import { HttpInterceptor } from "../auth/http-interceptors";


export function dashboardservicesgetall() {
    return HttpInterceptor
        .get(`/seller/dashboard`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function dashboardPopularproducts() {
    return HttpInterceptor
        .get(`/seller/popular-products`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function dashboardRecentlyOrderproducts() {
    return HttpInterceptor
        .get(`/seller/recent-orders`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function dashboardUserengagement() {
    return HttpInterceptor
        .get(`/seller/user-engagement`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}


// /seller/user-engagement