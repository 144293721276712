import React, { useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      cursor: "default",
      zIndex: 9999,
      ":active": {
        ...styles[":active"],
        backgroundColor: "red",
      },
    };
  },
};

const SelectBox = ({ options, name, onChange, value }) => {
  const [optionSelected, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    onChange({ label: selected.label, id: selected.value });
    setSelectedOptions(selected);
  };

  const selectedValue = (value) => {
    if (value !== null) {
      // console.log(options)
      setSelectedOptions(options.filter((item) => item.value == value));
    }
  };
  useEffect(() => {
    selectedValue(value);
  }, [value, options]);

  return (
    <Select
      options={options}
      isLoading={!options}
      closeMenuOnSelect={true}
      onChange={handleChange}
      value={value !== "" ? optionSelected : value}
      name={name}
      menuPortalTarget={document.body}
    />
  );
};

export default SelectBox;
