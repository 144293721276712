import { useState, memo, Fragment, useEffect } from "react";
import { Button, Card, Col } from "react-bootstrap";
import "../styles/Tickets.scss";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import Pagination from "rc-pagination";
import supportServices from "../../../../services/support_services/support_services";

import moment from "moment";

const Tickets = memo((props) => {
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [limit, seLimit] = useState([]);
  const [current, setCurrent] = useState(1);
  const headertexts = [
    {
      headers: "Subject",
    },
    {
      headers: "Ticket ID",
    },
    {
      headers: "Status",
    },
    {
      headers: "Created on",
    },
  ];






  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };
  // const handleChangePagecount = () => {
  //   setPageNum(++pageNum);
  // };

  const fetchCurrentData = async (current) => {
    const resdata = await supportServices.getTicketsPagination(
      current
    );

    setTickets(resdata);
  };
  const handleChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };

  const getTickets = () => {
    supportServices
      .getTickets(null)
      .then((results) => {
        setTickets(results);
        seLimit(results)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (loading) {
      getTickets();
    }
  }, [loading]);

  const [serachName, setSearchicketName] = useState("");


  const handleSearchTickets = () => {
    // SearchTickets
    supportServices
      .SearchTickets(serachName)
      .then((results) => {
        setTickets(results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <Fragment>
      {/* <div className="mb-3 mt-3">
        <h3>Tickets</h3>
      </div> */}
      <div className="d-flex justify-content-between mb-3">
        <div className="mt-3">
          <h3>Tickets</h3>
        </div>
        <div className="mt-3" style={{ marginRight: "2%" }}>
          <Button onClick={() => history(`/womeyn/support`)}>
            + Add New Tickets
          </Button>
        </div>
      </div>

      <Card className="col-lg-12">
        {/* <div className="text-end" style={{ marginRight: "2%" }}>
          <Button onClick={() => history(`/womeyn/support`)}>
            + Add New Ticket
          </Button>
        </div> */}
        <div className="d-flex justify-content-end ">
          <Col lg={5}>
            <div className="py-0 col-lg-12">
              <div className="form-group input-group mb-0">
                <input type="text" className="form-control" placeholder="Search By Ticket and Query... " onChange={(e) => setSearchicketName(e.target.value)} value={serachName} />
                <span className="input-group-text" style={{ cursor: "pointer" }} onClick={handleSearchTickets}>
                  <svg className="icon-20" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></circle>
                    <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </span>
              </div>
            </div>
          </Col>
          {/* <Col lg={2}>
            <Select placeholder='Status' onChange={Handlefilterservicelist} options={options}>
                        </Select>
          </Col> */}
        </div>
        {!loading && tickets.results.length > 0 ? (
          <Card.Body className="m-0 p-0">
            <Table hover className="cursor-texts mt-5">
              <thead>
                <tr>
                  {headertexts.map((item, index) => {
                    return (
                      <>
                        <th>{item.headers}</th>
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {!loading &&
                  tickets?.results.map((item, index) => {
                    return (
                      <>
                        <tr
                          onClick={() => history("/womeyn/tickets/" + item.id)}
                        >
                          <td
                            className={item?.isSellerViewed ? "" : "text-bold"}
                          >
                            <Link to={"/womeyn/tickets/" + item.id}>
                              {item?.subject}
                            </Link>
                          </td>
                          <td
                            className={item?.isSellerViewed ? "" : "text-bold"}
                          >
                            <div
                              onClick={() =>
                                history("/womeyn/tickets/" + item.id)
                              }
                            >
                              {item?.id}
                            </div>
                          </td>
                          <td
                            className={item?.isSellerViewed ? "" : "text-bold"}
                          >
                            {/* <Select placeholder='Status' isDisabled={true} onChange={Handlefilterservicelist} options={options1}>
                                                </Select> */}
                            <div
                              onClick={() =>
                                history("/womeyn/tickets/" + item.id)
                              }
                            >
                            
                             {item?.stateId === 3?"Closed":"Open"}
                            

                            </div>
                          </td>
                          <td
                            className={item?.isSellerViewed ? "" : "text-bold"}
                          >
                            <div
                              onClick={() =>
                                history("/womeyn/tickets/" + item.id)
                              }
                            >
                              {moment(item?.createdDate).format(
                                    "lll"
                                )}
                            </div>
                          </td>
                          {/* <td>
                                                <Button className="btn btn-primary btn-icon btn-sm rounded-pill ms-2" to="#" role="button">
                                                    <span className="btn-inner">
                                                        {remove}
                                                    </span>
                                                </Button>

                                            </td> */}
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>

            {/* <Pagination
              className="pagination-data"
              total={10}
              onChange={handleChangePagecount}
              current={1}
              itemRender={PrevNextArrow}
              breakLabel="..."
            /> */}

            <Pagination
              className="pagination-data"
              total={limit.totalPages * 10}
              onChange={handleChangePagecount}
              current={current}
              itemRender={PrevNextArrow}
              breakLabel="..."
            />
          </Card.Body>
        ) : (
          <div className="no-ticket fw-bold fs-3 text-center mt-5">
            <>No Tickets</>
          </div>
        )}
      </Card>
    </Fragment>
  );
});

export default Tickets;
