import { Fragment, memo, useContext, useEffect, useState } from "react";

//Router
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionContext,
  Nav,
  OverlayTrigger,
  Tooltip,
  useAccordionButton,
} from "react-bootstrap";
import { toast } from "react-toastify";
import loginServices from "../../../../services/login_services/login-services";
import { userProfileInformation } from "../../../../services/profile_user_services/profile_user_services";
import { toastlogout } from "../../../../toastcontents/ToastContent";
import "./styles/Vertical.scss";

// function CustomToggle({ children, eventKey, onClick }) {
//   const { activeEventKey } = useContext(AccordionContext);
//   const decoratedOnClick = useAccordionButton(eventKey, (active) =>
//     onClick({ state: !active, eventKey: eventKey })
//   );

//   const isCurrentEventKey = activeEventKey === eventKey;

//   return (
//     <Link
//       to="#"
//       aria-expanded={isCurrentEventKey ? "true" : "false"}
//       className="nav-link"
//       role="button"
//       onClick={(e) => {
//         decoratedOnClick(isCurrentEventKey);
//       }}
//     >
//       {children}
//     </Link>
//   );
// }

const VerticalNav = memo((props) => {
  const [users, setUsers] = useState([]);
  let location = useLocation();
  const history = useNavigate();

  const logOut = () => {
    loginServices.logout(null).then((result) => {
      toast.success(toastlogout);
      if (result) {
        setTimeout(() => {
          history("/");
        }, 1000);
      }
    });
  };




  return (
    <Fragment>
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Pages</span>
            <span className="mini-icon">-</span>
          </Link>
        </li>


        <li
          className={`${location.pathname === "/womeyn/dashboard" ? "active" : ""
            } nav-item`}
        >


          <Link
            className={`${location.pathname === "/womeyn/dashboard" ? "active" : ""
              } nav-link `}
            aria-current="page"
            to="/womeyn/dashboard"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Dashboard</Tooltip>}
            >
              <span
                className={`${location.pathname === "/dashboard"
                  ? "active"
                  : "icon-dashboard"
                  } icon-size`}
              ></span>
            </OverlayTrigger>
            <span className="item-name">Dashboard </span>
          </Link>

        </li>
        <div>
          <li
            className={`${location.pathname === "/womeyn/user-profile-update"
              ? "active"
              : ""
              } nav-item`}
          >
            <Link
              className={`${location.pathname === "/womeyn/user-profile-update"
                ? "active"
                : ""
                } nav-link `}
              aria-current="page"
              to="/womeyn/user-profile-update"
            >
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    {props.userInfo.stateId === 2
                      ? "Create profile"
                      : "Update Profile"}
                  </Tooltip>
                }
              >
                <span
                  className={`${location.pathname === "/user-profile-update"
                    ? "active"
                    : "icon-profile"
                    } icon-size`}
                ></span>
              </OverlayTrigger>
              <span className="item-name">
                {props.userInfo.stateId === 2
                  ? "Create profile"
                  : "Update Profile"}
              </span>
            </Link>
          </li>
        </div>

        {props.userInfo.stateId === 2 ? (
          <></>
        ) : (
          <>





            {props.userInfo.categoryTypeId === 1 ? <div>

              <li
                className={`${location.pathname === "/womeyn/product-list" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/product-list"
                    ? "active"
                    : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/product-list"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Products</Tooltip>}
                  >
                    <span
                      className={`${location.pathname === "/dashboard"
                        ? "active"
                        : "icon-product"
                        } icon-size`}
                    ></span>
                  </OverlayTrigger>
                  <span className="item-name">Products</span>
                </Link>
              </li>


              <li
                className={`${location.pathname === "/womeyn/order-details" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/order-details" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/order-details"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Orders</Tooltip>}
                  >
                    <span>
                      <svg
                        width="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42631 18.9079C6.58697 18.9079 5.9075 19.6018 5.9075 20.459C5.9075 21.3061 6.58697 22 7.42631 22C8.25567 22 8.93514 21.3061 8.93514 20.459C8.93514 19.6018 8.25567 18.9079 7.42631 18.9079ZM18.6676 18.9079C17.8282 18.9079 17.1487 19.6018 17.1487 20.459C17.1487 21.3061 17.8282 22 18.6676 22C19.4969 22 20.1764 21.3061 20.1764 20.459C20.1764 19.6018 19.4969 18.9079 18.6676 18.9079Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Orders</span>
                </Link>
              </li>

              <li
                className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/tickets"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Tickets</Tooltip>}
                  >
                    <span>
                      <svg
                        width="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.9 2H15.07C17.78 2 19.97 3.07 20 5.79V20.97C20 21.14 19.96 21.31 19.88 21.46C19.75 21.7 19.53 21.88 19.26 21.96C19 22.04 18.71 22 18.47 21.86L11.99 18.62L5.5 21.86C5.351 21.939 5.18 21.99 5.01 21.99C4.45 21.99 4 21.53 4 20.97V5.79C4 3.07 6.2 2 8.9 2ZM8.22 9.62H15.75C16.18 9.62 16.53 9.269 16.53 8.83C16.53 8.39 16.18 8.04 15.75 8.04H8.22C7.79 8.04 7.44 8.39 7.44 8.83C7.44 9.269 7.79 9.62 8.22 9.62Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Tickets</span>
                </Link>
              </li>



              <li
                className={`${location.pathname === "/womeyn/inventory" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/inventory" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/inventory"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>inventory</Tooltip>}
                  >
                    <span>
                      <svg
                        width="23"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">inventory</span>
                </Link>
              </li>



            </div> : null}


            {props.userInfo.categoryTypeId === 2 ? <div>


              <li
                className={`${location.pathname === "/womeyn/services-list"
                  ? "active"
                  : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/services-list"
                    ? "active"
                    : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/services-list"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Service Creation</Tooltip>}
                  >
                    <span
                      className={`${location.pathname === "/dashboard"
                        ? "active"
                        : "icon-service"
                        } icon-size`}
                    ></span>
                  </OverlayTrigger>
                  <span className="item-name">Services</span>
                </Link>
              </li>


              <li
                className={`${location.pathname === "/womeyn/service-list" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/service-list" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/service-list"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Service Booking</Tooltip>}
                  >
                    <span>


                      <svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5227 7.39601V8.92935C19.2451 9.46696 20.5 11.0261 20.5 12.8884V17.8253C20.5 20.1308 18.5886 22 16.2322 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8253V12.8884C3.5 11.0261 4.75595 9.46696 6.47729 8.92935V7.39601C6.48745 4.41479 8.95667 2 11.9848 2C15.0535 2 17.5227 4.41479 17.5227 7.39601ZM12.0051 3.73904C14.0678 3.73904 15.7445 5.37871 15.7445 7.39601V8.7137H8.25553V7.37613C8.26569 5.36878 9.94232 3.73904 12.0051 3.73904ZM12.8891 16.4549C12.8891 16.9419 12.4928 17.3294 11.9949 17.3294C11.5072 17.3294 11.1109 16.9419 11.1109 16.4549V14.2488C11.1109 13.7718 11.5072 13.3843 11.9949 13.3843C12.4928 13.3843 12.8891 13.7718 12.8891 14.2488V16.4549Z" fill="currentColor"></path>                            </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Service Booking</span>
                </Link>
              </li>


              <li
                className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/tickets"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Tickets</Tooltip>}
                  >
                    <span>
                      <svg
                        width="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.9 2H15.07C17.78 2 19.97 3.07 20 5.79V20.97C20 21.14 19.96 21.31 19.88 21.46C19.75 21.7 19.53 21.88 19.26 21.96C19 22.04 18.71 22 18.47 21.86L11.99 18.62L5.5 21.86C5.351 21.939 5.18 21.99 5.01 21.99C4.45 21.99 4 21.53 4 20.97V5.79C4 3.07 6.2 2 8.9 2ZM8.22 9.62H15.75C16.18 9.62 16.53 9.269 16.53 8.83C16.53 8.39 16.18 8.04 15.75 8.04H8.22C7.79 8.04 7.44 8.39 7.44 8.83C7.44 9.269 7.79 9.62 8.22 9.62Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Tickets</span>
                </Link>
              </li>

            </div> : null}


            {props.userInfo.categoryTypeId === 0 ? <div>

              <li
                className={`${location.pathname === "/womeyn/product-list" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/product-list"
                    ? "active"
                    : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/product-list"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Products</Tooltip>}
                  >
                    <span
                      className={`${location.pathname === "/dashboard"
                        ? "active"
                        : "icon-product"
                        } icon-size`}
                    ></span>
                  </OverlayTrigger>
                  <span className="item-name">Products</span>
                </Link>
              </li>

              <li
                className={`${location.pathname === "/womeyn/services-list"
                  ? "active"
                  : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/services-list"
                    ? "active"
                    : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/services-list"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Service Creation</Tooltip>}
                  >
                    <span
                      className={`${location.pathname === "/dashboard"
                        ? "active"
                        : "icon-service"
                        } icon-size`}
                    ></span>
                  </OverlayTrigger>
                  <span className="item-name">Services</span>
                </Link>
              </li>


              <li
                className={`${location.pathname === "/womeyn/service-list" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/service-list" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/service-list"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Service Booking</Tooltip>}
                  >
                    <span>


                      <svg width="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5227 7.39601V8.92935C19.2451 9.46696 20.5 11.0261 20.5 12.8884V17.8253C20.5 20.1308 18.5886 22 16.2322 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8253V12.8884C3.5 11.0261 4.75595 9.46696 6.47729 8.92935V7.39601C6.48745 4.41479 8.95667 2 11.9848 2C15.0535 2 17.5227 4.41479 17.5227 7.39601ZM12.0051 3.73904C14.0678 3.73904 15.7445 5.37871 15.7445 7.39601V8.7137H8.25553V7.37613C8.26569 5.36878 9.94232 3.73904 12.0051 3.73904ZM12.8891 16.4549C12.8891 16.9419 12.4928 17.3294 11.9949 17.3294C11.5072 17.3294 11.1109 16.9419 11.1109 16.4549V14.2488C11.1109 13.7718 11.5072 13.3843 11.9949 13.3843C12.4928 13.3843 12.8891 13.7718 12.8891 14.2488V16.4549Z" fill="currentColor"></path>                            </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Service Booking</span>
                </Link>
              </li>

              <li
                className={`${location.pathname === "/womeyn/order-details" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/order-details" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/order-details"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Orders</Tooltip>}
                  >
                    <span>
                      <svg
                        width="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.1213 11.2331H16.8891C17.3088 11.2331 17.6386 10.8861 17.6386 10.4677C17.6386 10.0391 17.3088 9.70236 16.8891 9.70236H14.1213C13.7016 9.70236 13.3719 10.0391 13.3719 10.4677C13.3719 10.8861 13.7016 11.2331 14.1213 11.2331ZM20.1766 5.92749C20.7861 5.92749 21.1858 6.1418 21.5855 6.61123C21.9852 7.08067 22.0551 7.7542 21.9652 8.36549L21.0159 15.06C20.8361 16.3469 19.7569 17.2949 18.4879 17.2949H7.58639C6.25742 17.2949 5.15828 16.255 5.04837 14.908L4.12908 3.7834L2.62026 3.51807C2.22057 3.44664 1.94079 3.04864 2.01073 2.64043C2.08068 2.22305 2.47038 1.94649 2.88006 2.00874L5.2632 2.3751C5.60293 2.43735 5.85274 2.72207 5.88272 3.06905L6.07257 5.35499C6.10254 5.68257 6.36234 5.92749 6.68209 5.92749H20.1766ZM7.42631 18.9079C6.58697 18.9079 5.9075 19.6018 5.9075 20.459C5.9075 21.3061 6.58697 22 7.42631 22C8.25567 22 8.93514 21.3061 8.93514 20.459C8.93514 19.6018 8.25567 18.9079 7.42631 18.9079ZM18.6676 18.9079C17.8282 18.9079 17.1487 19.6018 17.1487 20.459C17.1487 21.3061 17.8282 22 18.6676 22C19.4969 22 20.1764 21.3061 20.1764 20.459C20.1764 19.6018 19.4969 18.9079 18.6676 18.9079Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Orders</span>
                </Link>
              </li>

              <li
                className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/tickets"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Tickets</Tooltip>}
                  >
                    <span>
                      <svg
                        width="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.9 2H15.07C17.78 2 19.97 3.07 20 5.79V20.97C20 21.14 19.96 21.31 19.88 21.46C19.75 21.7 19.53 21.88 19.26 21.96C19 22.04 18.71 22 18.47 21.86L11.99 18.62L5.5 21.86C5.351 21.939 5.18 21.99 5.01 21.99C4.45 21.99 4 21.53 4 20.97V5.79C4 3.07 6.2 2 8.9 2ZM8.22 9.62H15.75C16.18 9.62 16.53 9.269 16.53 8.83C16.53 8.39 16.18 8.04 15.75 8.04H8.22C7.79 8.04 7.44 8.39 7.44 8.83C7.44 9.269 7.79 9.62 8.22 9.62Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">Tickets</span>
                </Link>
              </li>



              <li
                className={`${location.pathname === "/womeyn/inventory" ? "active" : ""
                  } nav-item`}
              >
                <Link
                  className={`${location.pathname === "/womeyn/inventory" ? "active" : ""
                    } nav-link `}
                  aria-current="page"
                  to="/womeyn/inventory"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>inventory</Tooltip>}
                  >
                    <span>
                      <svg
                        width="23"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z"
                          fill="currentColor"
                        ></path>{" "}
                      </svg>
                    </span>
                  </OverlayTrigger>
                  <span className="item-name">inventory</span>
                </Link>
              </li>


            </div> : null}
          </>
        )}




        <li>
          <hr className="hr-horizontal" />
        </li>
        <li className="nav-item static-item">
          <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
            <span className="default-icon">Others </span>
            <span className="mini-icon">-</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" onClick={logOut}>
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Signout</Tooltip>}
            >
              <span className="icon-size">
                <svg
                  width="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.8861 2H16.9254C19.445 2 21.5 4 21.5 6.44V17.56C21.5 20.01 19.445 22 16.9047 22H11.8758C9.35611 22 7.29083 20.01 7.29083 17.57V12.77H13.6932L12.041 14.37C11.7312 14.67 11.7312 15.16 12.041 15.46C12.1959 15.61 12.4024 15.68 12.6089 15.68C12.8051 15.68 13.0117 15.61 13.1666 15.46L16.1819 12.55C16.3368 12.41 16.4194 12.21 16.4194 12C16.4194 11.8 16.3368 11.6 16.1819 11.46L13.1666 8.55C12.8568 8.25 12.3508 8.25 12.041 8.55C11.7312 8.85 11.7312 9.34 12.041 9.64L13.6932 11.23H7.29083V6.45C7.29083 4 9.35611 2 11.8861 2ZM2.5 11.9999C2.5 11.5799 2.85523 11.2299 3.2815 11.2299H7.29052V12.7699H3.2815C2.85523 12.7699 2.5 12.4299 2.5 11.9999Z"
                    fill="currentColor"
                  ></path>{" "}
                </svg>
              </span>
            </OverlayTrigger>
            <span className="item-name">Signout</span>
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link
            className={`${location.pathname === "/womeyn/tickets" ? "active" : ""
              } nav-link `}
            aria-current="page"
            to="/womeyn/tickets"
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>Support</Tooltip>}
            >
              <span>
                <svg width="23" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9303 7C16.9621 6.92913 16.977 6.85189 16.9739 6.77432H17C16.8882 4.10591 14.6849 2 12.0049 2C9.325 2 7.12172 4.10591 7.00989 6.77432C6.9967 6.84898 6.9967 6.92535 7.00989 7H6.93171C5.65022 7 4.28034 7.84597 3.88264 10.1201L3.1049 16.3147C2.46858 20.8629 4.81062 22 7.86853 22H16.1585C19.2075 22 21.4789 20.3535 20.9133 16.3147L20.1444 10.1201C19.676 7.90964 18.3503 7 17.0865 7H16.9303ZM15.4932 7C15.4654 6.92794 15.4506 6.85153 15.4497 6.77432C15.4497 4.85682 13.8899 3.30238 11.9657 3.30238C10.0416 3.30238 8.48184 4.85682 8.48184 6.77432C8.49502 6.84898 8.49502 6.92535 8.48184 7H15.4932ZM9.097 12.1486C8.60889 12.1486 8.21321 11.7413 8.21321 11.2389C8.21321 10.7366 8.60889 10.3293 9.097 10.3293C9.5851 10.3293 9.98079 10.7366 9.98079 11.2389C9.98079 11.7413 9.5851 12.1486 9.097 12.1486ZM14.002 11.2389C14.002 11.7413 14.3977 12.1486 14.8858 12.1486C15.3739 12.1486 15.7696 11.7413 15.7696 11.2389C15.7696 10.7366 15.3739 10.3293 14.8858 10.3293C14.3977 10.3293 14.002 10.7366 14.002 11.2389Z" fill="currentColor"></path>                            </svg>

              </span>
            </OverlayTrigger>
            <span className="item-name">Support</span>
          </Link>

        </li> */}
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
