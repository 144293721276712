import { memo, Fragment, useState, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";
import login from "../../../assets/loginLogos/login.png";
import logowomenyn from "../../../assets/loginLogos/women_white_log.svg";
import circlethree from "../../../assets/loginLogos/circle.svg";
import circleone from "../../../assets/loginLogos/circle2.svg";
import circletwo from "../../../assets/loginLogos/circle1.svg";
import "../../auth/styles/Signin.scss";
import Spinner from "react-bootstrap/Spinner";
import { forgetPasswordEmailSend } from "../../../services/forgetpassword/forgetpassword-service";
import "./styles/ForegetPassowrd.scss";
const ForgetPassword = memo(() => {
  const userefs = useRef(null);
  let history = useNavigate();
  const [formState, setFormState] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email Required"),
    }),
    onSubmit: (values) => {
      var email = values.email;
      const datas = {
        email: email,
      };
      setFormState(true);
      forgetPasswordEmailSend(datas)
        .then((res) => {
          toast.success(`Check Your Mail `);
          showModal();
          setFormState(false);
        })
        .catch((err) => console.log(err));
    },
  });

  const showModal = () => {
    const modalEle = userefs.current;
    const bsModal = new Modal(modalEle, {
      backdrop: "static",
      keyboard: false,
    });
    bsModal.show();
  };

  const hideModal = () => {
    const modalEle = userefs.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const loginnavigate = () => {
    toast.success("Login Page ");
    setTimeout(() => {
      history(`/`);
      hideModal();
    }, 1000);
  };

  return (
    <Fragment>
      <div className="singin-main-section">
        <div className="inside-section-main">
          <div className="left-section-signin d-md-block d-none">
            <div className="logo">
              <img src={logowomenyn} alt="no image"  className="womenlogsize"/>
            </div>
            <div className="circle">
              <img src={circlethree} alt="no image" className="circles" />
            </div>
            <div className="sellers">
              <h5 className="we">We are trusted by </h5>
              <div>
                <h6 className="seller">Sellers</h6>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "94%",
                paddingTop: "7%",
              }}
            >
              <img src={login} className="Image-fluid" alt="images" />
            </div>
          </div>
          <div className="right-section-signin">
            <div className="circleone">
              <img
                src={circleone}
                alt="no circleone"
                className="circleone-image"
              />
            </div>
            <div>
              <img
                src={circletwo}
                alt="no circletwo"
                className="circletwo-image"
              />
            </div>
            <div className="inside-right-section-singnin">
              <div className="first-section-right mb-5">
                <div className="qutations-section-first">
                  <div className="qutations"></div>
                  <div className="and-love-by">
                    And loved by
                    <br />
                    <span className="customer">Sellers</span>
                  </div>
                </div>
              </div>
              <div className="second-section-right mt-5">
                <div className="mt-5">
                  <h2 className="mb-2 mb-3">Reset Password</h2>
                  <p className="mb-4 mt-3">
                    Enter your email address and we'll send you an email with
                    instructions to reset your password.
                  </p>
                </div>
                <div className="mt-3">
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col sm="12" md="12" lg="12" className="form-group">
                        <Form.Group controlId="firstName">
                          <Form.Label className="label-form">Email</Form.Label>
                          <Form.Control
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            isInvalid={
                              formik.touched.email && !!formik.errors.email
                            }
                          />
                          <Form.Text className="text-danger">
                            {formik.touched.email && formik.errors.email ? (
                              <div className="text-danger">
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </Form.Text>
                          {/* <Form.Control.Feedback type="valid">
                                                        Looks Good! 😎
                                                    </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="d-flex forget-password-button-section mt-2">
                      <div className="">
                        <Button
                          onClick={() => history("/")}
                          className="back-btn"
                        >
                          Back
                        </Button>
                      </div>
                      <Button type="submits" className="submit-btn">
                        {formState ? (
                          <>
                            {" "}
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            <span className="ms-2">Loading...</span>
                          </>
                        ) : (
                          <>Reset Password</>
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="third-section-right"></div>
            </div>
          </div>
        </div>
        <>
          <p
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          ></p>
          <div
            class="modal fade modal-bodys"
            id="staticBackdrop"
            ref={userefs}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                  <div className="mt-5 mb-5">
                    If you are a registered customer, you will receive a
                    password reset link in your email. Please follow the
                    instruction and Reset your password.
                  </div>
                  <div className="mt-4 mb-5 button-forgetpassword">
                    <Button onClick={loginnavigate} className="reset-button">
                      Ok
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </Fragment>
  );
});
ForgetPassword.displayName = "ForgetPassword";
export default ForgetPassword;
