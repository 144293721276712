import { memo, useEffect, useState } from 'react'

//Components
import Card from '../../components/bootstrap/card';
import { userProfileInformation } from '../../services/profile_user_services/profile_user_services';
import { useNavigate } from 'react-router-dom';

const SubHeader = memo(() => {
    const [data, setDetails] = useState([]);
    const history = useNavigate();
    useEffect(() => {
        const userid = localStorage.getItem("userid");
        userProfileInformation(JSON.parse(userid))
            .then((res) => {
                setDetails(res);
            })
            .catch((err) => console.log(err));
    }, [])

    const Changeplan = () => {
        history("/womeyn/subscribe/user");
    }


    const navigatePath = async () => {
        history("/womeyn/user-profile");
    }


    return (
        <div>

            <Card className="bg-soft-primary d-flex ">
                <div className='d-flex justify-content-between  gap-4'>
                    <div className='d-flex gap-4'>
                        <div onClick={navigatePath}>
                            {data.profileImageName ?
                                <img
                                    style={{ borderRadius: "50px", width: "90px", height: "90px", cursor: "pointer", objectFit: "cover" }}
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${data.profileImageName}`}
                                    alt="profile-pic"
                                /> :
                                <>
                                    <img
                                        src={"https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo="}
                                        alt="profile-pic"
                                        style={{ borderRadius: "50px", width: "90px", height: "90px", cursor: "pointer", objectFit: "cover" }}
                                    />
                                </>
                            }
                        </div>
                        <div className='d-flex align-items-center justify-content-center flex-column'>
                            <h1>{data.firstName}</h1>
                            <h6 className='text-danger'>Welcome back to Dashboard</h6>
                        </div>
                    </div>
                    {data?.planName ? <>
                        <div>
                            <div className='mb-4 d-flex'>



                                {data?.planName ? <>
                                    {data?.isActive ? <>
                                        Subscribed Plan : <span className='fw-bold ms-1'>{data?.planName}</span>
                                    </> : <>
                                        <> Subscribed Plan : Plan Expired</>
                                    </>}
                                </> : <>-</>}
                            </div>
                            <div>
                                <button className='sellall w-100' onClick={Changeplan}>Change Plan</button>
                            </div>
                        </div>
                    </> : null}


                </div>
            </Card>
        </div>
    )
})

SubHeader.displayName = "SubHeader"
export default SubHeader

