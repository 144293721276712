import { useState, memo, Fragment, useEffect } from "react";
import Card from "../../../../components/bootstrap/card";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import support_services from "../../../../services/support_services/support_services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloseConfirm from "../components/ConfirmCloseModal";

const ReplyTicket = memo((props) => {
  let history = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const saveReply = (data) => {
    support_services
      .saveTicket(data)
      .then((result) => {
        toast.success("Ticket replied Successful!!");
        props.getTickets();
        formReplyDetails.resetForm();
      })
      .catch((err) => {
        toast.error("Failed to reply Ticket !");
        if (err) {
          throw err;
        }
      });
  };
  const goBack = () => {
    history("/womeyn/tickets");
  };
  const closeTicket = () => {
    let data = {
      messageId: props.id,
      stateId: 3,
    };
    support_services
      .closeTicket(data)
      .then((result) => {
        toast.success("Ticket Closed Successful!!");
        props.getTickets();
        formReplyDetails.resetForm();
      })
      .catch((err) => {
        if (err) {
          toast.error("Failed to Close Ticket !");
        }
      });
  };
  const showConfirmCloseDialogue = () => {
    handleShow();
  };
  const formReplyDetails = useFormik({
    initialValues: {
      message: "",
      masterTicketId: props.id,
    },
    onSubmit: (values) => {
      saveReply(values);
    },
    validationSchema: yup.object({
      message: yup.string().required("Message  is Required !"),
    }),
  });
  return (
    <Fragment>
      {/* <div className="d-flex flex-row justify-content-between mt-3 mb-5 ms-2">
            <h3>Tickets</h3>
        </div> */}

      <div>
        <CloseConfirm
          show={show}
          handleClose={handleClose}
          closeTicket={closeTicket}
        />
        <Card>
          <Card.Body className="m-0 p-0">
            <div>
              <div>
                <h3>Add Reply</h3>
              </div>
              <div className="mb-4 mt-4">
                <Form
                  onSubmit={formReplyDetails.handleSubmit}
                  validateOnChange={false}
                >
                  <div>
                    <Row>
                      <Col lg="8">
                        <div className="form-group">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="message"
                            value={formReplyDetails.values.message}
                            onChange={formReplyDetails.handleChange}
                            placeholder="Enter your reply here"
                          />
                          <div className="">
                            {formReplyDetails.errors.message &&
                            formReplyDetails.touched.message ? (
                              <span className="error-message">
                                {formReplyDetails.errors.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col lg="4" className="m-auto">
                        <Button
                          variant="primary"
                          onClick={showConfirmCloseDialogue}
                        >
                          Close Ticket
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <div className="">
                    <Button variant="secondary" onClick={goBack}>
                      Back
                    </Button>{" "}
                    <Button
                      variant="primary"
                      type="submit"
                      className="margin-left-button"
                    >
                      Add reply
                    </Button>{" "}
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
});

export default ReplyTicket;
