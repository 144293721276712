import { memo, useEffect, useState } from "react";

//router
import { Navigate, Route, Routes } from "react-router-dom";
import Default from "../layout/default";
import LoginSuccess from "../pages/auth/components/login-success";

//Pages
import { useOutletContext } from "react-router-dom";
import SignIn from "../pages/auth/sign-in";
import Signup from "../pages/auth/sign-up";
import Profileuser from "../pages/views/Profileuser/Profileuser";
import Index from "../pages/views/dashboard";
import ForgetPassword from "../pages/views/forgetpassword/ForgetPassword";
import ForgetPasswordChange from "../pages/views/forgetpassword/ForgetPasswordChange";
import Inventory from "../pages/views/inventory/Inventory";
import Inventoryview from "../pages/views/inventory/inventoryview/Inventoryview";
import Ordertarcking from "../pages/views/order-tracking/orderTracking";
import OrderDetails from "../pages/views/orders-list/OrderDetails";
import ViewOrderDetails from "../pages/views/orders-list/ViewOrderDetails";
import Orderdetailsview from "../pages/views/orders-list/orderdetailsview/Orderdetailsview";
import OtpCheck from "../pages/views/otpcheck/OtpCheck";
import PageNotFound from "../pages/views/pagenotfound/PageNotFound";
import Popularproducts from "../pages/views/popularproducts/Popularproducts";
import Productlist from "../pages/views/product-list/ProductList";
import AddProduct from "../pages/views/product-list/components/addproduct/AddProduct";
import ProductListEdit from "../pages/views/product-list/components/productListEdit/ProductListEdit";
import EditUser from "../pages/views/seller-approval/edit-user/edit-user";
import SellerApproval from "../pages/views/seller-approval/seller-approval";
import Servicelist from "../pages/views/service-creation/Servicelist";
import Servicecreation from "../pages/views/service-creation/component/Servicecreation";
import Serviceview from "../pages/views/service-creation/component/serviceview/Serviceview";
// import ServiceDetails from "../pages/views/services-list/serviceDetails";
import ServiceList from "../pages/views/services-list/serviceList";
import {
  default as Flexiablepricing,
  default as Subscribe,
} from "../pages/views/subscribe/Subscribe";
import Subscribecancel from "../pages/views/subscribe/subscribecancel/Subscribecancel";
import Subscribesuccess from "../pages/views/subscribe/subscribesuccess/Subscribesuccess";
import AddTickets from "../pages/views/support/pages/AddTicket";
import TicketLists from "../pages/views/support/pages/TicketLists";
import ViewTicket from "../pages/views/support/pages/ViewTicket";
import Tickets from "../pages/views/tickets/Tickets";
import Ticketsview from "../pages/views/tickets/ticketsview/Ticketsview";
import UserManagement from "../pages/views/user-management/user-management";
import UserProfile from "../pages/views/userprofile/UserProfile";
import { userProfileInformation } from "../services/profile_user_services/profile_user_services";
import ProtectedRoutes from "./protected-routes";
import Termsandconditions from "../pages/views/termsandconditions/Termsandconditions";
import Servicesorderview from "../pages/views/services-list/serviceorderview/Servicesorderview";
import Democheck from "../pages/views/democheck/Democheck";
import ServiceEditScreen from "../pages/views/service-creation/component/serviceEditScreen/ServiceEditScreen";
import PrivacyPolicy from "../pages/views/privacypolicy/PrivacyPolicy";

const IndexRouters = memo((props) => {

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {

  }, [refresh])
  return (
    <Routes>
      <Route path="/" element={<SignIn />}></Route>
      <Route path="sign-up" element={<Signup />}></Route>
      <Route path="forgot-password" element={<ForgetPassword />}></Route>
      <Route path="change-password" element={<ForgetPasswordChange />}></Route>
      <Route path="otp-confirm" element={<OtpCheck />}></Route>
      <Route path="oauth-success" element={<LoginSuccess />}></Route>
      <Route path="termsandconditions" element={<Termsandconditions />}></Route>
      <Route path="privacypolicy" element={<PrivacyPolicy />}></Route>


      <Route element={<ProtectedRoutes setRefresh={setRefresh}></ProtectedRoutes>}>
        <Route path="womeyn" element={<Default setRefresh={setRefresh} refresh={refresh} />}>
          <Route path="dashboard" element={<Index></Index>}></Route>
          <Route
            path="seller-approval"
            element={<SellerApproval></SellerApproval>}
          ></Route>
          <Route
            path="seller-approval/edit"
            element={<EditUser></EditUser>}
          ></Route>
          <Route
            path="user-management"
            element={<UserManagement></UserManagement>}
          ></Route>
          <Route
            path="user-profile-update"
            element={<UserProfile {...props} setRefresh={setRefresh} />}
          ></Route>
          <Route
            path="user-profile"
            element={<Profileuser {...props} />}
          ></Route>
          <Route path="product-list" element={<Productlist />}></Route>
          <Route path="add-product" element={<AddProduct />}></Route>
          <Route path="update-product/:id" element={<AddProduct />}></Route>
          <Route
            path="product-list-edit/:id"
            element={<ProductListEdit />}
          ></Route>
          <Route path="service-view/:id" element={<Serviceview />}></Route>
          <Route path="services-list" element={<Servicelist />}></Route>
          <Route path="services-creation" element={<Servicecreation />}></Route>
          <Route path="services-creation/:id" element={<Servicecreation />}></Route>
          <Route path="services-creation-edit/:id" element={<ServiceEditScreen />}></Route>


          {/* <Route path='order-details' element={<OrderDetails />}></Route> */}
          <Route
            path="order-view/:orderId"
            element={<Orderdetailsview />}
          ></Route>
          <Route path="support-chat" element={<Ticketsview />}></Route>
          <Route
            path="subscribe/:name"
            element={<Subscribe {...props} />}
          ></Route>
          <Route path="checkout/success" element={<Subscribesuccess />}></Route>
          <Route path="checkout/cancel" element={<Subscribecancel />}></Route>
          <Route path="inventory" element={<Inventory />}></Route>
          <Route path="inventory-view/:id" element={<Inventoryview />}></Route>
          <Route path="support" element={<AddTickets></AddTickets>}></Route>
          {/* <Route path='tickets' element={<Tickets />}></Route> */}
          <Route path="tickets" element={<TicketLists></TicketLists>}></Route>
          <Route path="tickets/:id" element={<ViewTicket></ViewTicket>}></Route>
          <Route path="order-details" element={<OrderDetails />}></Route>
          <Route path="order-invoice" element={<Orderdetailsview />}></Route>
          <Route
            path="view-order/:id"
            element={<ViewOrderDetails></ViewOrderDetails>}
          ></Route>
          <Route path="order-tracking/:id" element={<Ordertarcking />}></Route>
          <Route path="popularproducts" element={<Popularproducts />} />
          <Route
            path="service-list"
            element={<ServiceList></ServiceList>}
          ></Route>
          <Route
            path="serviceorder-view/:id"
            element={<Servicesorderview></Servicesorderview>}
          ></Route>
          <Route
            path="demo"
            element={<Democheck></Democheck>}
          ></Route>
        </Route>

        {/* <Route
          path="service/:id"
          element={<ServiceDetails></ServiceDetails>}
        ></Route> */}
      </Route>
      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
