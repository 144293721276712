import React, { useEffect } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";

const SalePriceField = (props) => {
  useEffect(() => {
    let val = "";
    if (props.values.offerPercentag != "") {
      val = Math.max(
        0,
        // Math.round(
        props.values.actualPrice -
        (parseInt(props.values.actualPrice) *
          parseInt(props.values.offerPercentag)) /
        100
        // )
      );
    } else {
      val = props.values.actualPrice;
    }
    props.setFieldValue("salePrice", val);
  }, [props.values.offerPercentag, props.values.actualPrice]);

  return (
    <div className="mb-4">
      <Col lg="12">
        <div className="form-group">
          <Form.Label htmlFor="salePrice">Sale Price</Form.Label>
          <Form.Control
            id="salePrice"
            type="number"
            name="salePrice"
            value={props.values.salePrice}
            {...props}
            disabled
          />
        </div>
      </Col>
    </div>
  );
};

export default SalePriceField;
