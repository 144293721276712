import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
function Removemodalpopup({ show, handleClose, removeid, DeleteProduct, loading }) {

    return (
        <div >
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <div className='mt-4 mb-2 ms-3'>
                    <Modal.Title>
                        <div className='text-center'>
                            {/* Product_Id:  */}
                            {/* {removeid} */}
                        </div>
                    </Modal.Title>

                    <Modal.Body>
                        <div className='text-center mt-2 mb-3 fs-5'>
                            Are you sure you want to delete Product?
                        </div>
                    </Modal.Body>

                    <div className='d-flex gap-3 align-items-center justify-content-center mt-3 mb-4'>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={DeleteProduct}>{loading ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className='ms-2'>Loading...</span>
                        </> : <>Delete</>}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Removemodalpopup