import { Fragment, memo, useEffect, useState } from "react";
import { Button, Col, Modal, Nav, Row, Tab, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Card from "../../../components/bootstrap/card";
import * as SettingSelector from "../../../store/setting/selectors";
import Allorderdetails from "./allorderdetails/Allorderdetails";
const OrderDetails = memo((props) => {
  useSelector(SettingSelector.theme_color);
  const [show, setShow] = useState(false);
  const [stateId, setStateId] = useState(0);

  const tableHeaders = [
    { title: "All Orders", stateId: 0 },
    { title: "In Progress", stateId: 3 },
    { title: "Cancelled by Customer", stateId: 4 },
    { title: "Cancelled by WomenPreneur", stateId: 5 },
    { title: "Delivered", stateId: 6 },
  ];
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between mt-3 mb-5 ms-2">
        <h3>Orders</h3>
      </div>
      <div>
        <Card>
          <Card.Body className="m-0 p-0">
            <div>
              <Tab.Container defaultActiveKey="0">
                <Nav className="tab-bottom-bordered">
                  <div
                    className="mb-0 order-tab nav nav-tabs d-flex"
                    id="nav-tab1"
                    role="tablist"
                  >
                    {tableHeaders.map((item, i) => {
                      return (
                        <Nav.Link
                          eventKey={i}
                          id="nav-admin"
                          data-bs-target="#tab-admin"
                          aria-controls="pills-admin"
                          data-bs-toggle="tab"
                          type="button"
                          role="tab"
                          aria-selected="true"
                          onClick={() => {
                            setStateId(item.stateId);
                          }}
                        >
                          <span className="iq-mail-section">{item.title}</span>
                        </Nav.Link>
                      );
                    })}
                  </div>
                </Nav>
                <Tab.Content
                  className="mt-4 iq-tab-fade-up"
                  id="nav-tabContent"
                >
                  <Tab.Pane
                    eventKey="0"
                    id="nav-admin"
                    role="tablist"
                    aria-labelledby="nav-admin-tab"
                  >
                    {stateId == 0 ? <Allorderdetails stateId={stateId} /> : ""}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="1"
                    id="nav-sellers"
                    role="tablist"
                    aria-labelledby="nav-sellers-tab"
                  >
                    {stateId == 3 ? <Allorderdetails stateId={stateId} /> : ""}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="2"
                    id="nav-sellers"
                    role="tab"
                    aria-labelledby="nav-sellers-tab"
                  >
                    {stateId == 4 ? <Allorderdetails stateId={stateId} /> : ""}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="3"
                    id="nav-endc"
                    role="tab"
                    aria-labelledby="nav-endc-tab"
                  >
                    {stateId == 5 ? <Allorderdetails stateId={stateId} /> : ""}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="4"
                    id="nav-endc"
                    role="tab"
                    aria-labelledby="nav-endc-tab"
                  >
                    {stateId == 6 ? <Allorderdetails stateId={stateId} /> : ""}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
});

export default OrderDetails;
