import { createSlice } from '@reduxjs/toolkit';
const UserData = createSlice({
    name: "user",
    initialState: [],
    reducers: {
        addusers(state, action) {
            state.push(action.payload);
        },
    }
});

export const { addusers } = UserData.actions;
export default UserData.reducer;