
import axios from "axios";
import login_services from '../login_services/login-services'
import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
axios.interceptors.request.use(
  function (config) {
    if (config.url.search("/seller/profile") !== -1 || config.url.search("/add-chat-attachements") !== -1 || config.url.search("/seller/update-photo") !== -1 || config.url.search("/update-thumb-image") !== -1 || config.url.search("/seller/create-product") !== -1 || config.url.search('/seller/update-product') !== -1 || config.url.search("/seller/update-service") !== -1 || config.url.search("/seller/upload-banner") !== -1) {
      const token = localStorage.getItem("access_token");
      config.url = process.env.REACT_APP_APIURL + config.url;
      config.headers = {
        ...config.headers,
        "Content-Type": false,
        "process-data": false,
        cache: false,
        "data-type": "Text",
        Authorization: `Bearer ${token}`,
      };
      return config;
    } else if ((config.url.search("/auth/seller/google") !== -1) || (config.url.search("/auth/seller/oauth/success") !== -1)) {
      console.log("crede called")
      const token = localStorage.getItem("access_token");
      config.url = process.env.REACT_APP_APIURL + config.url;
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      config.withCredentials = true;
      return config;
    }

    else {
      const token = localStorage.getItem("access_token");
      config.url = process.env.REACT_APP_APIURL + config.url;


      if (token) {
        if (jwt_decode(token).exp < Date.now() / 1000) {
          localStorage.clear();
          window.location.assign("/");
        }
      }
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      };
      config.withCredentials = true;
      return config;
    }

  },
  function (error) {

    console.log(error?.response?.data?.message,"kl1")
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response) {
      // await login_services.logout();
      // localStorage.clear();
      // window.location.assign("/");
      // <Navigate
      //   to={{
      //     pathname: "/"
      //   }}
      // />
      if(error?.response?.data?.code==401 || error?.response?.data?.code==404)
        {
          toast.error(error?.response?.data?.message)
        }

    }
    else {

      return Promise.reject(error);
    }

  }
);
export const HttpInterceptor = axios;

