import React, { useEffect, useState, Fragment } from "react";
import arrowright from "../../../../../assets/loginLogos/rightarrow.png";
import arrowleft from "../../../../../assets/loginLogos/leftarrow.png";
import { Row, Col, Image, Button, Table, Form } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "rc-pagination";
import Card from "../../../../../components/bootstrap/card";
import noimage from "../../../../../assets/loginLogos/noimage1.jpg";
import ProductServices, {
  DeleteProductList,
  GetProductList,
  SearchProductList
} from "../../../../../services/product_services/product_services";
import Removemodalpopup from "../removemodalpopup/Removemodalpopup";
import { toast } from "react-toastify";
import Select from "react-select";
import "./styles/Listproduct.scss";
import { useOutletContext } from "react-router-dom";
import { userProfileInformation } from "../../../../../services/profile_user_services/profile_user_services";
function ListProduct() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [removeid, setRemoveid] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setRemoveid(id);
  };
  const history = useNavigate();
  const edit = (
    <svg
      width="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.9927 18.9534H14.2984C13.7429 18.9534 13.291 19.4124 13.291 19.9767C13.291 20.5422 13.7429 21.0001 14.2984 21.0001H19.9927C20.5483 21.0001 21.0001 20.5422 21.0001 19.9767C21.0001 19.4124 20.5483 18.9534 19.9927 18.9534Z"
        fill="currentColor"
      ></path>
      <path
        d="M10.309 6.90385L15.7049 11.2639C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8344 7.72908 20.8452L4.23696 20.8882C4.05071 20.8903 3.88775 20.7613 3.84542 20.5764L3.05175 17.1258C2.91419 16.4915 3.05175 15.8358 3.45388 15.3306L9.88256 6.95545C9.98627 6.82108 10.1778 6.79743 10.309 6.90385Z"
        fill="currentColor"
      ></path>
      <path
        opacity="0.4"
        d="M18.1208 8.66544L17.0806 9.96401C16.9758 10.0962 16.7874 10.1177 16.6573 10.0124C15.3927 8.98901 12.1545 6.36285 11.2561 5.63509C11.1249 5.52759 11.1069 5.33625 11.2127 5.20295L12.2159 3.95706C13.126 2.78534 14.7133 2.67784 15.9938 3.69906L17.4647 4.87078C18.0679 5.34377 18.47 5.96726 18.6076 6.62299C18.7663 7.3443 18.597 8.0527 18.1208 8.66544Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const remove = (
    <svg
      width="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
        fill="currentColor"
      ></path>
      <path
        d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
        fill="currentColor"
      ></path>
    </svg>
  );
  const userid = localStorage.getItem("userid");
  const [searchproductname, setSearchproductname] = useState("");
  const [productlistfilter, setproductlistfilter] = useState(0);
  const [demolist, seDemoList] = useState([]);
  const [limit, seLimit] = useState([]);
  const [current, setCurrent] = useState(1);
  const { userInfo } = useOutletContext();
  const [pagerefresh, setPagerefresh] = useState(false);

  useEffect(() => {

    if (searchproductname?.length == 0 || searchproductname == "") {
      getProductLists();

    }

    getProductLists();


    ProductServices
      .ProductAvailablecountCheck()
      .then((res) => {
        // setCounts(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [productlistfilter, pagerefresh]);
  const Handlefilterproduct = (e) => {
    setproductlistfilter(e.value);
    setSearchproductname("");
    // console.log(e.value);
  };


  const getProductLists = () => {
    GetProductList(JSON.parse(userid), productlistfilter)
      .then((res) => {
        seDemoList(res?.data?.results);
        seLimit(res?.data);

      })
      .catch((err) => console.log(err));
  };
  const fetchCurrentData = async (id, current) => {
    const resdata = await ProductServices.GetproductFilterPagination(
      id,
      current
    );



    seDemoList(resdata?.data?.results);
  };
  const handleChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(JSON.parse(userid), current);
  };
  const Handlesearchservice = () => {
    SearchProductList(JSON.parse(userid), searchproductname)
      .then((res) => {
        seDemoList(res?.data?.results);
        seLimit(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };

  const DeleteProduct = () => {
    setPagerefresh(false);
    const stateId = 5;
    const data = {
      stateId: stateId,
    };
    setLoading(true);
    DeleteProductList(removeid, data)
      .then((res) => {
        toast.success("Product Deleted successfully");
        handleClose();
        setLoading(false);
        setPagerefresh(true);

      })
      .catch((err) => console.log(err));
  };
  const options = [
    { value: "0", label: "All" },
    { value: "1", label: "Approved" },
    { value: "3", label: "Pending Approval" },
    { value: "4", label: "Rejected" },
  ];


  const [data, setDetails] = useState([]);
  useEffect(() => {
      const userid = localStorage.getItem("userid");
      userProfileInformation(JSON.parse(userid))
          .then((res) => {
              setDetails(res);
          })
          .catch((err) => console.log(err));
  }, [])


  return (
    <div className="mb-5">
      <Fragment>
        <Row>
          <Col sm="12">
            <div className="d-flex justify-content-between mb-3">
              <div className="mt-3">
                <h3>Products List</h3>
              </div>
              <div className="mt-3" style={{ marginRight: "2%" }}>
                {data?.planName==="Listing"?null:<>
                  <Button
                  disabled={userInfo.stateId !== 1 ? true : false}
                  onClick={() => history(`/womeyn/add-product`)}
                >
                  + Add New Product
                </Button>
                </>}
               
              </div>
            </div>
            <Card style={{ margin: "0px", padding: "15px" }}>
              <div className="d-flex justify-content-end mb-5 mt-2">
                <Col lg={3}>
                  <Select
                    onChange={Handlefilterproduct}
                    options={options}
                    placeholder="Status"
                  ></Select>
                </Col>
                <Col lg={4}>
                  <div className="px-3 py-0">
                    <div className="form-group input-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setSearchproductname(e.target.value)}
                        name="searchservicename"
                        value={searchproductname}
                        placeholder="Search by Product Id or Name... "
                      />
                      <span
                        className="input-group-text"
                        onClick={Handlesearchservice}
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          className="icon-20"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="11.7669"
                            cy="11.7666"
                            r="8.98856"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></circle>
                          <path
                            d="M18.0186 18.4851L21.5426 22"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </div>
                </Col>
              </div>
              <Table className="cursor-texts" hover responsive>
                <thead>
                  <tr>
                    <th scope="col">Product Name</th>
                    <th scope="col">Product Category</th>
                    <th scope="col">Product Id</th>
                    <th scope="col">Quantity Left</th>
                    <th scope="col">Price</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody className="m-0 p-0">
                  {demolist?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <div
                              className="d-flex align-items-center"
                              onClick={() =>
                                history(`/womeyn/product-list-edit/${item?.id}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {item?.productThumbImage ? (
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${item?.productThumbImage}`}
                                  alt="no image"
                                  className="image-circle"
                                />
                              ) : (
                                <img
                                  src={"https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo="}

                                  alt="no image"
                                  className="image-circle"
                                />
                              )}
                              <span className="media-support-infos ms-3 grey">
                                <span className="mb-0 cols w-50">
                                  {item?.productName}
                                </span>
                              </span>
                            </div>
                          </td>
                          <td className="text-dark">{item?.categoryName ? item?.categoryName : "-"}</td>
                          <td className="text-dark">{item?.productId}</td>
                          <td className="text-dark">{item?.quantityLeft}</td>
                          <td className="text-dark">A${item?.salePrice}</td>
                          <td className="text-dark">
                            {item?.stateId === 1 && (
                              <span>
                                <div className="d-flex gap-2 justify-content-start samples">
                                  <div className="status-box bg-success"></div>
                                  <span
                                    className="p-bold "
                                    style={{ color: "green" }}
                                  >
                                    Approved
                                  </span>
                                </div>
                              </span>
                            )}
                            {item?.stateId === 2 && (
                              <span>
                                <div className="d-flex gap-2 justify-content-start samples">
                                  <div className="status-box bg-info"></div>
                                  <span
                                    className="p-bold blue-text"
                                    style={{ color: "blue" }}
                                  >
                                    Pending
                                  </span>
                                </div>
                              </span>
                            )}
                            {item?.stateId === 6 && (
                              <>
                                <div className="d-flex gap-2 justify-content-start samples">
                                  <div className="status-box "></div>
                                  <span
                                    className="p-bold red-text"
                                    style={{ color: "#FFC416" }}
                                  >
                                    Pending Approval
                                  </span>
                                </div>
                              </>
                            )}
                            {
                              (item?.stateId === 3 && (
                                <>
                                  <div className="d-flex gap-2 justify-content-start samples">
                                    <div className="status-box "></div>
                                    <span
                                      className="p-bold red-text"
                                      style={{ color: "#FFC416" }}
                                    >
                                      Pending Approval
                                    </span>
                                  </div>
                                </>
                              ))}
                            {item?.stateId === 4 && (
                              <span>
                                <div className="d-flex gap-2 justify-content-start samples">
                                  <div className="status-box bg-danger"></div>
                                  <span
                                    className="p-bold red-text "
                                    style={{ color: "red" }}
                                  >
                                    Rejected
                                  </span>
                                </div>
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="d-flex">
                              <>
                                <Link
                                  className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                  to={`/womeyn/update-product/${item?.id}`}
                                  state={{ product: item }}
                                >
                                  <span className="btn-inner">{edit}</span>
                                </Link>
                              </>

                              <Button
                                className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                onClick={() => handleShow(item?.id)}
                              >
                                <span className="btn-inner">{remove}</span>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
              <div className="mt-5 mb-5 d-flex align-items-center justify-content-center w-100 mx-auto text-center h-100  fw-bold fs-3" >
          {demolist?.length == 0 ? "No Data Found" : ""}
        </div>
              <div>
                <Pagination
                  className="pagination-data"
                  total={limit.totalPages * 10}
                  onChange={handleChangePagecount}
                  current={current}
                  itemRender={PrevNextArrow}
                  breakLabel="..."
                />
              </div>
            </Card>
          </Col>
        </Row>
        <div>
          <Removemodalpopup
            show={show}
            handleClose={handleClose}
            removeid={removeid}
            DeleteProduct={DeleteProduct}
            loading={loading}
          />
        </div>
      </Fragment>
    </div>
  );
}

export default ListProduct;
