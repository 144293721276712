import axios from "axios";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getDataProduct = createAsyncThunk("product/getDataProduct",
    async () => {
        return fetch("https://jsonplaceholder.typicode.com/users").then((res) => res.json());
    }
);
const ProductData = createSlice(
    {
        name: "product",
        initialState: {
            productList: [],
            loading: false,
            error: false
        },
        extraReducers: {
            [getDataProduct.pending]: (state, action) => {
                state.loading = true;
            },
            [getDataProduct.fulfilled]: (state, action) => {
                state.loading = false;
                state.productList = action.payload;
            },
            [getDataProduct.rejected]: (state, action) => {
                state.loading = false;
                state.error = true;
            }
        },

    }
)

export default ProductData.reducer;