
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ListProduct from './components/listproduct/ListProduct'
import Spinner from 'react-bootstrap/Spinner';


function ProductList() {
    const userId = localStorage.getItem("ispaid");
    const [paidusers, setPaidUsers] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const callBackfunction = () => {
        setLoading(true);
        setTimeout(() => {
            history(`/womeyn/subscribe/Product`);
            setLoading(false);
        }, 1200);
    }
    return (
        <>

            <ListProduct />

        </>
    )

}
export default ProductList
