import { HttpInterceptor } from "../auth/http-interceptors";
const userid = localStorage.getItem("user_id");
const ProductCreate = async (data) => {
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/seller/create-product`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err && err.response) reject(err.response);
      });
  });
};
export function GetProductList(data, filterstateid) {
  return HttpInterceptor.get(
    `/seller/products-list/${data}/?sortBy=_id:desc&&stateId=${filterstateid}&&limit=10`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

// serach api calls

export function SearchProductList(data, searchvalue) {
  return HttpInterceptor.get(
    `/seller/products-list/${data}/?sortBy=_id:desc&&search=${searchvalue}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

export function GetproductFilterPagination(id, current) {
  return HttpInterceptor.get(
    `/seller/products-list/${id}?sortBy=_id:desc&page=${current}`
  )
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

const GetCategories = async (data) => {
  return HttpInterceptor.get("/seller/categories-list?typeId=1")
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const GetCategoriesService = async (data) => {
  return HttpInterceptor.get("/seller/categories-list?typeId=2")
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const CreateVariation = async (data) => {
  return HttpInterceptor.post(`/seller/create-variation`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const GetVariationList = async (sellerid, categoryid) => {
  return new Promise((resolve, reject) => {
    HttpInterceptor.get(
      `/seller/variations-list?sellerId=${sellerid}&categoryId=${categoryid}`
    )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => console.log(err));
  });
};

export function GetProductListEdit(productid) {
  return HttpInterceptor.get(`/seller/product-details/${productid}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
}

export function DeleteProductList(productid, stateId) {
  return HttpInterceptor.post(
    `/seller/product-update-status/${productid}`,
    stateId
  )
    .then((res) => {
      console.log(res, "check");
      return res.data;
    })
    .catch((err) => console.log(err));
}

// export function EditProductData(productid, data) {
//     //  https://eapi.tamilspark.com/v1/seller/update-photo/user
//     return HttpInterceptor.post(`/seller/update-product/${productid}`, data).then((res) => {
//         return res.data;
//     }).catch((err) => console.log(err))

// }
const EditProduct = async (productId, data) => {
  // https://womeynapi.cdp360.in/v1/seller/update-product/:productId
  return new Promise((resolve, reject) => {
    HttpInterceptor.post(`/seller/update-product/${productId}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const testStripe = async (data) => {
  //  https://eapi.tamilspark.com/v1/seller/update-photo/user
  return HttpInterceptor.post(`/seller/checkout`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};
const UploadImages = async (data) => {
  //  https://eapi.tamilspark.com/v1/seller/update-photo/user
  return HttpInterceptor.post(`/seller/update-photo/${userid}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const UploadBannerImage = async (data, productId) => {

  return HttpInterceptor.post(
    `/seller/update-thumb-image/${productId}?typeId=1`,
    data
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};

const ProductAvailablecountCheck = async () => {


  return HttpInterceptor.get(
    `/seller/check-availability`,
  )
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log(err));
};



export function ProductDeleteImage(data) {
  return HttpInterceptor.delete(`/seller/delete`, { data })
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}





export default {
  testStripe,
  ProductCreate,
  GetProductList,
  EditProduct,
  GetCategories,
  CreateVariation,
  GetVariationList,
  GetProductListEdit,
  UploadImages,
  GetproductFilterPagination,
  UploadBannerImage,
  GetCategoriesService,
  ProductAvailablecountCheck
};
