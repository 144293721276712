import { useState, memo, Fragment, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const CloseConfirm = memo((props) => {

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Close ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to close this ticket ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={props.closeTicket}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
})

export default CloseConfirm;
