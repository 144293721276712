import React from "react";
import Modal from "react-bootstrap/Modal";

import Spinner from "react-bootstrap/Spinner";
import { Row, Col, Image, Form, ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Select from "react-select";

function Editinventory({
  show,
  handleClose,
  filedvalue,
  DeleteProduct,
  loading,
  inventorydetail,
  handleChange,
  handleSubmitProductUpdate,
  editinventoryloading
}) {
  const options = [
    {
      value: "women",
      label: "women",
    },
    {
      value: "myself",
      label: "myself",
    },
  ];

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="mt-4 mb-2 d-flex align-items-center justify-content-center flex-direction-column ms-5">
          <div>
            <div className="col-lg-11">
              <div className="text-center mb-4 mt-2">
                <h4>Edit Inventory</h4>
              </div>
              <Row>
                <Col>
                  <div className="form-group">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label htmlFor="quantityLeft">Quantity</Form.Label>
                      <Form.Control
                        type="number"
                        onChange={handleChange}
                        name="quantityLeft"
                        value={inventorydetail.quantityLeft}
                        placeholder="Enter quantityLeft"
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    required
                  >
                    <Form.Label>Actual price</Form.Label>
                    <Form.Control
                      type="number"
                      name="actualPrice"
                      onChange={handleChange}
                      value={inventorydetail.actualPrice}
                      placeholder="Enter ActualPrice"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    required
                  >
                    <Form.Label>Offer percentage</Form.Label>
                    <Form.Control
                      type="number"
                      name="offerPercentag"
                      onChange={handleChange}
                      value={inventorydetail.offerPercentag}
                      placeholder="Enter offerPercentag"
                    />
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicEmail"
                    required
                  >
                    <Form.Label>Price</Form.Label>
                    <Form.Control
                      type="number"
                      name="salePrice"
                      onChange={handleChange}
                      value={
                        filedvalue ? filedvalue : inventorydetail.salePrice
                      }
                      placeholder="Enter Saleprice"
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>isShippingRequired</Form.Label>
                                        <Form.Control type="number" name="isShippingRequired" onChange={handleChange} value={inventorydetail.offerPercentag} placeholder="Enter quantityLeft" />
                                    </Form.Group> */}
                  <Form.Select
                    id="validationDefault05"
                    name="isShippingRequired"
                    value={inventorydetail.isShippingRequired}
                    onChange={handleChange}
                  >
                    <option value="">--Please choose Shipping By--</option>
                    <option label="Myself" value={1}>
                      Womenpreneur
                    </option>
                    <option label="Womeyn" value={2}>
                      Womeyn
                    </option>
                  </Form.Select>
                  {/* <Select
                                    required
                                    placeholder="Select Shipping By..."
                                        name="isShippingRequired"
                                        value={{
                                            label: inventorydetail.isShippingRequired === 1 && "Myself",
                                            value: inventorydetail.isShippingRequired === 2 && "Womeyn"
                                        }}
                                        onChange={handleChange}
                                        options={options}
                                    /> */}
                </Col>
                {inventorydetail.isShippingRequired == 1 && (
                  <div className="mt-3">
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicEmail"
                        required
                      >
                        <Form.Label>Outside Country Delivery Charge</Form.Label>
                        <Form.Control
                          type="text"
                          name="outside"
                          onChange={handleChange}
                          value={inventorydetail.outside}
                          placeholder="Enter Outside Country Delivery Charge"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicEmail"
                        required
                      >
                        <Form.Label>Local Delivery Charge</Form.Label>
                        <Form.Control
                          type="text"
                          name="local"
                          onChange={handleChange}
                          value={inventorydetail.local}
                          placeholder="Enter Local Delivery Charge"
                        />
                      </Form.Group>
                    </Col>
                  </div>
                )}
              </Row>

              <div className="d-flex gap-3 align-items-center justify-content-center mt-3 mb-4">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmitProductUpdate}>
                  {editinventoryloading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <span className="ms-2">Loading...</span>
                    </>
                  ) : (
                    <>Update</>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default Editinventory;
