import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import order_services from "../../../services/order_services/order_services";
import { OrdersDetailsById, OrdersStatusUpdate } from "../../../services/order_services/singleOrder";
import "./styles/Orderlist.scss";
import { DownloadBillInvoice, DownloadRevenue } from "../../../services/invoice_services/invoice_services";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

import Modal from "react-bootstrap/Modal";

export default function ViewOrderDetails() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { id } = useParams();

  const [ordercancelId, setOrderCancelId] = useState("");
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true)
    setOrderCancelId(id);
  };

  const [invoiceloading, setInvoiceLoading] = useState(false);
  const [invoiceloading1, setInvoiceLoading1] = useState(false);

  const history = useNavigate();

  const [billdata, setBillData] = useState("");
  const [billdata1, setBillData1] = useState("");


  const [orderDetails, setOrderDetails] = useState("");

  const [show, setShow] = useState(false);
  const [removeid, setRemoveid] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setRemoveid(id);
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = () => {
    OrdersDetailsById(id)
      .then((res) => {

        setOrderDetails(res.data.results);

        if (res?.data?.results) {
          res?.data?.results?.map((item, index) => {
            setOrderStatusChange({
              shippingDate: item?.trackingDetails?.shippingDate, trackingId: item?.trackingDetails?.trackingId, trackingURL: item?.trackingDetails?.trackingURL, statusId: res?.data?.results[0]?.stateId

            })
          })
        }

      })
      .catch((err) => console.log(err));
  };




  const ViewInvoicedownload = (orderids) => {

    setInvoiceLoading(true);

    DownloadBillInvoice(orderids).then((res) => {
      setBillData(res?.data?.url);
      window.open(res?.data?.url);
      setTimeout(() => {
        setInvoiceLoading(false);
      }, 600);
    }).catch((err) => {
      console.log(err);
      setInvoiceLoading(false);
    })
  }

  const ViewRevenuedownload = (orderids) => {
    setInvoiceLoading1(true);
    DownloadRevenue(orderids).then((res) => {
      setBillData1(res?.data?.url);
      window.open(res?.data?.url);
      setTimeout(() => {
        setInvoiceLoading1(false);
      }, 600);
    }).catch((err) => {
      console.log(err);
      setInvoiceLoading1(false);
    })
  }


  const cancelOrder = () => {
    order_services.cancelOrder(ordercancelId).then(result => {
      if (result) {
        toast.success('Order Cancelled Successfully');
        handleClose1();

      }
    }).catch(err => {
      console.log(err)
    })
  }


  const [loading, setLoading] = useState(false);

  const [orderStatuschange, setOrderStatusChange] = useState({
    shippingDate: "", trackingId: "", trackingURL: "", statusId: "",
  })

  const [orderstatuserror, setOrderStatusError] = useState(false);

  const { shippingDate, trackingId, trackingURL, statusId } = orderStatuschange;


  const handleChange = (e) => {
    setOrderStatusChange({ ...orderStatuschange, [e.target.name]: e.target.value })
  }



  const UpdateOrderStatus = () => {

    if (shippingDate?.length === 0 || trackingId?.length === 0 || trackingURL?.length === 0 || statusId?.length === 0) {
      setOrderStatusError(true);
    }
    if (shippingDate && trackingId && trackingURL && statusId) {
      const datas = {
        shippingDate: shippingDate, trackingId: trackingId, trackingURL: trackingURL, statusId: statusId, orderId: id
      }
      setLoading(true)
      OrdersStatusUpdate(datas).then((res) => {
        toast.success("Order Status updated")
        handleClose();
        setLoading(false)
      }).catch((error) => {
        console.log(error);
        setLoading(false)

      })
    }
  }




  const handleConfirmOrder = () => {

  }

  if (!orderDetails) {
    return null;
  }

  return (
    <>
      <div>
        <h3 className="mb-4">Order Details </h3>
        {orderDetails.map((item) => {
          return (
            <>
              <Row>
                <Col>
                  <Card style={{ padding: "0px" }}>
                    <Card.Header className="d-flex justify-content-between">
                      {/* <div className="header-title">
                      <h4 className="card-title">Customer Details</h4>
                    </div> */}
                    </Card.Header>
                    <Card.Body>
                      <div className="new-user-info">
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Name:</h6>
                            <p>{item.customerName}</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Address:</h6>

                            {item?.deliveryAddress[0].fullAddress ? <>
                              <p>{item.deliveryAddress[0].fullAddress}</p>
                            </> : <>
                              <div>
                                {item?.deliveryAddress.map((items, index) => {
                                  return (
                                    <div>
                                      {items?.cityName},{items?.landMark},{items?.stateName},{items?.countryName},{items?.pinCode}
                                    </div>
                                  )
                                })}

                              </div>
                            </>}


                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Payment Status</h6>
                            <p>{item.paymentStatus}</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Payment Method</h6>
                            <p>{item.paymentMethod}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Ordered Date</h6>
                            <p>{moment(item.orderedDate).format("LLLL")}</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Item Price</h6>
                            <p>A$ {item.itemsOrdered[0].price}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Order GST</h6>
                            <p>A$ {item.itemsOrdered[0].salePriceGST}</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Order Status</h6>
                            {item.stateId === 1 && <p>New Order</p>}
                            {item.stateId === 2 && <p>Confirmed</p>}
                            {item.stateId === 3 && <p>In Progress</p>}
                            {item.stateId === 4 && <p>Cancelled by Buyer</p>}
                            {item.stateId === 5 && <p>Cancelled by Womenpreneurs</p>}
                            {item.stateId === 6 && <p>Delivered</p>}
                            {item.stateId === 7 && <p>Packing Completed</p>}
                            {item.stateId === 8 && <p>Shipping Completed</p>}
                            {item.stateId === 9 && <p>Out for Delivery</p>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Delivery Fee</h6>
                            <p>A$ {item.itemsOrdered[0].deliveryFee}</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Delivery GST</h6>
                            <p>A$ {item.itemsOrdered[0].deliveryChargeGST}</p>
                          </div>


                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Discount Amount</h6>
                            {item.discountAmount ?
                              <p>A$ {item.discountAmount}</p>

                              : <>-</>}
                          </div>
                          <div className="col-md-6">
                            <h6>Coupon Code</h6>
                            {item?.couponCode ?
                              <p>{item?.couponCode}</p>
                              : <>-</>}

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h6>Qunatity Ordered</h6>
                            {item.itemsOrdered?.map((items, index) => {
                              return (
                                <div>
                                  {items?.quantity}
                                </div>
                              )
                            })}
                          </div>
                          <div className="col-md-6">
                            <h6>Total Amount</h6>
                            <p>A$ {item.totalOrderAmount}</p>
                          </div>
                        </div>

                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <div className="">

                <div className="header-container">
                  <p className="header-btn">{item?.orderId}</p>
                  <div className="d-flex gap-2">

                    <div>
                      {item.stateId === 2 ? <Button

                        onClick={() => {
                          handleShow1(item?.orderId);
                        }}


                        className='invoiceprint' >
                        <div className='d-flex gap-3'>
                          <div>
                            Cancel Order
                          </div>
                        </div>
                      </Button> : ""

                      }
                    </div>
                    <div className="rightside-btn">

                      <Button
                        className="invoiceprint mx-5"
                        onClick={() => ViewRevenuedownload(orderDetails[0]?.orderId)}
                      >
                        {invoiceloading1 ? <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="ms-2">Loading...</span>
                        </> : <> View Revenue</>}

                      </Button>
                    </div>

                    <div className="rightside-btn">
                      <Button
                        className="invoiceprint "
                        onClick={() => ViewInvoicedownload(orderDetails[0]?.orderId)}
                      >

                        <div className="d-flex gap-3">
                          <div>
                            {invoiceloading ? <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="ms-2">Loading...</span>
                            </> : <> View Invoice</>}
                          </div>
                        </div>
                      </Button>
                    </div>

                    {item.shippingDetails ? (
                      <div className="rightside-btn">
                        {item.shippingDetails.status === "completed" ? (
                          <Button
                            className="invoiceprint mx-5"
                            onClick={() =>
                              window.open(
                                item.shippingDetails.label_download.png,
                                "_blank"
                              )
                            }
                          >
                            <div className="d-flex gap-2">
                              <div>View Label</div>
                            </div>
                          </Button>
                        ) : item.shippingDetails.status !== "cancelled" ||
                          item.shippingDetails.status !== "completed" ? (
                          <Button className="invoiceprint mx-5">
                            <div className="d-flex gap-3">
                              <div>Track Order</div>
                            </div>
                          </Button>
                        ) : null}


                      </div>
                    ) : <div>
                      <Button className="invoiceprint mx-5" onClick={handleShow}>
                        <div className="d-flex gap-3">
                          <div>Update Order Status</div>
                        </div>
                      </Button>
                    </div>}
                  </div>
                </div>

              </div>
              {item.itemsOrdered.map((e) => {
                return (
                  <>
                    <div className="mt-4">
                      <div className="content-container">
                        <div>
                          {e?.productThumbImage ? (
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}/${e.productThumbImage}`}
                              className="set-img"
                            />
                          ) : (
                            <img
                              src={
                                "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fae01.alicdn.com%2Fkf%2FHTB1mnnhaYYI8KJjy0Faq6zAiVXa4%2FKids-Party-Wear-Frocks-Kids-Party-Dress-for-Winter-Girls-Puffy-Dresses-Children-Vintage-Dresses-Big.jpg&f=1&nofb=1&ipt=8ebd3f3ecdf1134c57d9f35534468b857f033a925d6c6bef13ba529599b4ddfd&ipo=images"
                              }
                              className="set-img"
                            />
                          )}
                        </div>
                        <div>
                          <p className="header-text">Name</p>
                          <div style={{
                            width: "300px"
                          }} className="mb-4 mt-2">{e.productName}</div>
                          <p className="header-text">Seller</p>
                          <span>{item.sellerBusinessName}</span>

                        </div>
                        <div>
                          <p className="header-text">Item Price</p>
                          <p>A$ {e.price}</p>
                          <p className="header-text">GST</p>
                          <p>A$ {item.itemsOrdered[0].salePriceGST}</p>
                        </div>
                        <div className="">
                          <h6>Qunatity Ordered</h6>
                          {item.itemsOrdered?.map((items, index) => {
                            return (
                              <div>
                                {items?.quantity}
                              </div>
                            )
                          })}

                          <div className="mt-3">


                            {e.variations ? (
                              <>
                                {e.variations.map((item) => {
                                  return (
                                    <div className="">
                                      <p className="header-text">{item?.name}:</p>
                                      <p className="ms-1"> {item?.value}</p>
                                    </div>
                                  );
                                })}
                              </>
                            ) : null}
                          </div>
                        </div>


                        <div>
                          <p className="header-text">Expected Delivery</p>
                          <p>
                            {moment(
                              item.itemsOrdered[0].expectedDeliveryDate
                            ).format("LLLL")}
                          </p>
                        </div>

                      </div>
                    </div>
                  </>
                );
              })}

              <div className="footer-container mt-1 mb-5">
                <div></div>
                <p className="mb-0 fs-4">
                  Order Total :
                  <span className="text-black "> A$ {item.totalOrderAmount}</span>
                </p>
              </div>
            </>
          );
        })}
      </div>


      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <div className="mt-4 mb-2 p-5 ">
            <div>
              <div className="">
                <div className="text-center mb-5 mt-2">
                  <h4 className="mb-5">Update Order Status</h4>
                </div>
                <div className="mt-5">
                  <Row>
                    <Col lg="">

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="quantityLeft">Shipping Date</Form.Label>
                        <Form.Control
                          type="Date"
                          onChange={handleChange}
                          name="shippingDate"
                          value={shippingDate}
                          placeholder="Enter shippingDate"
                        />

                        <div className="mb-2 mt-2">
                          {orderstatuserror && shippingDate?.length <= 0 ? <span className="text-danger">Field Is Required</span> : null}
                        </div>
                      </Form.Group>



                    </Col>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicEmail"
                        required
                      >
                        <Form.Label>Tracking Id</Form.Label>
                        <Form.Control
                          type="text"
                          name="trackingId"
                          onChange={handleChange}
                          value={trackingId}
                          placeholder="Enter trackingId"
                        />
                        <div className="mb-2 mt-2">
                          {orderstatuserror && trackingId?.length <= 0 ? <span className="text-danger">Field Is Required</span> : null}
                        </div>
                      </Form.Group>

                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicEmail"
                        required
                      >
                        <Form.Label>Tracking URL</Form.Label>
                        <Form.Control
                          type="text"
                          name="trackingURL"
                          onChange={handleChange}
                          value={trackingURL}
                          placeholder="Enter trackingURL"
                        />

                        <div className="mb-2 mt-2">
                          {orderstatuserror && trackingURL?.length <= 0 ? <span className="text-danger">Field Is Required</span> : null}
                        </div>
                      </Form.Group>

                    </Col>

                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicEmail"
                        required
                      >
                        <Form.Label>Order Status</Form.Label>
                        <Form.Select aria-label="Default select example" value={statusId} name="statusId" onChange={handleChange}>
                          <option>--select Order Status--</option>
                          <option value="3">
                            In Progress
                          </option>
                          <option value="7">
                            Packing Completed
                          </option>  <option value="8">
                            Shipping Completed
                          </option>  <option value="9">
                            Out for Delivery
                          </option>  <option value="6">
                            Delivered
                          </option>
                        </Form.Select>
                        <div className="mb-2 mt-2">
                          {orderstatuserror && statusId?.length <= 0 ? <span className="text-danger">Field Is Required</span> : null}
                        </div>
                      </Form.Group>


                    </Col>

                  </Row>

                </div>



                <div className="d-flex gap-3 align-items-center justify-content-center mt-5 mb-3">
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={UpdateOrderStatus}>
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-2">Loading...</span>
                      </>
                    ) : (
                      <>Update</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <div className="mt-4 mb-2 p-5 ">
            <div>
              <div className="">
                <div className="text-center mb-5 mt-2">
                  <h4 className="mb-5">Cancel Order</h4>
                </div>
                <div className="mt-5 fs-3 text-center">


                  Are you sure you want to Cancel this Order?



                </div>



                <div className="d-flex gap-3 align-items-center justify-content-center mt-5 mb-3">
                  <Button variant="secondary" onClick={handleClose1}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={cancelOrder}>
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-2">Loading...</span>
                      </>
                    ) : (
                      <>Cancel</>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal >
      </div >

    </>

  );
}
