import { HttpInterceptor } from "../auth/http-interceptors";

export function GetServices() {
  return HttpInterceptor.get(`/seller/service-bookings`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

export function ServiceSearch(searchservice) {
  return HttpInterceptor.get(`/seller/service-bookings&search=${searchservice}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}


export function ServiceOrdergetsingle(serviceorderid) {
  return HttpInterceptor.get(`/seller/service-bookings?orderId=${serviceorderid}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

export function ServiceDownloadBillInvoice(serviceorderid) {
  return HttpInterceptor.get(`/seller/service/invoice/${serviceorderid}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

export function ServiceDownloadRevenue(serviceorderid) {
  return HttpInterceptor.get(`/seller/service/revenue/${serviceorderid}`)
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

export function UploadBannerImage(data, productId) {

  // return HttpInterceptor.post(
  //   `/seller/update-thumb-image/${productId}?typeId=1`,
  //   data
  // )
  //   .then((res) => {
  //     return res.data;
  //   })
  //   .catch((err) => console.log(err));

  return HttpInterceptor.post(`/seller/update-thumb-image/${productId}?typeId=2`, data)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => console.log(err));
};

export function ServiceDetailsEdit(data) {



  return HttpInterceptor.get(`/seller/service-details/${data}`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => console.log(err));
};


export function ServiceDetailsEditVariations(data) {



  return HttpInterceptor.get(`/seller/service-variations-list/${data}`)
    .then((res) => {
      return res?.data;
    })
    .catch((err) => console.log(err));
};


export function ServiceDeleteImage(data) {

  return HttpInterceptor.delete(`/seller/delete`, { data })
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}


export function ServiceDeleteVariations(data) {
  return HttpInterceptor.delete(`/seller/delete`, { data })
    .then((res) => {
      return res;
    })
    .catch((err) => console.log(err));
}

export function getSingleSellerServices(data) {
  return HttpInterceptor.get(
      `/admin/services/${data}`
  );
}