import { HttpInterceptor } from "../auth/http-interceptors";
class supportServices {
    saveTicket = (data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post('/seller/tickets', data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    savereply = (data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post('/seller/tickets', data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    uploadChatImages = (message_id, data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post(`/seller/add-chat-attachements/${message_id}`, data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })

        })
    }
    closeTicket = (data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post('/seller/tickets', data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    getTickets = (id, current) => {
        let url;
        if (id) {
            url = '/seller/ticket-view/' + id;
        } else {
            url = `/seller/tickets?limit=10`
        }
        return new Promise((resolve, reject) => {
            HttpInterceptor.get(url).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    getTicketsPagination = (current) => {
        let url = `/seller/tickets?limit=10&page=${current}`
        return new Promise((resolve, reject) => {
            HttpInterceptor.get(url).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    SearchTickets = (searchname) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.get(`/seller/tickets?limit=150&&search=${searchname}`).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new supportServices();
