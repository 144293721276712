import { HttpInterceptor } from "../auth/http-interceptors";
export function VerifyOtpCheck(data) {
    return HttpInterceptor
        .post(`/auth/seller/verify-otp`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function ResendOtpCheck(userid) {
    return HttpInterceptor
        .post(`/auth/seller/resend-otp`, userid)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}


export const Signups = (data) => {
    return HttpInterceptor.post('/auth/seller/register', data).then(response => {
        return response.data
    }).catch((err) => {
        console.log(err)
    })

}