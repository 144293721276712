import { useState, memo, Fragment, useEffect } from 'react'
import { Row, Col, Image, Nav, Tab, Form, Button, Table } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Card from '../../../../../components/bootstrap/card'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";
import Swal from "sweetalert2";
import './styles/ProductListEdit.scss';
import product_services from '../../../../../services/product_services/product_services';
const ProductListEdit = memo(() => {
    const { id } = useParams();
    const [indeximage, setIndexImage] = useState(0);
    const [slideimages, setSlideimages] = useState([]);
    const [single, setSingle] = useState([]);
    const history = useNavigate();
    useEffect(() => {
        getFunctions();
    }, [])
    const getFunctions = () => {
        product_services.GetProductListEdit(id).then((res) => {
            setSingle(res);
            setSlideimages(res?.productImages)
        }).catch((err) => {
            console.log(err);
        })
    }
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    function Sweetalert() {
        Swal.fire({
            icon: 'success',
            title: 'Added!',
            text: 'Your item has been Added to the cart.'
        })
    }
    return (
        <Fragment>
            <Row>
                <Card className="p-0 m-0">
                    <Card.Body className="p-0 m-0">
                        <Row className="align-items-centers">
                            <Col lg="7">
                                <div className="product-vertical-slider">
                                    <div className="slider__flex">
                                        <div className="slider__col">
                                            <div className="slider__thumbs">
                                                <Swiper
                                                    className="iq-swiper swiper-container gallery-top text-end"
                                                    direction={"vertical"}
                                                    onSwiper={setThumbsSwiper}
                                                    slidesPerView={3}
                                                    spaceBetween={10}
                                                    freeMode={true}
                                                    watchSlidesProgress={true}
                                                    modules={[FreeMode, Thumbs]}
                                                    loop={true}
                                                >
                                                    {slideimages.map((item, index) => {
                                                        return (
                                                            <div className='col-lg-2'>
                                                                <SwiperSlide>
                                                                    <div className="slider__image" onClick={() => setIndexImage(index)}>
                                                                        <Image alt="product-15" src={`${process.env.REACT_APP_IMAGE_URL}/${item?.name}`} style={{ objectFit: "cover" }} />
                                                                    </div>
                                                                </SwiperSlide>
                                                            </div>

                                                        )
                                                    })}


                                                </Swiper>
                                            </div>
                                        </div>
                                        <div className="slider__images">
                                            <Swiper
                                                className="swiper-container gallery-thumbs"
                                                direction={"vertical"}
                                                spaceBetween={10}
                                                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                                                mousewheel={true}
                                                modules={[FreeMode, Thumbs, Mousewheel]}
                                                loop={true}
                                            >

                                                <SwiperSlide>
                                                    <div className="slider__image">
                                                        <Image alt="product-15" src={`${process.env.REACT_APP_IMAGE_URL}/${slideimages[indeximage]?.name}`} style={{ objectFit: "cover" }} />
                                                    </div>
                                                </SwiperSlide>
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="5">
                                <div className="border-bottoms mt-5">
                                    <div className="d-flex flex-column mb-3">
                                        <div className="d-flex justify-content-between mb-2 mt-1">
                                            <h4 className="mb-0 fs-2">{single?.productName}</h4>
                                            <div className="d-flex justify-content-end ">
                                            </div>
                                        </div>

                                    </div>
                                    <div className="d-flex my-4">
                                        <h4 className="mb-0"> Price:</h4>
                                        <h4 className="text-primary mb-0 ms-2">$ {single?.salePrice}</h4>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="mt-5">
                    <Card.Body className="p-0 m-0">
                        <Tab.Container defaultActiveKey="first">
                            <Nav className="tab-bottom-bordered">
                                <div className="mb-0 nav nav-tabs" id="nav-tab1" role="tablist">
                                    <Nav.Link eventKey="first" id="nav-description-tab" data-bs-toggle="tab" data-bs-target="#nav-description" type="button" role="tab" aria-controls="nav-description" aria-selected="true">Description</Nav.Link>
                                    <Nav.Link id="nav-info-tab" eventKey="second" data-bs-toggle="tab" data-bs-target="#nav-info" type="button" role="tab" aria-controls="nav-info" aria-selected="false">Info</Nav.Link>
                                    <Nav.Link id="nav-review-tab" eventKey="thired" data-bs-toggle="tab" data-bs-target="#nav-review" type="button" role="tab" aria-controls="nav-review" aria-selected="false">Reviews</Nav.Link>
                                </div>
                            </Nav>
                            <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
                                <Tab.Pane eventKey="first" id="nav-description" role="tabpanel" aria-labelledby="nav-description-tab">
                                    <div className="d-flex flex-column">
                                        {single?.productDescription}
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" id="nav-info" role="tabpanel" aria-labelledby="nav-info-tab">
                                    <Table bordered className="mb-0">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    BrandName
                                                </th>
                                                <td>
                                                    {single?.brandName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Model Number
                                                </th>
                                                <td>
                                                    {single?.modelName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Style Name
                                                </th>
                                                <td>
                                                    {single?.styleName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Manufacturer Name
                                                </th>
                                                <td>
                                                    {single?.manufacturerName}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Quantity
                                                </th>
                                                <td>
                                                    {single?.quantityLeft}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Actual Price
                                                </th>
                                                <td>
                                                    {single?.actualPrice}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Offer Percentage
                                                </th>
                                                <td>
                                                    {single?.offerPercentag}%
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Sale Price
                                                </th>
                                                <td>
                                                    {single?.salePrice}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Target Gender
                                                </th>
                                                <td>
                                                    {single?.targetGender}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    IsShipping Required
                                                </th>
                                                <td>
                                                    {single?.isShippingRequired == 1 && "MySelf"}
                                                    {single?.isShippingRequired == 2 && "ShipEngine offered by Womeyn"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab.Pane>

                                <Tab.Pane eventKey="thired" id="nav-review" role="tabpanel" aria-labelledby="nav-review-tab">

                                    No Review !!!

                                    {/* <h2 className="mb-0 border-bottom pb-3">Review</h2>
                                    <div>
                                        <Card className="shadow-none bg-transparent border">
                                            <Card.Body>
                                                <div className="d-flex flex-sm-nowrap flex-wrap">
                                                    <div>
                                                        <Image alt="product-15" src={`${process.env.REACT_APP_IMAGE_URL}/${single?.productThumbImage}`} style={{ objectFit: "cover", width: "100px", height: "100px", borderRadius: "20%" }} />

                                                    </div>
                                                    <div className="ms-0 ms-sm-3">
                                                        <div className="d-flex justify-content-between align-items-center my-2 my-lg-0">
                                                            <h6 className="mb-0">Emma Jones</h6>
                                                            <Link className="text-dark " to="#">Reply</Link>
                                                        </div>
                                                        <small className="text-primary">March 01st 2021</small>
                                                        <p className="mt-2 mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut eu morbi tincidunt erat egestas quisque ultrices ut. Vel elementum blandit et tellus sit tincidunt.</p>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                        <Card className="shadow-none bg-transparent border">
                                            <Card.Body>
                                                <div className="d-flex flex-sm-nowrap flex-wrap">
                                                    <div>
                                                        <Image alt="product-15" src={`${process.env.REACT_APP_IMAGE_URL}/${single?.productThumbImage}`} style={{ objectFit: "cover", width: "100px", height: "100px", borderRadius: "20%" }} />

                                                    </div>
                                                    <div className="ms-0 ms-sm-3">
                                                        <div className="d-flex justify-content-between align-items-center my-2 my-lg-0">
                                                            <h6 className="mb-0">Jackson Jones</h6>
                                                            <Link className="text-dark " to="#">Reply</Link>
                                                        </div>
                                                        <small className="text-primary">March 20th 2021</small>
                                                        <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam ut eu morbi tincidunt erat egestas quisque ultrices ut. Vel elementum blandit et tellus sit tincidunt.</p>
                                                        <div className="d-flex mb-3">
                                                            <Link className="" to="#">Reply To Jackson Jones</Link>
                                                            <Link className="text-body ms-3" to="#">Cancel Reply</Link>
                                                        </div>
                                                        <Col lg="12">
                                                            <div className="form-group ">
                                                                <Form.Control type="text" placeholder=" Hi there, I love your blog " />
                                                            </div>
                                                        </Col>
                                                        <div className="d-flex">
                                                            <Button type="submit" className="primary rounded">Get Started</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div> */}

                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Card.Body>
                </Card>
                <Col lg="12" md="12">
                    <Row className="row-cols-1">
                        <div className="overflow-hidden slider-circle-btn" id="ecommerce-slider">
                            <Swiper className="p-0 m-0 mb-4 swiper-wrapper list-inline"
                                slidesPerView={3}
                                spaceBetween={32}
                                modules={[Navigation]}
                                navigation={{
                                    nextEl: '#swiper-button-next1',
                                    prevEl: '#swiper-button-prev1'
                                }}
                                breakpoints={{
                                    320: { slidesPerView: 1 },
                                    550: { slidesPerView: 2 },
                                    991: { slidesPerView: 3 },
                                    1400: { slidesPerView: 3 },
                                    1500: { slidesPerView: 3 },
                                    1920: { slidesPerView: 4 },
                                    2040: { slidesPerView: 4 },
                                    2440: { slidesPerView: 4 }
                                }}>

                            </Swiper>
                            <div id="swiper-button-next1" className="swiper-button swiper-button-next"></div>
                            <div id="swiper-button-prev1" className="swiper-button swiper-button-prev"></div>
                        </div>
                    </Row>
                </Col>
            </Row>
        </Fragment>
    )
})
ProductListEdit.displayName = "ProductListEdit"
export default ProductListEdit
