import { configureStore } from '@reduxjs/toolkit';

import settingReducer from './setting/reducers';
import UserData from './Reducers/users';
import Products from './Reducers/products';
export const store = configureStore({
  reducer: {
    setting: settingReducer,
    loginuser: UserData,
    products: Products
  }
});
