// import React, { useEffect, useState, useRef } from "react";

// import { Button, Row, Col, Form } from "react-bootstrap";

// let autoComplete;


// function Googleautocomplete({
//     formikBusinessDetailserror, touch, values, change,
//     businessPinCode,
//     name,
//     setBusinessCode,
//     businesscode,

//     setOverallCountrys,
//     setOverallCountrys1,
//     setOverallCountrys2,
//     setOverallCountrys3,

//     personalscodeerror1,
//     setBusinessPostalcode,
//     newpostalcode,
//     sellerusers,
//     sameAsPersonalAddress
// }) {



//     console.log(sellerusers, 'over')


//     const autoCompleteRef = useRef(null);
//     const [query, setQuery] = useState("");
//     setBusinessPostalcode(autoCompleteRef)



//     const loadScript = (url, callback) => {
//         let script = document.createElement("script");
//         script.type = "text/javascript";

//         if (script.readyState) {
//             script.onreadystatechange = function () {
//                 if (
//                     script.readyState === "loaded" ||
//                     script.readyState === "complete"
//                 ) {
//                     script.onreadystatechange = null;
//                     callback();
//                 }
//             };
//         } else {
//             script.onload = () => callback();
//         }

//         script.src = url;
//         document.getElementsByTagName("head")[0].appendChild(script);
//     };

//     function handleScriptLoad(updateQuery, autoCompleteRef) {
//         autoComplete = new window.google.maps.places.Autocomplete(
//             autoCompleteRef.current,
//             { types: ["postal_code"], componentRestrictions: { country: "AUS" } }
//         );

//         autoComplete.setFields([
//             "address_components",
//             "formatted_address",
//             "geometry",
//         ]);
//         autoComplete.addListener("place_changed", () =>
//             handlePlaceSelect(updateQuery)
//         );



//     }
//     const getAddressFromGoogle = (event) => {
//         let value = event.target.value || " ";
//         const limit = 4;
//         setQuery(value.slice(0, limit));
//     };
//     async function handlePlaceSelect(updateQuery) {
//         const addressObject = autoComplete.getPlace();
//         const query = addressObject;
//         updateQuery(query);
//     }



//     useEffect(() => {
//         loadScript(
//             `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
//             () => handleScriptLoad(setQuery, autoCompleteRef)
//         );
//     }, []);


//     useEffect(() => {




//         if (query && query.address_components) {
//             for (const component of query.address_components) {
//                 const componentType = component.types[0];
//                 if (componentType === "locality") {
//                     setOverallCountrys(component.long_name)
//                 }
//                 if (componentType === "administrative_area_level_1") {

//                     setOverallCountrys1(component.long_name)
//                     setOverallCountrys2(component.short_name)
//                 }
//                 if (componentType === "country") {
//                     if (component.long_name === "Australia") {

//                         setOverallCountrys3("Australia")
//                     }
//                 }
//                 if (componentType === "postal_code") {
//                     setQuery(component.long_name);
//                     setBusinessCode(component.long_name);
//                 }
//             }

//         }
//     }, [query, setOverallCountrys, setOverallCountrys1, setOverallCountrys2, setOverallCountrys3, businessPinCode]);



//     console.log(query, 'query')

//     return (
//         <div>
//             <Col lg="12">
//                 <div className="form-group">
//                     <Form.Label htmlFor={businessPinCode}>
//                         Business Post Code
//                         {businesscode}
//                         {sameAsPersonalAddress ? sellerusers?.postelCode : "null"} thala
//                     </Form.Label>
//                     <div className="">
//                     </div>
//                     <Form.Control
//                         type="number"
//                         name={name}
//                         maxLength={5}
//                         id={name}
//                         ref={autoCompleteRef}
//                         onChange={
//                             getAddressFromGoogle ||
//                             change
//                         }
//                         placeholder="Enter Post code"
//                         value={sameAsPersonalAddress ? sellerusers?.postelCode : query?query:businesscode}
//                     />

//                     <div className="mt-2">
//                         {personalscodeerror1 && <span className="text-danger mt-2">{autoCompleteRef?.current?.value?.length <= 2 ? "Business Post Code Required" : null}</span>}

//                     </div>

//                 </div>
//             </Col>
//         </div>
//     )
// }

// export default Googleautocomplete






import React, { useEffect, useState, useRef } from "react";

import { Button, Row, Col, Form } from "react-bootstrap";

let autoComplete;

function Googleautocomplete({
    businessPinCode,
    setBussinessPincode,
    businesserrror,
    setBussinessCityName,
    setBussinessState,
    setBussinessStateShortCode,
    setBussinessCountryName
}

) {



    const autoCompleteRef = useRef(null);

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (
                    script.readyState === "loaded" ||
                    script.readyState === "complete"
                ) {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    function handleScriptLoad(updateQuery, autoCompleteRef) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            { types: ["postal_code"], componentRestrictions: { country: "AUS" } }

        );



        autoComplete.setFields([
            "address_components",
            "formatted_address",
            "geometry",
            "name"
        ]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );
    }

    async function handlePlaceSelect(updateQuery) {
        const addressObject = autoComplete.getPlace();
        const query = addressObject;
        updateQuery(query);

    }


    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=places&v=weekly`,
            () => handleScriptLoad(setBussinessPincode, autoCompleteRef)
        );
    }, []);



    useEffect(() => {

        if (businessPinCode && businessPinCode.address_components) {
            for (const component of businessPinCode.address_components) {
                const componentType = component.types[0];
                if (componentType === "locality") {

                    setBussinessCityName(component?.long_name)

                }
                if (componentType === "administrative_area_level_1") {


                    setBussinessState(component.long_name)
                    setBussinessStateShortCode(component.short_name)
                }
                if (componentType === "country") {
                    if (component.long_name === "Australia") {
                        setBussinessCountryName(component.long_name)
                    }
                }
                if (componentType === "postal_code") {
                    setBussinessPincode(component?.long_name)

                }
            }


        }



    }, [businessPinCode]);



    // const { ref } = usePlacesWidget({
    //     apiKey: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    //     onPlaceSelected: (place) => {



            // for (const component of place.address_components) {
            //     const componentType = component.types[0];
            //     if (componentType === "locality") {

            //         setBussinessCityName(component?.long_name)

            //     }
            //     if (componentType === "administrative_area_level_1") {


            //         setBussinessState(component.long_name)
            //         setBussinessStateShortCode(component.short_name)
            //     }
            //     if (componentType === "country") {
            //         if (component.long_name === "Australia") {
            //             setBussinessCountryName(component.long_name)
            //         }
            //     }
            //     if (componentType === "postal_code") {
            //         setBussinessPincode(component?.long_name)

            //     }
            // }




    //     },
    //     options: {
    //         types: ["postal_code"],
    //         componentRestrictions: { country: "Aus" },
    //     },
    // });
    return (
        <div>
            <Col lg="12">
                <div className="form-group">
                    <Form.Label htmlFor={businessPinCode}>
                        Business Post Code
                    </Form.Label>
                    <div className="">
                    </div>
                    <Form.Control
                        type="text"
                        maxLength={4}
                        ref={autoCompleteRef}
                        onChange={(e) => setBussinessPincode(e?.target?.value)}
                        placeholder="Enter Post code"
                        value={businessPinCode}
                    />

                    <Form.Text className="text-muted">
                        {businesserrror && businessPinCode?.length <= 0 ? <span className="text-danger">Business Pincode is Required !</span> : null}
                    </Form.Text>

                </div>
            </Col>
        </div>
    )
}

export default Googleautocomplete