import * as moment from "moment";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import arrowleft from "../../../../assets/loginLogos/leftarrow.png";
import arrowright from "../../../../assets/loginLogos/rightarrow.png";
import paypal_logo from "../../../../assets/paypal.webp";
import Stripe from "../../../../assets/Stripe_Logo.png";
import order_services from "../../../../services/order_services/order_services";
import "./styles/Allorders.scss";
function Allorderdetails(props) {
  const view = (
    <svg
      width="28"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {" "}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
        fill="currentColor"
      ></path>{" "}
    </svg>
  );

  const history = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stateId, setStateId] = useState("empty");
  const [startDate, setStartDate] = useState("");
  const [limit, seLimit] = useState([]);

  const [endDate, setEndDate] = useState("")
    ;

  const tableheaders = [
    {
      header: "Order Id",
    },
    {
      header: "Customer",
    },
    {
      header: "WomenPreneur",
    },

    {
      header: "Order",
    },
    {
      header: "Payment Method",
    },
    {
      header: "Order Amount",
    },
    {
      header: "Order Status",
    },
  ];

  const getOrders = (params) => {

    if (params) {
      let startDate = moment(params.start).format("YYYY-MM-DD");
      let endDate = moment(params.end).format("YYYY-MM-DD");
      order_services
        .getOrderList({ startDate: startDate, endDate: endDate }, props.stateId)
        .then((result) => {
          setOrders(result);
          setLoading(false);
          seLimit(result)



        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    } else {
      order_services
        .getOrderList(null, props.stateId)
        .then((result) => {
          setOrders(result);
          setLoading(false);
          seLimit(result)

        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  };
  useEffect(() => {
    if (loading) {
      if (stateId !== props.stateId) {
        getOrders(null);
      }
    }
  }, [loading, props.stateId]);

  // const options = [
  //   { value: "0", label: "All" },
  //   { value: "1", label: "Approved" },
  //   { value: "2", label: "Pending" },
  //   { value: "3", label: "Pending Approval" },
  //   { value: "4", label: "Rejected" },
  // ];

  const options = [
    { label: "All Orders", value: '0' },
    { label: "New Order", value: "1" },
    { label: "Confirmed", value: "2" },
    { label: "In Progress", value: '3' },
    { label: "Cancelled by Buyer", value: '4' },
    { label: "Cancelled by Seller", value: '5' },
    { label: "Delivered", value: '6' },
    { label: "Packing Completed", value: '7' },
    { label: " Shipping Completed", value: '8' },
    { label: "Out for Delivery", value: '9' },

  ];

  const [tables, setTables] = useState([]);
  const [servicelistfilter, setServicelistfilter] = useState(0);

  const Handlefilterservicelist = (e) => {
    setServicelistfilter(e.value);
    order_services.Stausfilterorder(e.value).then((result) => {
      setOrders(result);
      setLoading(false);
    })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  const [current, setCurrent] = useState(1);



  const fetchCurrentData = async (current) => {
    const resdata = await order_services.paginationOrders(
      current
    );
    setOrders(resdata);
  };
  const handleChangePagecount = async (e) => {
    setCurrent(e);
    const current = e;
    await fetchCurrentData(current);
  };
  const edit = (
    <svg
      width="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.9927 18.9534H14.2984C13.7429 18.9534 13.291 19.4124 13.291 19.9767C13.291 20.5422 13.7429 21.0001 14.2984 21.0001H19.9927C20.5483 21.0001 21.0001 20.5422 21.0001 19.9767C21.0001 19.4124 20.5483 18.9534 19.9927 18.9534Z"
        fill="currentColor"
      ></path>
      <path
        d="M10.309 6.90385L15.7049 11.2639C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8344 7.72908 20.8452L4.23696 20.8882C4.05071 20.8903 3.88775 20.7613 3.84542 20.5764L3.05175 17.1258C2.91419 16.4915 3.05175 15.8358 3.45388 15.3306L9.88256 6.95545C9.98627 6.82108 10.1778 6.79743 10.309 6.90385Z"
        fill="currentColor"
      ></path>
      <path
        opacity="0.4"
        d="M18.1208 8.66544L17.0806 9.96401C16.9758 10.0962 16.7874 10.1177 16.6573 10.0124C15.3927 8.98901 12.1545 6.36285 11.2561 5.63509C11.1249 5.52759 11.1069 5.33625 11.2127 5.20295L12.2159 3.95706C13.126 2.78534 14.7133 2.67784 15.9938 3.69906L17.4647 4.87078C18.0679 5.34377 18.47 5.96726 18.6076 6.62299C18.7663 7.3443 18.597 8.0527 18.1208 8.66544Z"
        fill="currentColor"
      ></path>
    </svg>
  );

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return (
        <button>
          <img src={arrowright} alt="no image" className="arrows" />
        </button>
      );
    }
    if (type === "next") {
      return (
        <button>
          <img src={arrowleft} alt="no image" className="arrows" />
        </button>
      );
    }
    return originalElement;
  };


  const [searchOrders, setSearchOrderlist] = useState("");

  const SearchFieldData = () => {
    order_services.SearchOrdersList(searchOrders).then((result) => {
      setOrders(result);
      setLoading(false);
    })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });


  }

  return (
    <div>
      <div className="mt-4 mb-5">
        <Row>
          <div className="orders d-flex justify-content-between ">
            <Col lg={6}>
              <div className="py-0 col-lg-10">
                <div className="form-group input-group mb-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for order ID... "
                    onChange={(e) => setSearchOrderlist(e?.target?.value)}
                    value={searchOrders}
                  />
                  <span
                    className="input-group-text"
                    style={{ cursor: "pointer" }}
                    onClick={SearchFieldData}
                  >
                    <svg
                      className="icon-20"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>

                </div>
              </div>
            </Col>
            <Col lg={6}>

              <Row className="date-pick">
                <Col lg={8}>
                  <div className="date-picker">
                    <div>
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select date start"
                      />
                    </div>
                    <span className="to">To</span>
                    <div>
                      <DatePicker
                        className="form-control"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="Select date end"
                      />
                    </div>
                    <div className="formsbuttons">
                      <Button
                        className="nexts"
                        onClick={() => {
                          getOrders({ start: startDate, end: endDate });
                        }}
                      >
                        Go
                      </Button>
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  <Select
                    placeholder="Order Filter Status"
                    onChange={Handlefilterservicelist}
                    options={options}
                  ></Select>
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
      </div>
      <Table hover responsive className="p-0 m-0">
        <thead>
          <tr>
            {tableheaders.map((item) => {
              return <th>{item.header}</th>;
            })}
          </tr>
        </thead>
        <tbody>
  
          {!loading &&
            orders?.results.map((item) => {
              return (
                <>
                  <tr
                    className="cursor-texts"
                    onClick={() =>
                      history(`/womeyn/view-order/${item.orderId}`)
                    }
                  >
                    <td>{item.orderId} </td>
                    <td>{item.customerName?item?.customerName:"-"}</td>
                    <td>{item.sellerBusinessName}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        {item.itemsOrdered[0].productThumbImage ? (
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${item.itemsOrdered[0].productThumbImage}`}
                            alt="no image"
                            className="image-circle"
                          />
                        ) : (
                          <img
                            src={
                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSo13dMxSP1rF3oMPw_0yGj892NadEqEnQ_Uw&usqp=CAU"
                            }
                            alt="no image"
                            className="image-circle"
                          />
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        {item?.paymentMethod === "Stripe" ? (
                          <img
                            src={Stripe}
                            alt="no image"
                            width={70}
                            // height={30}
                            style={{ borderRadius: "20%" }}
                          />
                        ) : item?.paymentMethod === "PayPal" ? (
                          <img
                            src={paypal_logo}
                            alt="no image"
                            width={70}
                            // height={30}
                            style={{ borderRadius: "20%" }}
                          />
                        ) : null}
                      </div>
                    </td>
                    <td>
                      {item.totalOrderAmount
                        ? "A$" + item.totalOrderAmount
                        : "---"}
                    </td>
                    <td>
                      {item.stateId === 1 && (
                        <span className="neworders">New Order</span>
                      )}
                      {item.stateId === 2 && (
                        <span className="confirm">Confirmed</span>
                      )}
                      {item.stateId === 3 && (
                        <span className="progresss">In Progress</span>
                      )}
                      {item.stateId === 4 && (
                        <span className="cancel">Cancelled by Buyer</span>
                      )}
                      {item.stateId === 5 && (
                        <span className="cancel" > Cancelled by WomeynPreneur</span>
                      )}
                      {item.stateId === 6 && (
                        <span className="delivery">Delivered</span>
                      )}
                      {item.stateId === 7 && <span className="progresss">Packing Completed</span>}
                      {item.stateId === 8 && <span className="progresss">Shipping Completed</span>}
                      {item.stateId === 9 && <span className="delivery">Out for Delivery</span>}
                    </td>
                    <td>{item.payment}</td>
                  </tr>
                </>
              );
            })}
        </tbody>
      </Table>

      <div className="text-center mt-5 fw-bold fs-3 mx-auto w-100 d-flex align-content-center justify-content-center">
   {!loading && orders?.results.length === 0 ? "No Data Found" : ""}
   </div>
      <div className="mt-3">
        <Pagination
          className="pagination-data"
          total={limit.totalPages * 10}
          onChange={handleChangePagecount}
          current={current}
          itemRender={PrevNextArrow}
          breakLabel="..."
        />
      </div>
    </div>
  );
}

export default Allorderdetails;
