import { HttpInterceptor } from "../auth/http-interceptors";


class chatServices {
    sendChatMessage = (data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post('/seller/create-communication/', data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })

        })
    }

    getChatMessages = (sender_id) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.get(`/seller/get-communications/${sender_id}`).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })

        })
    }
    uploadChatImages = (message_id, data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post(`/seller/add-chat-attachements/${message_id}`, data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })

        })
    }
}

export default new chatServices();


