
import React, { useState, Fragment, useEffect } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Button, Row, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "./styles/Servicecreation.scss";
import Card from "../../../../components/bootstrap/card";
import { useNavigate, useParams } from "react-router-dom";
import product_services from "../../../../services/product_services/product_services";
import SelectBox from "../../../components/select-box";
import * as yup from "yup";
import { useFormik } from "formik";
import Modalvariation from "./modalvariations/Modalvariation";
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';

import camera from "../../../../assets/loginLogos/bxs_camera.svg";
import {
  CreateService,
  CreateServiceUploadimages,
  CreateServiceVariations,
  Getservicevariationlist,
} from "../../../../services/service_creation_service/service_creation_service";
import { toast } from "react-toastify";
import Select from "react-select";
import { ServiceDeleteImage, ServiceDeleteVariations, ServiceDetailsEdit, ServiceDetailsEditVariations, UploadBannerImage } from "../../../../services/services/services";
import { WorkingDays, variationsTimesam } from "./commondropdowndata/CommonDataDropdown";

function Servicecreation() {
  const [loading, setLoading] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState([
    {
      id:1,
      name:""
    },
    {
      id:2,
      name:""

    },
    {
      id:3,
      name:""

    },
    {
      id:4,
      name:""

    },
    {
      id:5,
      name:""

    }
  ]);
  const [uploadingFiles1, setUploadingFiles1] = useState([]);
  const { id } = useParams();
  const [formDatas, setFormDatas] = useState(null);
  const [firstdayas, setFirstDays] = useState("");
  const [secondtimes, setSecondtimes] = useState("");
  const [thirdtimes, setThirdtimes] = useState("");
  const [timerror, setTimeerror] = useState(false);
  const [editcategoryid, setEditCategoryId] = useState("");
  const [datalengthvariations, setDatalengthvariations] = useState([]);
  const [datalengthvariationsdummy, setDatalengthvariationsdummy] = useState(
    []
  );
  const [serviceName1, setServiceName1] = useState("");
  const [serviceName2, setServiceName2] = useState("");


  const [imageurlsbanner, setImagebanner] = useState("");
  const [addcount, setAddCount] = useState(0);

  const [serviceids, setServiceIds] = useState("")

  const [monthDatas, setMonthDatas] = useState([]);


  const handleAddCount = () => {
    if (frequencyType?.length === 0) {
      toast.error("Please Select Days or Weeks")
    }
    else {
      setAddCount(addcount + 1);

    }
  };
  const handleminCount = () => {
    if (addcount === 0) {
      setAddCount(0);
    } else {
      setAddCount(addcount - 1);
      selectMonths.pop();
    }
  };
  const [selectedImage, setSelectedImage] = useState("");
  const [selectbannerimage, setSelectbannerimage] = useState("");
  const [selectbannerimage1, setSelectbannerimage1] = useState("");
  const [selectMonths, setCheckBoxsMonth] = useState([]);
  const [selectMonths1, setCheckBoxsMonth1] = useState([]);
  const history = useNavigate();
  const [step, setIndex] = useState(1);
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);


  const [serviceimageid, setServiceImageId] = useState("");
  const [servicevariationId, setServiceVariationId] = useState("");



  const handleShow1 = (imageids) => {
    setShow1(true)
    setServiceImageId(imageids)
  }

  const handleClose1 = () => {
    setShow1(false)
  }

  const handleShow2 = (variationids) => {
    setShow2(true)
    setServiceVariationId(variationids)
  }

  const handleClose2 = () => {
    setShow2(false)
  }

  const [variationloading, setVariationLoading] = useState(false);
  const [DoneLoading, setDoneLoading] = useState(false);

  const [servicecreation, setServiceCreation] = useState({
    serviceName: "",
    serviceDescription: "",
    categoryId: "",
  });




  const [serviceerror, setServiceError] = useState(false);
  const [serviceerrorvariation, setServiceErrorvariation] = useState(false);
  const [serviceerrorpayment, setServiceErrorpayment] = useState(false);
  const [serviceerrorbannerimage, setServiceErrorbannerimage] = useState(false);
  const [servicevariation, setServicevariations] = useState({
    title: "",
    frequencyType: "",
    durationDetails: "",
    price: "",
    isCancellationAvailable: "",
    endDate: "",
    startDate: "",
    isOpenRegistration: true,
    serviceTypeId: "",
    address: "",
    linkDescription: "",
    numberOfPeopleAllowed: "",
    workingDays: []
  });


  const [servicepayment, setServicepayment] = useState({
    paymentTypeId: "",
  });
  const {
    title,
    frequencyType,
    durationDetails,
    price,
    isCancellationAvailable,
    endDate,
    startDate,
    isOpenRegistration,
    serviceTypeId,
    address,
    linkDescription,
    numberOfPeopleAllowed,
    workingDays
  } = servicevariation;
  const { serviceName, serviceDescription } = servicecreation;
  const { paymentTypeId } = servicepayment;
  const handleChanges = (e) => {
    setServiceCreation({ ...servicecreation, [e.target.name]: e.target.value });
  };
  const [lists, setLists] = useState("");
  const [lists1, setLists1] = useState("");
  const [categorysids, setCategoryids] = useState("");
  const handleChanges1 = (e) => {
    setLists(e?.target.value);
  };
  const handleChangesvariations = (e) => {
    setServicevariations({
      ...servicevariation,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangespayment = (e) => {
    setServicepayment({ ...servicepayment, [e.target.name]: e.target.value });
  };
  const Submitservicecreation = (e) => {
    e.preventDefault();
    if (
      serviceName.length === 0 ||
      serviceDescription.length === 0 ||
      categorysids.length === 0
    ) {
      setServiceError(true);
    }

    if (id) {
      return add();

    }

    else {
      if (serviceName && serviceDescription && lists) {
        const userid = localStorage.getItem("userid");
        const datas = {
          serviceName: serviceName,
          serviceDescription: serviceDescription,
          categoryId: lists,
          sellerId: JSON.parse(userid) || id,
        };
        CreateService(datas)
          .then((res) => {
            setServiceIds(res?.data?.id)
            Getservicevariationlist(res?.data?.id || id)
              .then((res) => {
                setDatalengthvariations(res?.data);
                add();
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }

    }
  };
  useEffect(() => {
    if (id) {
      ServiceDetailsEdit(id).then((res) => {
        setSelectbannerimage1(res?.serviceThumbImage)
        setUploadingFiles1(res?.serviceImages);
        if (res?.categoryId) {
          setLists(res?.categoryId)
        }
        // setLists1(res?.categoryId);
        setServiceCreation({
          serviceName: res?.serviceName,
          serviceDescription: res?.serviceDescription,
          categoryId: res?.categoryId,
        })
      }).catch((err) => {
        console.log(err);
      })
    }

    if (id) {
      ServiceDetailsEditVariations(id).then((res) => {
        setDatalengthvariationsdummy(res);
      }).catch((err) => {
        console.log(err);
      })
    }

    setServiceName1(serviceName);
  }, [serviceids, isCancellationAvailable, selectMonths, serviceimageid, id])



  const Submitservicecreationvariation = (e) => {
    e.preventDefault();
    if (
      frequencyType.length === 0 ||
      durationDetails.length === 0 ||
      price.length === 0 ||
      isCancellationAvailable.length === 0 ||
      endDate.length === 0 ||
      startDate.length === 0 ||
      isOpenRegistration.length === 0 ||
      serviceTypeId.length === 0 ||
      address.length === 0 ||
      linkDescription.length === 0 ||
      numberOfPeopleAllowed.length === 0 ||
      workingDays.length === 0
    ) {
      setServiceErrorvariation(true);
    }

    // if (
    //   title &&
    //   frequencyType &&
    //   durationDetails &&
    //   price &&
    //   // isCancellationAvailable &&
    //   endDate &&
    //   startDate &&
    //   isOpenRegistration &&
    //   serviceTypeId &&
    //   // address &&
    //   // linkDescription &&
    //   numberOfPeopleAllowed &&
    //   workingDays &&
    //   (serviceTypeId == 2 ? address : linkDescription) &&
    //   (serviceTypeId == 1 ? linkDescription : address)
    // ) {


    const formatPrice = (price) => {
      // Convert price to a number first
      const numericPrice = parseFloat(price);
      
      // Check if the price is a valid number
      if (isNaN(numericPrice)) return "0.00";
  
      // Format the price to two decimal places
      return numericPrice.toFixed(2);
    };
  
    const FinalPrice = formatPrice(price);

    if (editcategoryid) {

  //     const splitPrice=price.toString();

  //     const changeValue=splitPrice.split(".");
  // const FinalPrice=price?.includes(".")?changeValue[1]?.length>1?price.toFixed(2):`${price}0`:`${price}.00`;

      if (

        frequencyType &&
        durationDetails &&
        price &&
        // isCancellationAvailable &&
        endDate &&
        startDate &&
        isOpenRegistration &&
        serviceTypeId &&
        // address &&
        // linkDescription &&
        numberOfPeopleAllowed &&
        workingDays &&
        (serviceTypeId == 2 ? address : linkDescription) &&
        (serviceTypeId == 1 ? linkDescription : address)
      ) {

        const datas = {
          title: serviceName1 ? serviceName1 : serviceName,
          frequency: addcount,
          frequencyType: frequencyType,
          durationDetails: durationDetails,
          price: FinalPrice,
          isCancellationAvailable: isCancellationAvailable,
          endDate: endDate,
          startDate: startDate,
          isOpenRegistration: isOpenRegistration,
          serviceTypeId: serviceTypeId,
          address: address,
          linkDescription: linkDescription,
          numberOfPeopleAllowed: numberOfPeopleAllowed,
          workingDays: selectMonths,
          serviceId: serviceids || id,
          variationId: editcategoryid
        }

      
        setVariationLoading(true);
        CreateServiceVariations(datas)
          .then((res) => {
            setDatalengthvariationsdummy(res?.data);
            setServiceName1(res?.data[0]?.title);
            setServiceName2(res?.data[0]?.title)
            setVariationLoading(false);
            setTimeout(() => {
              handleClose();
              setServicevariations({
                frequencyType: "",
                durationDetails: "",
                price: "",
                isCancellationAvailable: "",
                endDate: "",
                startDate: "",
                isOpenRegistration: "",
                serviceTypeId: "",
                address: "",
                linkDescription: "",
                numberOfPeopleAllowed: "",
                workingDays: [],
                setAddCount: "",
              });
              setServiceName1(res?.title)
            }, 300);
          })
          .catch((err) => console.log(err));
      }
    }
    else {


      const splitPrice=price.toString();

      const changeValue=splitPrice.split(".");

  const FinalPrice=price?.includes(".")?changeValue[1]?.length>1?price.toFixed(2):`${price}0`:`${price}.00`;

      if (
        frequencyType &&
        durationDetails &&
        price &&
        isCancellationAvailable &&
        endDate &&
        startDate &&
        isOpenRegistration &&
        serviceTypeId &&
        // address &&
        // linkDescription &&
        numberOfPeopleAllowed &&
        workingDays &&
        (serviceTypeId == 2 ? address : linkDescription) &&
        (serviceTypeId == 1 ? linkDescription : address)) {
        const datas = {
          title: serviceName1 ? serviceName1 : serviceName,
          frequency: addcount,
          frequencyType: frequencyType,
          durationDetails: durationDetails,
          price: FinalPrice,
          isCancellationAvailable: isCancellationAvailable,
          endDate: endDate,
          startDate: startDate,
          isOpenRegistration: isOpenRegistration,
          serviceTypeId: serviceTypeId,
          address: address,
          linkDescription: linkDescription,
          numberOfPeopleAllowed: numberOfPeopleAllowed,
          workingDays: selectMonths,
          serviceId: serviceids || id,
        }

        setVariationLoading(true);
        CreateServiceVariations(datas)
          .then((res) => {
            setDatalengthvariationsdummy(res?.data);
            setVariationLoading(false);
            setTimeout(() => {
              handleClose();
              setServicevariations({
                frequencyType: "",
                durationDetails: "",
                price: "",
                isCancellationAvailable: "",
                endDate: "",
                startDate: "",
                isOpenRegistration: "",
                serviceTypeId: "",
                address: "",
                linkDescription: "",
                numberOfPeopleAllowed: "",
                workingDays: [],
                setAddCount: ""
              });
            }, 300);

          })
          .catch((err) => console.log(err));
      }
    }


    // }
  };

  const Submitservicecreationpayment = (e) => {
    e.preventDefault();
    if (paymentTypeId.length === 0) {
      setServiceErrorpayment(true);
    }

    if (paymentTypeId) {
      add();
    }
  };

  const handlechangeBannerimage = (e) => {
    setSelectbannerimage(e.target.files[0]);
  };

  const handleUploadBannerImage = (productId) => {
    // return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("upl", selectbannerimage);
    UploadBannerImage(formData, productId)
      .then((result) => {
        // resolve(true);
      

      })
      .catch((err) => {
        // if (err) reject(err);
        console.log(err);
      });
  };




  const Submitservicecreationbannerimage = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("serviceId", serviceids || id);
    formData.append("paymentTypeId", servicepayment?.paymentTypeId);
    for (let i = 0; i <Object.keys(uploadingFiles).length; i++) {
      // formData.append("upl",uploadingFiles[i]?.name);
      formData.append("upl",uploadingFiles[i]?.name);

    }
    setDoneLoading(true)
    CreateServiceUploadimages(formData)
      .then((res) => {
        if (res) {
          // setTimeout(() => {
          //   history("/womeyn/services-list");
          // }, 1200);
           handleUploadBannerImage(serviceids || id);
          setDoneLoading(false)
          toast.success(id ? "Updated Service Successful!!" : "Create Service Successful!!");
          setTimeout(() => {
            history("/womeyn/services-list");
          }, 1200);
        }
        else {
            toast.error("Request Entity Too Large");
          
  
        }

      })
      .catch((err) => {
        setDoneLoading(false);
        toast.error("Request Entity Too Large");
        if (err.response && err.response.status === 413) {
          toast.error("Request Entity Too Large");
        } else {
          toast.error("An error occurred. Please try again.");
        }

      })
  };
  const Submitservicecreationbannerimage1 = () => {
    const userid = localStorage.getItem("userid");
    const formDatas = new FormData();
    formDatas.append("serviceName", serviceName2 ? serviceName2 : serviceName);
    formDatas.append("serviceDescription", serviceDescription);
    formDatas.append("categoryId", lists);
    formDatas.append("sellerId", JSON.parse(userid));
    formDatas.append("serviceId", id || serviceids);

    if (uploadingFiles) {
      for (let i = 0; i < Object.keys(uploadingFiles).length; i++) {
        // formDatas.append("upl", uploadingFiles[Object.keys(uploadingFiles)[i]]);
              formDatas.append("upl",uploadingFiles[i]?.name);
      }
      
    }


    if(uploadingFiles[0]?.name?.name?.length>0 || uploadingFiles[1]?.name?.name?.length>0 || 

      uploadingFiles[2]?.name?.name?.length>0 || uploadingFiles[3]?.name?.name?.length>0 ||

      uploadingFiles[4]?.name?.name?.length>0
    )
    {
      const overAllImage=uploadingFiles1[0];
      const datas = {
        parentId: id, itemId: overAllImage?._id, parentType: 3
      };
      const filters = datalengthvariationsdummy?.filter((item, index) => {
        return item?.id !== servicevariationId
      })
      ServiceDeleteVariations(datas)
      .then((res) => {
      })
      .catch((err) => console.log(err));
    }
    setDoneLoading(true)

    CreateServiceUploadimages(formDatas)
      .then((res) => {
        if (res) {
          setDoneLoading(false)
          // setTimeout(() => {
          //   history("/womeyn/services-list");
          // }, 1200);
    handleUploadBannerImage(serviceids || id)

          toast.success(id ? "Updated Service Successful!!" : "Create Service Successful!!");
          setTimeout(() => {
            history("/womeyn/services-list");
          }, 1200);
        }
        else {
          toast.error("Request Entity Too Large");
        

      }
      })
      .catch((err) => {
        setDoneLoading(false);
        toast.error("Request Entity Too Large");
        if (err.response && err.response.status === 413) {
          toast.error("Request Entity Too Large");
        } else {
          toast.error("An error occurred. Please try again.");
        }
      })
  };

  useEffect(() => {
    GetCategories();
  }, [selectMonths]);

  const GetCategories = () => {
    product_services
      .GetCategoriesService()
      .then((res) => {
        // const values = [];
        // res?.map((item, index) => {
        //   const data = {
        //     value: item?.id,
        //     label: item?.path,
        //   };
        //   values.push(data);
        // });
        // setCategories(values);
        setCategories(res);

      })
      .catch((err) => console.log(err));
  };


  const prepCategories = categories.map((item) => ({
    value: item.id,
    label: item.path,
  }));

  const add = () => {
    setIndex(step + 1);
  };
  const minus = () => {
    setIndex(step - 1);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true)
  };

  const handleMonthData = (e) => {
    if (firstdayas?.length === 0 || secondtimes?.length === 0 || thirdtimes?.length === 0) {
      setTimeerror(true);
    }
    if (monthDatas?.workingDays?.length>0) {
      if (firstdayas && secondtimes && thirdtimes) {
        const forms = {
          dayName: firstdayas,
          workingHours: `${secondtimes} to ${thirdtimes}`
        }
        setCheckBoxsMonth([...selectMonths, forms]);
        setSecondtimes("");
        setThirdtimes("");
        setTimeerror(false);
        setFirstDays("")


      }

    }
    else {

      if (firstdayas && secondtimes && thirdtimes) {
        const forms = {
          dayName: firstdayas,
          workingHours: `${secondtimes} to ${thirdtimes}`
        }
        setCheckBoxsMonth([...selectMonths, forms]);
        setSecondtimes("");
        setThirdtimes("");
        setTimeerror(false);
        setFirstDays("")

      }
    }


  };


  const handledeletevariations = (id) => {
    const valuegets = datalengthvariationsdummy?.filter((item) => {
      return item?.id !== id
    })
    setDatalengthvariationsdummy(valuegets)
  }

  const handledeleteMonthTimes = (id) => {
    const valuegets = selectMonths?.filter((item, index) => {
      return index + 1 !== id
    })
    setCheckBoxsMonth(valuegets)
setAddCount((pre)=>pre-1);
  }


  const remove = (
    <svg
      width="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
        fill="currentColor"
      ></path>
      <path
        d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
        fill="currentColor"
      ></path>
    </svg>
  );

  const EditServiceData = (id) => {
    datalengthvariationsdummy?.filter((item, index) => {
      if (index + 1 === id) {
        setEditCategoryId(item?.id)
        setServicevariations(item);
        setCheckBoxsMonth(item?.workingDays)
        setMonthDatas(item?.workingDays)
        setCheckBoxsMonth1(item?.workingDays)
        setAddCount(item?.frequency)
      }
    })
    handleShow();
  }

  useEffect(() => {
  }, [addcount, frequencyType]);

  const SelectVariations=()=>{
    toast.error("Please enter the slots or working days")
  }

  const handlecheckslotlength=()=>{
    toast.error("Please enter the slots or working days")

  }
  const variationcontents = () => {
    return (
      <>
        <div className="variation-section-inside">
          <div className="mb-4">

            <Form.Label htmlFor="styleName">Add title </Form.Label>
            <Form.Control
              type="text"
              name="title"
              onChange={handleChangesvariations}
              value={serviceName1 ? serviceName1 : serviceName}
              placeholder="title"
              className="serviceform"
              disabled
            />
            {/* {serviceerrorvariation && title?.length <= 0 ? (
              <span className="error-message">serviceName is Required!!</span>
            ) : (
              ""
            )} */}
          </div>
          <div>
            <h6>Select frequency for this experience</h6>
          </div>
          <div className="per-day-year-section mb-4 mt-3">
           
            <div className="form-sections">
              <Col>
                <Form.Select className="serviceform" value={frequencyType} name="frequencyType" onChange={handleChangesvariations}>
                  <option value="" disabled>--Days--</option>
                  <option label="Per Day" value="Per Day">
                    Per Day
                  </option>
                  <option label="Per Week" value="Per Week">
                    Per Week
                  </option>
                </Form.Select>
                {serviceerrorvariation && frequencyType?.length <= 0 ? (
                  <span className="error-message">frequency Type is Required</span>
                ) : (
                  ""
                )}
              </Col>
            </div>

            <div className="count-sections">
              <div onClick={handleminCount} className="leftsectionplus">
                -
              </div>
              {/* <div>{addcount}</div> */}
              <div className="middlesectionplus">
                <input type="text" value={addcount} disabled className="countsection" />
              </div>
              <div onClick={handleAddCount} className="rightsectionplus">
                +
              </div>
            </div>
          </div>
          <div>
            <h6>Slots or Working Days </h6>
            <div className="row mt-3">
              {/* const WorkingDays = [ */}
              <Col lg={4}>
                <Form.Select
                  name="hours1" className="serviceform mt-2" onChange={(e) => {
                    setFirstDays(e?.target.value)
                  }

                  } value={firstdayas}>
                  <option value="">--Days--</option>
                  {WorkingDays?.map((item, index) => {
                    return (
                      <>
                        <option label={item?.name} value={item?.name} name={item?.name}>
                          {item?.name}
                        </option>
                      </>
                    )
                  })}
                </Form.Select>
                <div className="">
                  {timerror && firstdayas?.length <= 0 ? <span className="text-danger">Working Days is Required</span> : null}
                </div>
              </Col>

              <Col lg={3}>
                <Form.Select name="hours1" className="serviceform mt-2" onChange={(e) => setSecondtimes(e?.target.value)} value={secondtimes}>
                  <option value="">--Slot Time--</option>
                  {variationsTimesam?.map((item, index) => {
                    return (
                      <>
                        <option label={item?.time} value={item?.time} name={item?.time}>
                          {item?.time}
                        </option>
                      </>
                    )
                  })}
                </Form.Select>
                <div className="">
                  {timerror && secondtimes?.length <= 0 ? <span className="text-danger">Slot Time is Required</span> : null}
                </div>
              </Col>
              <Col lg={3}>
                <Form.Select name="hours1" className="serviceform mt-2" onChange={(e) => setThirdtimes(e?.target.value)} value={thirdtimes}>
                  <option value="">--Slot Time--</option>
                  {variationsTimesam?.map((item, index) => {
                    return (
                      <>
                        <option label={item?.time} value={item?.time} name={item?.time}>
                          {item?.time}
                        </option>
                      </>
                    )
                  })}
                </Form.Select>
                <div className="">
                  {timerror && thirdtimes?.length <= 0 ? <span className="text-danger">Slot Time is Required</span> : null}
                </div>
              </Col>
              <Col lg={2}>
                <div className="buttonslots">

                  <>

                    {addcount === selectMonths?.length ? <>
                    </> : <>
                      <button onClick={handleMonthData} className={"slotboxtimes"}>+ Add Slot </button>
                    </>}
                  </>





                </div>
              </Col>

              <div className="mainslots row mt-5 mb-3">
                {selectMonths?.length > 0 ? <>
                  {selectMonths?.map((item, index) => {
                    return (
                      <div className="slottimesbox mt-2">
                        <div className={"insidesoltstimeboxs"}>

                          <div className="mb-2 mt-1">
                            {item?.dayName}
                          </div>
                          <div className="mb-2 mt-1">
                            {item?.workingHours}
                          </div>

                          <div className="insidedeletetimes">
                            <Button
                              className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                              to="#"
                              role="button"
                              onClick={() => handledeleteMonthTimes(index + 1)}
                            >
                              <span className="btn-inner">{remove}</span>
                            </Button>

                          </div>
                        </div>
                      </div>
                    )
                  })}
                </> : <>
                  {/* {monthDatas?.workingDays ? <>

                    {monthDatas?.workingDays?.map((item, index) => {
                      return (
                        <div className="slottimesbox mt-2">
                          <div className={"insidesoltstimeboxs"}>

                            <div className="mb-2 mt-1">
                              {item?.dayName}
                            </div>
                            <div className="mb-2 mt-1">
                              {item?.workingHours}
                            </div>

                            <div className="insidedeletetimes">
                              <Button
                                className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                to="#"
                                role="button"
                                onClick={() => handledeleteMonthTimes(index + 1)}
                              >
                                <span className="btn-inner">{remove}</span>
                              </Button>

                            </div>
                          </div>
                        </div>
                      )
                    })}


                  </> : <></>} */}


                </>}


              </div>



            </div>
          </div >


          <div className="date-section-form mb-4 mt-3">
            <Col>
              <Form.Label htmlFor="styleName">
                <h6>Duration details</h6>
              </Form.Label>
              <Form.Control
                type="text"
                name="durationDetails"
                value={durationDetails}
                // onChange={handleChangesvariations}
                onChange={(e) => {
                  var tests = /^[0-9.]*$/;
                  if (
                    e.target.value === "" ||
                    tests.test(e.target.value)
                  ) {
                    return handleChangesvariations(e);
                  } else {
                    return null;
                  }
                }}
                placeholder="1 hr per day"
                className="serviceform"
              />

              {serviceerrorvariation && durationDetails?.length <= 0 ? (
                <span className="error-message">Duration Details is Required</span>
              ) : (
                ""
              )}

            </Col>
            <Col>
              <Form.Label htmlFor="styleName">
                <h6>Price</h6>
              </Form.Label>
              <Form.Control
                type="text"
                name="price"
                className="serviceform"
                placeholder="Please Enter A$ 100.00"
                value={price}
                onChange={(e) => {
                  var tests = /^[0-9.]*$/;
                  if (
                    e.target.value === "" ||
                    tests.test(e.target.value)
                  ) {
                    return handleChangesvariations(e);
                  } else {
                    return null;
                  }
                }

                }
              />

              {serviceerrorvariation && price?.length <= 0 ? (
                <span className="error-message">Price is Required</span>
              ) : (
                ""
              )}
            </Col>
          </div>

          <div className="mb-4 d-flex">
            Cancellation available ?
            <div className="d-flex gap-3">
              <div>
                <span className="ms-3 ">
                  <input
                    type="radio"
                    value="true"
                    onChange={handleChangesvariations}
                    checked={isCancellationAvailable.toString() == "true"}
                    className="checkedcolor"
                    name="isCancellationAvailable"

                  />
                  <span className="ms-2">Yes</span>
                </span>
              </div>
              <div>
                <input
                  type="radio"
                  value="false"
                  name="isCancellationAvailable"
                  checked={isCancellationAvailable.toString() == "false"}
                  onChange={handleChangesvariations}
                  className="checkedcolor"
                />
                <span className="ms-2">No</span>
              </div>

              <div>
                {serviceerrorvariation && isCancellationAvailable?.length <= 0 ? (
                  <span className="error-message">isCancellation Available is Required</span>
                ) : (
                  ""
                )}
              </div>
            </div>


          </div>


          <div className="date-section-form mb-4">
            <Col>
              <Form.Label htmlFor="styleName">
                <h6>Start date </h6>
              </Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                onChange={handleChangesvariations}
                value={moment(startDate).format('YYYY-MM-DD')}
                style={{ cursor: "pointer" }}
                className="serviceform"
                min={moment().format("YYYY-MM-DD")}
              />
              {serviceerrorvariation && startDate?.length <= 0 ? (
                <span className="error-message">Start Date is Required</span>
              ) : (
                ""
              )}
            </Col>
            <Col>
              <Form.Label htmlFor="styleName">
                <h6>End date</h6>
              </Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                onChange={handleChangesvariations}
                value={moment(endDate).format('YYYY-MM-DD')}
                style={{ cursor: "pointer" }}
                className="serviceform"
                min={startDate}
              />
              {serviceerrorvariation && endDate.length <= 0 ? (
                <span className="error-message">endDate is Required</span>
              ) : (
                ""
              )}
            </Col>
          </div>
          <div className="mb-4 ">
            <Col>

            <div>
            <span>Open registration (No end date)</span>
            </div>
              {/* {isOpenRegistration ? <>
                <div className="d-flex gap-2">
                  <Form.Check type="checkbox" name="isOpenRegistration" value={true}
                    onChange={handleChangesvariations}
                    checked={isOpenRegistration}
                  />
                  <span>Open registration (No end date)</span>
                  {serviceerrorvariation && isOpenRegistration.length <= 0 ? (
                    <span className="error-message">is OpenRegistrations is Required</span>
                  ) : (
                    ""
                  )}
                </div>
              </> : <>
                <div className="d-flex gap-2">
                  <Form.Check type="checkbox" name="isOpenRegistration" value="true"
                    onChange={handleChangesvariations}
                  />
                  <span>Open registration (No end date)</span>
                  {serviceerrorvariation && isOpenRegistration.length <= 0 ? (
                    <span className="error-message">isOpenRegistrations is Required</span>
                  ) : ( 
                    ""
                  )}
                </div></>} */}

            </Col>
          </div>

          <div className="mb-4 d-flex">
            Is this an online/ offline service ?{" "}
            <div className="d-flex gap-3">
              <div>
                <span className="ms-3">
                  <input
                    type="radio"
                    value="1"
                    onChange={handleChangesvariations}
                    checked={serviceTypeId == "1"}
                    className="checkedcolor"
                    name="serviceTypeId"
                    id="online"
                  />
                  <span className="ms-2">online</span>
                </span>
              </div>
              <div>
                <input
                  type="radio"
                  id="offline"
                  value="2"
                  name="serviceTypeId"
                  checked={serviceTypeId == "2"}
                  onChange={handleChangesvariations}
                  className="checkedcolor"
                />
                <span className="ms-2">offline</span>
              </div>
            </div>
            {serviceerrorvariation && serviceTypeId?.length <= 0 ? (
              <span className="error-message">Service is Required</span>
            ) : (
              ""
            )}
          </div>

          <div className="mt-5 mb-4">
            {serviceTypeId == 1 && (
              <div>
                <Form.Label htmlFor="styleName">
                  Online link description
                </Form.Label>

                <Form.Control
                  as="textarea"
                  rows={2}
                  name="linkDescription"
                  value={serviceTypeId == 2 ? "" : linkDescription}
                  onChange={handleChangesvariations}
                  placeholder="We will send online link before 24 hrs"
                />
                {serviceerrorvariation && linkDescription.length <= 0 ? (
                  <span className="error-message">Link Description is Required </span>
                ) : (
                  ""
                )}
              </div>
            )}
            {serviceTypeId == 2 && (
              <div>
                <Form.Label htmlFor="styleName">Address(offline)</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  onChange={handleChangesvariations}
                  value={serviceTypeId == 1 ? "" : address}
                  placeholder="Address(offline)"
                  className="serviceform"
                />
                {serviceerrorvariation && address.length <= 0 ? (
                  <span className="error-message">Address is Required</span>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <div className="mb-0">
            <Form.Label htmlFor="styleName">
              *Number of people allowed to book this package at a time

            </Form.Label>
            <Form.Control
              type="number"
              onChange={handleChangesvariations}
              name="numberOfPeopleAllowed"
              value={numberOfPeopleAllowed}
              placeholder={`*No of people allowed in 1 session (${serviceTypeId?serviceTypeId == 1 ?"Online":"Offline":""
                })`}
              className="serviceform"
            />
            {serviceerrorvariation && numberOfPeopleAllowed.length <= 0 ? (
              <span className="error-message">
                Number Of Peoples Allowed is Required
              </span>
            ) : (
              ""
            )}
          </div>
        </div >
        <div className="w-100 d-flex align-items-center gap-4 mt-5 ms-4">
          <button className="btn backs" onClick={handleClose}>
            Close
          </button>
          <Button onClick={
          selectMonths?.length>0?addcount === selectMonths?.length?Submitservicecreationvariation:SelectVariations:handlecheckslotlength}>
            {variationloading ? <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="ms-2">Loading...</span>
            </> : <>
            
            {selectMonths?.length>0?<>
             {addcount === selectMonths?.length?<div>
              Save
            </div>:<>
            Please enter the slots or working days
            </>}
            </>:<>
      
            Please enter the slots or working days
             
            </>}
           

            </>}
          </Button>
        </div>
      </>
    );
  };


  const handleUploadingFiles = (e, imageNumber) => {
  
      if (e.target.files[0]) {

        const findIndexValue=uploadingFiles?.findIndex((item)=>item?.id===imageNumber);
  
        const overAllDatas=[...uploadingFiles];
        overAllDatas[findIndexValue].name=e.target.files[0];
       
        setUploadingFiles(overAllDatas);
  
      }
    
   

  
  };


  const [servicecounts, setServiceCounts] = useState([]);
  useEffect(() => {
    product_services.ProductAvailablecountCheck().then((res) => {


      setServiceCounts(res);
    }).catch((err) => {
      console.log(err);
    })
  }, []);

  const navigates = () => {
    history("/womeyn/subscribe/user");
  }

  const DeleteSerive = () => {
    const datas = {
      parentId: id, itemId: serviceimageid, parentType: 2
    };

    const filters = uploadingFiles1?.filter((item, index) => {
      return item?._id !== serviceimageid
    })

    setUploadingFiles1(filters);
    setLoading(true);
    ServiceDeleteImage(datas)
      .then((res) => {
        toast.success("Service Image Deleted successfully");
        handleClose1();
        setLoading(false);
        // setPagerefresh(true);
      })
      .catch((err) => console.log(err));
  };

  const DeleteSeriveVariations = () => {
    const datas = {
      parentId: id, itemId: servicevariationId, parentType: 3
    };
    const filters = datalengthvariationsdummy?.filter((item, index) => {
      return item?.id !== servicevariationId
    })
    setDatalengthvariationsdummy(filters);
    setLoading(true);
    ServiceDeleteVariations(datas)
      .then((res) => {
        toast.success("Service Variation Deleted successfully");
        handleClose2();
        setLoading(false);
        setServicevariations({
          title: "",
          frequencyType: "",
          durationDetails: "",
          price: "",
          isCancellationAvailable: "",
          endDate: "",
          startDate: "",
          isOpenRegistration: true,
          serviceTypeId: "",
          address: "",
          linkDescription: "",
          numberOfPeopleAllowed: "",
          workingDays: []
        });
        setMonthDatas([]);
        setAddCount(0);
        window.location.reload(false)
      })
      .catch((err) => console.log(err));
  }
  if (id ? null : servicecounts?.availableServicesCount === 0) {
    return (
      <div>
        {navigates()}
      </div>
    )
  }
  else {
    return (
      <Fragment>
        <div className="mt-5 mb-5 kalai-box" style={{ paddingBottom: "3%" }}>
          <Row>
            <Col>
              <div className="containers">
                <ProgressBar
                  percent={((step - 1) * 100) / 2}
                  height={3}
                  filledBackground="#e95a5a"
                >
                  <Step transition="scale">
                    {({ accomplished }) => (
                      <>
                        <div
                          className={`step ${accomplished ? "completed" : ""}`}
                        >
                          1
                        </div>
                        <div className="kalai">Service Details</div>
                      </>
                    )}
                  </Step>
                  <Step transition="scale">
                    {({ accomplished }) => (
                      <>
                        <div
                          className={`step ${accomplished ? "completed" : ""}`}
                        >
                          2
                        </div>

                        <div className="kalai">Choose Duration</div>
                      </>
                    )}
                  </Step>

                  <Step transition="scale">
                    {({ accomplished }) => (
                      <>
                        <div
                          className={`step ${accomplished ? "completed" : ""}`}
                        >
                          3
                        </div>
                        <div className="kalai">Upload Images</div>
                      </>
                    )}
                  </Step>

                </ProgressBar>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <Row>
            <div className="w-100 d-flex align-items-center justify-content-center mt-5">
              <Col lg="9" md="10" sm="10" className="mt-5 ms-2">
                <Card>
                  <Card.Body className="p-0">
                    {step === 1 && (
                      <div>
                        <div>
                          <div>
                            <h3>{id ? <>Edit Service</> : <>Add New Service</>}</h3>
                          </div>
                          <div className="mb-4 mt-4">
                            <Col lg="12">
                              <div className="form-group">

                                <Form.Label htmlFor="styleName">Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="serviceName"
                                  id="serviceName"
                                  onChange={handleChanges}
                                  value={serviceName1 ? serviceName1 : serviceName}
                                  placeholder="Enter title here"
                                />
                                <div className="mt-1">
                                  {serviceerror && serviceName.length <= 0 ? (
                                    <span className="text-danger">
                                      servicename is Required!!
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="form-group">
                                <Form.Label htmlFor="styleName">
                                  Description
                                </Form.Label>
                                <Form.Control
                                  as="textarea"
                                  rows={7}
                                  name="serviceDescription"
                                  onChange={handleChanges}
                                  value={serviceDescription}
                                  placeholder="Enter your product Description here"
                                />
                                <div className="mt-1">
                                  {serviceerror &&
                                    serviceDescription.length <= 0 ? (
                                    <span className="text-danger">
                                      servicedescription is Required!!
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                            <Col lg="12">
                              <div className="form-group">
                                <Form.Label htmlFor="styleName">
                                  Service Category
                                </Form.Label>
                                {/* <Select
                                  options={prepCategories}
                                  onChange={(value) => {
                                    handleChanges1(value);
                                  }}
                                  // value={lists?.id}
                                  defaultValue={categorysids}
                                  placeholder="Select Service Categories...."
                                  noOptionsMessage={() => "No Category Service"}
                                /> */}
                                <Form.Select onChange={(value) => {
                                  handleChanges1(value);
                                }}
                                  value={lists} >
                                  <option>Select Service Categories....</option>
                                  {prepCategories?.map((item, index) => {
                                    return (
                                      <option value={item?.value}>{item?.label}</option>
                                    )
                                  })}
                                  {/* <option value="1">One</option>
                                  <option value="2">Two</option>
                                  <option value="3">Three</option> */}
                                </Form.Select>

                                <div className="mt-1">
                                  {serviceerror && lists.length <= 0 ? (
                                    <span className="text-danger">
                                      Category is Required!!
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                          </div>
                          <div className="text-end">
                            <Button type="submit" onClick={Submitservicecreation}>
                              Next
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div>
                        <div>
                          <h3>Add New Service</h3>
                        </div>
                        <div>
                          <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                            className='kalaimodel'
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                Add New Service
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {variationcontents()}
                            </Modal.Body>

                          </Modal>
                          {/* <Modalvariation show={show} handleClose={handleClose} variationcontents={variationcontents} /> */}
                        </div>
                        <div className='mb-4 mt-4'>
                          {datalengthvariationsdummy?.length > 0 ? <></> : <>
                            <div className='add-variations' onClick={handleShow}>
                              Add Service Details
                            </div>
                          </>}
                        </div>
                        <div>
                          {datalengthvariationsdummy?.map((item, index) => {
                            return (
                              <div className='variation-box-inside-edit mb-3 mt-2' key={index}>
                                <div className='inside-box-inside-edit-section'>
                                  <div className="titles">
                                    {item?.title}
                                  </div>
                                  <div className='edit-delete-section'>
                                    <div className='delete-button-cursor' onClick={() => handleShow2(item?.id)} >
                                      Delete
                                    </div>
                                    <div className='delete-button-over'>
                                    </div>
                                    <div className='delete-button-cursor' onClick={() => EditServiceData(index + 1)} >
                                      Edit
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <span className='date-start-section'>Price : A$ </span>  {item?.price}
                                </div>

                                <div className="mt-3">
                                  <span>{item?.frequency}</span> <span>{item?.frequencyType}</span>
                                </div>
                                <div className='mt-2'>
                                  {item?.slugName}
                                </div>
                                <div className="mt-3">
                                  {item?.address} {item?.linkDescription}
                                </div>

                                <div className='mt-3 d-flex gap-3'>
                                  <span className='date-start-section'>StartDate : </span>{moment(item?.startDate).format("MMM Do YY",)}
                                  <span className='date-start-section'>EndDate :</span>{moment(item?.endDate).format("MMM Do YY",)}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <div>
                          <div className='w-100 d-flex align-items-center gap-4 mt-5'>
                            <button onClick={minus} className=' btn backs'>Back</button>

                            {datalengthvariationsdummy?.length === 0 ? <Button onClick={handleShow}>Next</Button> :
                              <Button onClick={add}>Next</Button>
                            }

                          </div>
                        </div>
                      </div>
                    )}



                    {step === 3 && (
                      <>

                        <div className="mb-4">
                          <h5>Service Thumbnail Image</h5>
                        </div>
                        <div className="text-danger mb-3">
                        Image Size Maximum Dimensions are 282 x 350 pixels.
                        </div>
                        <div className="d-flex justify-content-center">

                          {selectbannerimage ? <>

                            {selectbannerimage ? (
                              <>
                                <label htmlFor="upload-buttonbanner1">
                                  <div className="main-camera-sections">
                                    <img
                                      src={URL.createObjectURL(selectbannerimage)}
                                      alt="Avatar"
                                      class="image"
                                      width="120px"
                                      height="120px"
                                    />
                                  </div>
                                </label>
                              </>
                            ) : (
                              <label htmlFor="upload-buttonbanner1">
                                <div className="main-camera-section">
                                  <div className="inside-camera-section-upload">
                                    <img src={camera} alt="no image" />
                                  </div>
                                </div>
                              </label>
                            )}
                          </> : <>

                            {selectbannerimage1 ? <>

                              <label htmlFor="upload-buttonbanner1">
                                <div className="main-camera-sections">
                                  <img
                                    width="120px"
                                    height="120px"
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${selectbannerimage1}`}
                                    alt="profile-pic"
                                  />
                                </div>
                              </label>
                            </> : <>
                              <label htmlFor="upload-buttonbanner1">
                                <div className="main-camera-section">
                                  <div className="inside-camera-section-upload">
                                    <img src={camera} alt="no image" />
                                  </div>
                                </div>
                              </label>
                            </>}

                          </>}




                        </div>
                        <div className="mt-5 mb-5">
                          <hr />
                        </div>
                        <div className="mb-4 mt-4">
                          <h5>Service Banner Image</h5>
                        </div>
                        <div className="text-danger mb-5">
                        Image Size Maximum Dimensions are 1127 x 177 pixels.
                        </div>
                        <Row>
                          <Col className="mb-3">
                            {uploadingFiles[0].name && uploadingFiles[0].name? (
                              <>
                                <label htmlFor="upload-button1">
                                  <div className="main-camera-sections">
                                   
                                    <img
                                      src={URL.createObjectURL(
                                        uploadingFiles[0]?.name
                                      )}
                                      alt="Avatar"
                                      class="image"
                                      width="120px"
                                      height="120px"
                                    />
                                  </div>
                                </label>
                              </>
                            ) : (
                              <>
                                {uploadingFiles1[0] ? <div className="image-box-poistion1">

                                  <label htmlFor="upload-button1">
                                    <div className="main-camera-sections">
                                      <img
                                        width="120px"
                                        height="120px"
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${uploadingFiles1[0]?.name}`}
                                        alt="profile-pic"
                                        class="image"
                                      />
                                    </div>
                                  </label>
                                  <div>
                                    <div className="remove-image-box1">
                                      <Button
                                        className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                        onClick={() => handleShow1(uploadingFiles1[0]?._id)}
                                      >
                                        <span className="btn-inner">{remove}</span>
                                      </Button>
                                    </div>
                                  </div>
                                </div> : <>
                                  <label htmlFor="upload-button1">
                                    <div className="main-camera-section">
                                      <div className="inside-camera-section-upload">
                                        <img src={camera} alt="no image" />
                                      </div>
                                    </div>
                                  </label>
                                </>}

                              </>
                            )}
                          </Col>
                         <Col>
                         </Col>
                         <Col>
                         </Col>
                         <Col></Col>
                          <div className="mt-4">
                          <div className="mt-2 mb-3">
                          <hr />
                        </div>
                          <div>
                          <div className="mb-4 mt-4">
                          <h5>Service Images</h5>
                        </div>
                        <div className="text-danger mb-5">
                        Image Size Maximum Dimensions are 1127 x 177 pixels.
                        </div>
                          </div>
                            <Row>

                            <Col className="mb-3">
                            {uploadingFiles[1]?.name && uploadingFiles[1]?.name ? (
                              <>
                                <label htmlFor="upload-button2">
                                  <div className="main-camera-sections">
                                    <img
                                      src={URL.createObjectURL(
                                        uploadingFiles[1]?.name
                                      )}
                                      alt="Avatar"
                                      class="image"
                                      width="120px"
                                      height="120px"
                                    />
                                  </div>
                                </label>
                              </>
                            ) : (
                              <>
                                {uploadingFiles1[1] ? <div className="image-box-poistion1">

                                  <label htmlFor="upload-button2">
                                    <div className="main-camera-sections">
                                      <img
                                        width="120px"
                                        height="120px"
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${uploadingFiles1[1]?.name}`}
                                        alt="profile-pic"
                                        class="image"
                                      />
                                    </div>
                                  </label>
                                  <div>
                                    <div className="remove-image-box1">
                                      <Button
                                        className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                        onClick={() => handleShow1(uploadingFiles1[1] ? uploadingFiles1[1]?._id : null)}
                                      >
                                        <span className="btn-inner">{remove}</span>
                                      </Button>
                                    </div>
                                  </div>
                                </div> : <>
                                  <label htmlFor="upload-button2">
                                    <div className="main-camera-section">
                                      <div className="inside-camera-section-upload">
                                        <img src={camera} alt="no image" />
                                      </div>
                                    </div>
                                  </label>
                                </>}
                              </>
                            )}
                          </Col>

                          <Col className="mb-3">
                            {uploadingFiles[2]?.name && uploadingFiles[2]?.name ? (
                              <>
                                <label htmlFor="upload-button3">
                                  <div className="main-camera-sections">
                                    <img
                                      src={URL.createObjectURL(
                                        uploadingFiles[2]?.name
                                      )}
                                      alt="Avatar"
                                      class="image"
                                      width="120px"
                                      height="120px"
                                    />
                                  </div>
                                </label>
                              </>
                            ) : (
                              <>
                                {uploadingFiles1[2] ? <div className="image-box-poistion1">

                                  <label htmlFor="upload-button3">
                                    <div className="main-camera-sections">
                                      <img
                                        width="120px"
                                        height="120px"
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${uploadingFiles1[2]?.name}`}
                                        alt="profile-pic"
                                      />
                                    </div>
                                  </label>
                                  <div>
                                    <div className="remove-image-box1">
                                      <Button
                                        className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                        onClick={() => handleShow1(uploadingFiles1[2] ? uploadingFiles1[2]?._id : null)}
                                      >
                                        <span className="btn-inner">{remove}</span>
                                      </Button>
                                    </div>
                                  </div>
                                </div> : <>
                                  <label htmlFor="upload-button3">
                                    <div className="main-camera-section">
                                      <div className="inside-camera-section-upload">
                                        <img src={camera} alt="no image" />
                                      </div>
                                    </div>
                                  </label>
                                </>}
                              </>
                            )}
                          </Col>
                          <Col className="mb-3">
                            {uploadingFiles[3]?.name && uploadingFiles[3]?.name ? (
                              <>
                                <label htmlFor="upload-button4">
                                  <div className="main-camera-sections">
                                    <img
                                      src={URL.createObjectURL(
                                        uploadingFiles[3]?.name
                                      )}
                                      alt="Avatar"
                                      class="image"
                                      width="120px"
                                      height="120px"
                                    />
                                  </div>
                                </label>
                              </>
                            ) : (
                              <>
                                {uploadingFiles1[3] ? <div className="image-box-poistion1">

                                  <label htmlFor="upload-button4">
                                    <div className="main-camera-sections">
                                      <img
                                        width="120px"
                                        height="120px"
                                        src={`${process.env.REACT_APP_IMAGE_URL}/${uploadingFiles1[3]?.name}`}
                                        alt="profile-pic"
                                        style={{objectFit:"cover"}}
                                      />
                                    </div>
                                  </label>
                                  <div>
                                    <div className="remove-image-box1">
                                      <Button
                                        className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                        onClick={() => handleShow1(uploadingFiles1[3] ? uploadingFiles1[3]?._id : null)}
                                      >
                                        <span className="btn-inner">{remove}</span>
                                      </Button>
                                    </div>
                                  </div>
                                </div> : <>
                                  <label htmlFor="upload-button4">
                                    <div className="main-camera-section">
                                      <div className="inside-camera-section-upload">
                                        <img src={camera} alt="no image" />
                                      </div>
                                    </div>
                                  </label>
                                </>}
                              </>
                            )}
                          </Col>
                              <Col className="mb-3">
                                {uploadingFiles[4]?.name && uploadingFiles[4]?.name ? (
                                  <>
                                    <label htmlFor="upload-button5">
                                      <div className="main-camera-sections">
                                        <img
                                          src={URL.createObjectURL(
                                            uploadingFiles[4]?.name
                                          )}
                                          alt="Avatar"
                                          class="image"
                                          width="120px"
                                          height="120px"
                                        />
                                      </div>
                                    </label>
                                  </>
                                ) : (
                                  <>
                                    {uploadingFiles1[4] ? <div className="image-box-poistion1">

                                      <label htmlFor="upload-button5">
                                        <div className="main-camera-sections">
                                          <img
                                            width="120px"
                                            height="120px"
                                            src={`${process.env.REACT_APP_IMAGE_URL}/${uploadingFiles1[4]?.name}`}
                                            alt="profile-pic"
                                          />
                                        </div>
                                      </label>
                                      <div>
                                        <div className="remove-image-box1">
                                          <Button
                                            className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                            onClick={() => handleShow1(uploadingFiles1[4] ? uploadingFiles1[4]?._id : null)}
                                          >
                                            <span className="btn-inner">{remove}</span>
                                          </Button>
                                        </div>
                                      </div>
                                    </div> : <>
                                      <label htmlFor="upload-button5">
                                        <div className="main-camera-section">
                                          <div className="inside-camera-section-upload">
                                            <img src={camera} alt="no image" />
                                          </div>
                                        </div>
                                      </label>
                                    </>}
                                  </>
                                )}
                              </Col>
                            
                            </Row>
                          </div>
                        </Row>
                       
                        {/* <div className='w-100 button-gap-section mt-5'>
                                              <div>
                                                  <Button className='back-btn' onClick={minus}>Back</Button>
                                              </div>
                                              <div>
                                                  <Button className="next-btn" onClick={handleImageUpload}   >Next</Button>
                                              </div>
                                          </div> */}
                        {/* <div>
  
                                              <input type="file" accept="image/*" id="upload" onChange={(e) => { handleUploadingFiles(e) }}
                                                  name="userPhoto1" />
                                              <input type="file" accept="image/*" id="upload" onChange={(e) => { handleUploadingFiles(e) }}
                                                  name="userPhoto2" />
                                              <button type="button" onClick={handleImageUpload} >Upload</button>
  
                                          </div> */}
                        <div>
                          <input
                            id="upload-buttonbanner1"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handlechangeBannerimage(e);
                            }}
                            multiple
                          />
                        </div>
                        <div>
                          <input
                            id="upload-button1"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 1);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button2"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 2);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button3"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 3);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button4"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 4);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button5"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 5);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button6"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 6);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button7"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              handleUploadingFiles(e, 7);
                            }}
                            multiple
                          />
                          <input
                            id="upload-button8"
                            type="file"
                            name="myImage"
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={(e) => handleUploadingFiles(e, 8)}
                            multiple
                          />
                        </div>

                        <div className="w-100 d-flex align-items-center gap-4 mt-5">
                          <span className=" btn backs" onClick={minus}>
                            Back
                          </span>
                          <Button
                            type="submit"
                            onClick={id ? Submitservicecreationbannerimage1 : Submitservicecreationbannerimage}
                          >
                            {DoneLoading ? <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              <span className="ms-2">Loading...</span>
                            </> : <>Done</>}

                          </Button>
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </div>
          </Row>
        </div>

        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className='mt-4 mb-2 ms-3'>
            <Modal.Title>
              <div className='text-center'>
                {/* Product_Id:  */}
                {/* {removeid} */}
              </div>
            </Modal.Title>

            <Modal.Body>
              <div className='text-center mt-2 mb-3 fs-5'>
                Are you sure want to delete this image permanently?
              </div>
            </Modal.Body>

            <div className='d-flex gap-3 align-items-center justify-content-center mt-3 mb-4'>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
              <Button variant="primary" onClick={() => DeleteSerive()}>{loading ? <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className='ms-2'>Loading...</span>
              </> : <>Delete</>}</Button>
            </div>
          </div>
        </Modal>


        <Modal
          show={show2}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className='mt-4 mb-2 ms-3'>
            <Modal.Title>
              <div className='text-center'>
                {/* Product_Id:  */}
                {/* {removeid} */}
              </div>
            </Modal.Title>

            <Modal.Body>
              <div className='text-center mt-2 mb-3 fs-5'>
                Are you sure want to delete this variation permanently?
              </div>
            </Modal.Body>

            <div className='d-flex gap-3 align-items-center justify-content-center mt-3 mb-4'>
              <Button variant="secondary" onClick={handleClose2}>
                Close
              </Button>
              <Button variant="primary" onClick={DeleteSeriveVariations}>{loading ? <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className='ms-2'>Loading...</span>
              </> : <>Delete</>}</Button>
            </div>
          </div>
        </Modal>


      </Fragment>
    );
  }


}

export default Servicecreation;
