import React, { useEffect, useState, useRef } from "react";
import Card from "../../../components/bootstrap/card";
import { Button, Row, Col, Form } from "react-bootstrap";
import "./styles/UserProfile.scss";
import { toast } from "react-toastify";
import {
  createUserProfileData,
  getOfferingCategories,
} from "../../../services/profile_user_services/profile_user_services";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import "./styles/UserProfile.scss";
import fileupload from "../../../assets/loginLogos/file-upload.png";
import Tick from "../../../assets/Tick.png";
import { userProfileInformation } from "./../../../services/profile_user_services/profile_user_services";
import { SubscribeplanData } from "../../../services/subscrive_plan_services/subscribe_plan_services";
import ReactHtmlParser from "react-html-parser";
import Googleautocomplete from "./googleautocompleteaddress/Googleautocomplete";
import Spinner from 'react-bootstrap/Spinner';
import GooglePersonalPostalCode from "./GooglePersonlPostalCode/GooglePersonalPostalCode";
function UserProfile() {
  const { userInfo } = useOutletContext();
  const [step, setStep] = useState(0);

  const addstep = () => {
    setStep(step + 1);
  };
  const minusstep = () => {
    setStep(step - 1);
  };


  // first section

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [contactNumber, setContactNumber] = useState("")
  const [email, setEmail] = useState("")
  const [cityName, setCityName] = useState("")
  const [houseNo, setHouseNo] = useState("")
  const [streetAddress, setStreetAddress] = useState("")
  const [stateName, setStateName] = useState("")
  const [stateShortCode, setStateShortCode] = useState("")
  const [countryId, setCountryId] = useState("1")
  const [postelCode, setPostalCode] = useState("")
  const [profileDescription, setProfileDescription] = useState("")

  const [persondetailerror, setPersonalDetailError] = useState(false);


  const [contactNoError, setContactNoError] = useState("");


  const SubmitPersonalDetails = (e) => {
    if (firstName?.length === 0 || lastName?.length === 0 || contactNumber?.length === 0 || cityName?.length === 0 || houseNo?.length === 0 ||
      streetAddress?.length === 0 || stateName?.length === 0 || stateShortCode?.length === 0 || countryId?.length === 0 || postelCode?.length === 0 || profileDescription?.length === 0
    ) {
      setPersonalDetailError(true);
    }

    if (
      firstName &&
      lastName &&
      contactNumber &&
      cityName &&
      houseNo &&
      streetAddress &&
      stateName &&
      stateShortCode &&
      countryId &&
      postelCode &&
      profileDescription
    ) {

      // if (contactNumber?.length == 9) {
      // setPersonalDetailError(false);
      addstep();
      // }
      // else {
      //   setContactNoError("Contact Number Must be 9 digits");
      // }

    }
  }

  // second section

  const [businesserrror, setBusinessError] = useState(false);
  const [businessName, setBussinessName] = useState("")
  const [businessState, setBussinessState] = useState("")
  const [businessCountryName, setBussinessCountryName] = useState("")
  const [businessCityName, setBussinessCityName] = useState("")
  const [businessStateShortCode, setBussinessStateShortCode] = useState("")
  const [businessPinCode, setBussinessPincode] = useState("")
  const [websiteAddress, setWebsiteAddress] = useState("")
  const [categoryTypeId, setCategoryTypeId] = useState("")
  const [offeringCategories, setOfferingCategories] = useState(
    userInfo.offeringCategories ? userInfo.offeringCategories : ""
  );
  const [businessContactNumber, setBussinessContactNumber] = useState("")
  const [businessEmailId, setBussinessEmailId] = useState("")
  const [abnNumber, setABNNumber] = useState("")
  const [productDescription, setProductDescription] = useState("")
  const [bussinessAddressLineOne, setBussinessAddressLineOne] = useState("")
  const [businessDocumentFile, setBusinessDocumentFile] = useState("");
  // same address
  const [sameAddress, setSameAddress] = useState(false);

  // error
  const [businessContactNoerror, setContactNumberError] = useState("");
  const setIntialOfferingCategories = (ofc) => {
    if (ofc) {
      let ofcc = ofc.split(",");
      ofcc = ofcc.map((item) => {
        return { value: item, label: item };
      });
      return ofcc;
    } else {
      return "";
    }
  };
  const [reactSelectHandle, setReactSelectHandle] = useState(
    setIntialOfferingCategories(offeringCategories)
  );
  const [categories, setCategories] = useState("");


  const hadleOfferingCategory = (e) => {
    setReactSelectHandle(e);
    let offeringCategories = e
      .map((item) => {
        return item.label;
      })
      .toString();
    setOfferingCategories(offeringCategories);
  };


  useEffect(() => {
    // if (categoryTypeId) {
    getOfferingCategories(categoryTypeId)
      .then((categories) => {
        let react_select_categories = categories?.map((item) => {
          return { value: item.name, label: item.name };
        });
        setCategories(react_select_categories);
      })
      .catch((err) => {
        if (err) console.log(err);
      });
    // }
  }, [categoryTypeId]);

  const HandleFileupload = (e) => {
    if (e.target.files) {
      setBusinessDocumentFile(e.target.files);
    }
  };


  const CheckSameAddress = () => {
    setSameAddress((pre) => !pre)
  }

  useEffect(() => {
    if (sameAddress) {
      setBussinessContactNumber(contactNumber);
      setBussinessEmailId(email);
      setBussinessPincode(postelCode);
      setBussinessCityName(cityName)
      setBussinessState(stateName)
      setBussinessCountryName("Australia")
      setBussinessStateShortCode(stateShortCode)
      setBussinessAddressLineOne(houseNo + " , " + streetAddress)
    }
    else {
      setBussinessContactNumber("");
      setBussinessEmailId("");
      setBussinessPincode("");
      setBussinessCityName("")
      setBussinessState("")
      setBussinessCountryName("")
      setBussinessStateShortCode("")
      setBussinessAddressLineOne("")
    }


  }, [sameAddress])





  const SubmitBusinessSection = () => {

    if (businessName?.length === 0 || categoryTypeId?.length === 0 || offeringCategories?.length === 0 || businessContactNumber?.length === 0 || businessEmailId?.length === 0 || bussinessAddressLineOne?.length === 0 || businessPinCode?.length === 0 ||
      businessCityName?.length === 0 || businessState?.length === 0 ||
      businessStateShortCode?.length === 0 || businessCountryName?.length === 0 ||
      abnNumber?.length === 0 || productDescription?.length === 0 || reactSelectHandle?.length === 0) {
      setBusinessError(true);
    }


    if (businessName && categoryTypeId === 0 ? reactSelectHandle?.length > 0 : categoryTypeId && offeringCategories && businessContactNumber && businessEmailId && bussinessAddressLineOne && businessPinCode &&
      businessCityName && businessState && businessStateShortCode && businessCountryName && abnNumber && productDescription && reactSelectHandle
    ) {

      if (businessContactNumber?.length == 9 && abnNumber?.length == 11) {
        addstep();
        setContactNumberError(false);
      }
      else {
        setContactNumberError(true)
      }
    }

  }


  // third section


  const [legalBusinessName, setLegalBusinessName] = useState("");
  const [organizationTypeId, setOrganizationTypeId] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [tfnNumber, setTnfNumber] = useState("");
  const [taxationerror, setTaxtationError] = useState("");

  const [taxgstnumbererror, setTaxgstError] = useState(false);


  const SubmitTaxationSection = () => {

    if (legalBusinessName?.length === 0 || organizationTypeId?.length === 0) {
      setTaxtationError(true);
    }

    if (legalBusinessName && organizationTypeId) {

      // if (gstNumber?.length > 1) {
      //   if (gstNumber?.length == 11 && tfnNumber?.length == (tfnNumber?.length > 1 ? 9 : null)) {
      //     setTaxgstError(false);
      //   }
      //   else {
      //     setTaxgstError(true)
      //   }
      // }

      addstep();


    }
  }



  // fourth section


  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [bsbNumber, setBSBNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [bankingerror, setBankingError] = useState(false);
  const [createprofileloader1, setCreateProfileLoader1] = useState(false);
  const [createprofileloader, setCreateProfileLoader] = useState(false);


  const [bankbsnerror, setBankbsnError] = useState(false);


  const SubmitBankDetails = () => {

    if (accountHolderName?.length === 0 || bankName?.length === 0 || bsbNumber?.length === 0 || accountNumber?.length === 0) {
      setBankingError(true);
    }
    if (accountHolderName && bankName && bsbNumber && accountNumber) {
      // bsbNumber?.length === 6 && accountNumber?.length === 9
      if (bsbNumber?.length === 6 && accountNumber?.length > 4) {
        setBankbsnError(false);
        addstep();
      }
      else {
        setBankbsnError(true)
      }
    }
  }




  // fifth Section

  const [planName, setPlanName] = useState("");

  const [bgColor, setBgColor] = useState(null);
  const [plandetails, setPlanDetails] = useState([]);


  useEffect(() => {
    if (categoryTypeId) {
      const idscheck = categoryTypeId == 0 ? "3" : categoryTypeId;
      SubscribeplanData().then((res) => {
        const datasd = res?.results?.filter((item, index) => item?.planTypeId == idscheck);
        setPlanDetails(datasd);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [categoryTypeId])


  const ChangePlanName = (datas) => {
    setPlanName(datas);
  }


  const SubmitPlanName = () => {

    if (userInfo.stateId === 2) {
      const FormDatas = new FormData();
      const filess = businessDocumentFile[0]
      FormDatas.append('firstName', firstName);
      FormDatas.append('lastName', lastName);
      FormDatas.append('contactNumber', contactNumber);
      FormDatas.append('cityName', cityName);
      FormDatas.append('houseNo', houseNo);
      FormDatas.append('streetAddress', streetAddress);
      FormDatas.append('stateName', stateName);
      FormDatas.append('stateShortCode', stateShortCode);
      FormDatas.append('countryId', countryId);
      FormDatas.append('postelCode', postelCode);
      FormDatas.append('profileDescription', profileDescription);
      FormDatas.append('businessName', businessName);
      FormDatas.append('businessState', businessState);
      FormDatas.append('businessCountry', businessCountryName);
      FormDatas.append('businessPinCode', businessPinCode);
      FormDatas.append('businessCityName', businessCityName);
      FormDatas.append('businessStateShortCode', businessStateShortCode);
      FormDatas.append('websiteAddress', websiteAddress);
      FormDatas.append('offeringCategories', offeringCategories);
      FormDatas.append('categoryTypeId', categoryTypeId);
      FormDatas.append('businessContactNumber', businessContactNumber);
      FormDatas.append('businessEmailId', businessEmailId);
      FormDatas.append('abnNumber', abnNumber);
      FormDatas.append('businessAddress', "Australia");
      FormDatas.append('productDescription', productDescription);
      FormDatas.append('legalBusinessName', legalBusinessName);
      FormDatas.append('organizationTypeId', organizationTypeId == 1 ? "0" : organizationTypeId);
      FormDatas.append('gstNumber', gstNumber);
      FormDatas.append('tfnNumber', tfnNumber);
      FormDatas.append('accountHolderName', accountHolderName);
      FormDatas.append('bankName', bankName);
      FormDatas.append('bsbNumber', bsbNumber);
      FormDatas.append('accountNumber', accountNumber);
      FormDatas.append('swiftCode', swiftCode);
      FormDatas.append("upl", filess);
      FormDatas.append('bussinessAddressLineOne', bussinessAddressLineOne);
      FormDatas.append("planName", planName);

      if (planName) {
        setCreateProfileLoader1(true);
        createUserProfileData(FormDatas)
          .then((result) => {
            toast.success(
              `${userInfo.stateId === 2
                ? "Create Onboard Profile Success"
                : "Updated Successfully and waiting for approval"
              }`
            );
            setTimeout(() => {
              window.location.assign("/");
              setCreateProfileLoader1(false)
            }, 1000);

          })
          .catch((err) => {
            toast.error("Error in user profile creation");
            if (err) console.log(err);
            setCreateProfileLoader1(false);
          });
      }

    }

    else {
      const FormDatas = new FormData();
      const filess = businessDocumentFile[0]
      FormDatas.append('firstName', firstName);
      FormDatas.append('lastName', lastName);
      FormDatas.append('contactNumber', contactNumber);
      FormDatas.append('cityName', cityName);
      FormDatas.append('houseNo', houseNo);
      FormDatas.append('streetAddress', streetAddress);
      FormDatas.append('stateName', stateName);
      FormDatas.append('stateShortCode', stateShortCode);
      FormDatas.append('countryId', countryId);
      FormDatas.append('postelCode', postelCode);
      FormDatas.append('profileDescription', profileDescription);
      FormDatas.append('businessName', businessName);
      FormDatas.append('businessState', businessState);
      FormDatas.append('businessCountry', businessCountryName);
      FormDatas.append('businessPinCode', businessPinCode);
      FormDatas.append('businessCityName', businessCityName);
      FormDatas.append('businessStateShortCode', businessStateShortCode);
      FormDatas.append('websiteAddress', websiteAddress);
      FormDatas.append('offeringCategories', offeringCategories);
      FormDatas.append('categoryTypeId', categoryTypeId);
      FormDatas.append('businessContactNumber', businessContactNumber);
      FormDatas.append('businessEmailId', businessEmailId);
      FormDatas.append('abnNumber', abnNumber);
      FormDatas.append('businessAddress', "Australia");
      FormDatas.append('productDescription', productDescription);
      FormDatas.append('legalBusinessName', legalBusinessName);
      FormDatas.append('organizationTypeId', organizationTypeId == 1 ? "0" : organizationTypeId);
      FormDatas.append('gstNumber', gstNumber);
      FormDatas.append('tfnNumber', tfnNumber);
      FormDatas.append('accountHolderName', accountHolderName);
      FormDatas.append('bankName', bankName);
      FormDatas.append('bsbNumber', bsbNumber);
      FormDatas.append('accountNumber', accountNumber);
      FormDatas.append('swiftCode', swiftCode);
      FormDatas.append("upl", filess);
      FormDatas.append('bussinessAddressLineOne', bussinessAddressLineOne);

      setCreateProfileLoader(true);
      createUserProfileData(FormDatas)
        .then((result) => {
          toast.success(
            `${userInfo.stateId === 2
              ? "Create Onboard Profile Success"
              : "Updated Successfully and waiting for approval"
            }`
          );
          setTimeout(() => {
            window.location.assign("/");
            setCreateProfileLoader(false)
          }, 1000);

        })
        .catch((err) => {
          toast.error("Error in user profile creation");
          if (err) console.log(err);
          setCreateProfileLoader(false);
        });
    }

  }


  // end


  useEffect(() => {

    if (userInfo.stateId === 2) {
      const userid = localStorage.getItem("userid");
      userProfileInformation(JSON.parse(userid)).then((res) => {
        setEmail(res?.email)
        setFirstName(res?.firstName)
        setLastName(res?.lastName)
        setContactNumber(res?.contactNumber)
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      const userid = localStorage.getItem("userid");
      userProfileInformation(JSON.parse(userid)).then((res) => {
        setEmail(res?.email)
        setFirstName(res?.firstName)
        setLastName(res?.lastName)
        setContactNumber(res?.contactNumber)
        setHouseNo(res?.houseNo)
        setStreetAddress(res?.streetAddress)
        setPostalCode(res?.postelCode)
        setCityName(res?.cityName)
        setStateName(res?.stateName)
        setStateShortCode(res?.stateShortCode)
        setProfileDescription(res?.profileDescription)

        // second section

        setBussinessName(res?.businessName)
        setWebsiteAddress(res?.websiteAddress)
        setCategoryTypeId(res?.categoryTypeId)
        setOfferingCategories(res?.offeringCategories)
        setBussinessContactNumber(res?.businessContactNumber)
        setBussinessEmailId(res?.businessEmailId)
        setBussinessPincode(res?.businessPinCode)
        setBussinessCityName(res?.businessCityName)
        setBussinessState(res?.businessState)
        setBussinessStateShortCode(res?.businessStateShortCode)
        setBussinessCountryName(res?.businessCountryName || "Australia")
        setABNNumber(res?.abnNumber)
        setBussinessAddressLineOne(res?.bussinessAddressLineOne);
        setProductDescription(res?.productDescription)
        setBusinessDocumentFile(res?.businessDocuments)

        // third section

        setLegalBusinessName(res?.legalBusinessName);
        setOrganizationTypeId(res?.organizationTypeId === 0 ? 1 : res?.organizationTypeId);
        setGstNumber(res?.gstNumber);
        setTnfNumber(res?.tfnNumber);

        // fourth Section


        setAccountHolderName(res?.accountHolderName);
        setBankName(res?.bankName);
        setBSBNumber(res?.bsbNumber);
        setAccountNumber(res?.accountNumber);
        setSwiftCode(res?.swiftCode);


        // fifth section

        setPlanName(res?.planName)


      }).catch((err) => {
        console.log(err);
      })
    }

  }, [])


  return (
    <div className="d-flex flex-column justify-content-between align-items-center  mb-4 gap-3 w-100 col-lg-12">
      <Card className="col-lg-12 m-0 p-4 w-100">
        <Card.Header>
          <h5> {userInfo.stateId === 2 ? "Create profile" : "Update Profile"} </h5>

        </Card.Header>
        <div className="mt-5 w-100 mb-5">
          <Row>
            <Col
              lg="6"
              xl="12"
              md="12"
              xs="12"
              className={planName ? "userdata1" : "userdata mt-2 mb-3"}
            >
              <div
                className={`cards justify-content-center text-center align-items-center ${step >= 0 ? (step === 0 ? "actives" : "n") : ""
                  }`}

                onClick={() => {
                  if (userInfo.stateId === 2) {

                  }
                  else {
                    setStep(0)
                  }
                }}
              >
                <svg
                  width="40"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <div className="mt-2">
                  Personal <br /> details
                </div>
              </div>
            </Col>

            <Col
              lg="6"
              xl="2"
              md="6"
              className={planName ? "userdata1" : "userdata mt-2 mb-3"}
            >
              <div
                className={`cards justify-content-center text-center align-items-center ${step >= 1 ? (step === 1 ? "actives" : "n") : ""
                  }`}
                onClick={() => {
                  if (userInfo.stateId === 2) {

                  }
                  else {
                    setStep(1)
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  viewBox="0 0 40 40"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.2194 4.89463C17.8608 2.7788 22.3367 2.81578 25.9439 4.9915C29.5157 7.21153 31.6866 11.1736 31.6664 15.4358C31.5832 19.6699 29.2555 23.65 26.3458 26.7268C24.6664 28.5107 22.7877 30.0881 20.748 31.4267C20.538 31.5482 20.3079 31.6295 20.0691 31.6667C19.8393 31.6569 19.6155 31.589 19.4179 31.4691C16.304 29.4576 13.5722 26.8901 11.3539 23.8899C9.49763 21.3856 8.44304 18.36 8.33331 15.224C8.3309 10.9538 10.5779 7.01046 14.2194 4.89463ZM16.3236 16.9913C16.9361 18.5014 18.3819 19.4864 19.986 19.4864C21.0368 19.494 22.047 19.0731 22.7913 18.3175C23.5357 17.5619 23.9524 16.5344 23.9487 15.464C23.9543 13.8301 22.9924 12.3539 21.5121 11.7246C20.0318 11.0954 18.325 11.4373 17.1888 12.5906C16.0526 13.7439 15.711 15.4812 16.3236 16.9913Z"
                    fill="currentColor"
                  />
                  <ellipse
                    opacity="0.4"
                    cx="20"
                    cy="35"
                    rx="8.33333"
                    ry="1.66667"
                    fill="currentColor"
                  />
                </svg>
                <div className="mt-2">
                  Business <br /> details
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              xl="2"
              md="6"
              className={planName ? "userdata1" : "userdata mt-2 mb-3"}
            >
              <div
                className={`cards justify-content-center text-center align-items-center ${step >= 2 ? (step === 2 ? "actives" : "n") : ""
                  }`}
                onClick={() => {
                  if (userInfo.stateId === 2) {

                  }
                  else {
                    setStep(2)
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  viewBox="0 0 40 40"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.6607 13.9585H29.6029C26.3185 13.9643 23.6578 16.5586 23.6518 19.7609C23.6474 22.9705 26.3111 25.5763 29.6029 25.5807H36.6666V26.0906C36.6666 31.6893 33.2726 35 27.5289 35H12.4726C6.72739 35 3.33331 31.6893 3.33331 26.0906V13.8964C3.33331 8.2977 6.72739 5 12.4726 5H27.5229C33.2666 5 36.6607 8.2977 36.6607 13.8964V13.9585ZM11.2326 13.9455H20.6326H20.6385H20.6504C21.3541 13.9427 21.9229 13.3836 21.92 12.6961C21.917 12.01 21.3422 11.4553 20.6385 11.4582H11.2326C10.5333 11.4611 9.96591 12.0143 9.96294 12.6975C9.95998 13.3836 10.5289 13.9427 11.2326 13.9455Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.4"
                    d="M26.729 20.4944C27.0775 22.0798 28.4674 23.1951 30.0543 23.1661H35.4708C36.1311 23.1661 36.6666 22.6193 36.6666 21.9434V17.7242C36.6652 17.0497 36.1311 16.5015 35.4708 16.5H29.9268C28.1217 16.5058 26.6638 18.0041 26.6666 19.8505C26.6666 20.0666 26.6879 20.2827 26.729 20.4944"
                    fill="currentColor"
                  />
                  <ellipse
                    cx="30"
                    cy="19.8333"
                    rx="1.66667"
                    ry="1.66667"
                    fill="currentColor"
                  />
                </svg>
                <div className="mt-2">
                  Taxation
                  <br />
                  details
                </div>
              </div>
            </Col>
            <Col
              lg="6"
              xl="5"
              md="6"
              className={planName ? "userdata1" : "userdata mt-2 mb-3"}
            >
              <div
                className={`cards justify-content-center text-center align-items-center ${step >= 3 ? (step === 3 ? "actives" : "n") : ""
                  }`}
                onClick={() => {
                  if (userInfo.stateId === 2) {

                  }
                  else {
                    setStep(3)
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  viewBox="0 0 40 40"
                  fill="currentColor"
                >
                  <path
                    d="M19.9947 25.291C12.8063 25.291 6.66632 26.4243 6.66632 30.9577C6.66632 35.4927 12.768 36.666 19.9947 36.666C27.183 36.666 33.323 35.5343 33.323 30.9993C33.323 26.4643 27.223 25.291 19.9947 25.291"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.4"
                    d="M19.9947 20.9728C24.8913 20.9728 28.8147 17.0478 28.8147 12.1528C28.8147 7.25782 24.8913 3.33282 19.9947 3.33282C15.0997 3.33282 11.1747 7.25782 11.1747 12.1528C11.1747 17.0478 15.0997 20.9728 19.9947 20.9728"
                    fill="currentColor"
                  />
                </svg>
                <div className="mt-2">
                  Banking <br />
                  details
                </div>
              </div>
            </Col>



            {planName ? <>
            </> : <Col
              lg="6"
              xl="2"
              md="6"

              className={planName ? "userdata1" : "userdata mt-2 mb-3"}
            >
              <div
                className={`cards justify-content-center text-center align-items-center ${step >= 4 ? (step === 4 ? "actives" : "n") : ""
                  }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  viewBox="0 0 40 40"
                  fill="currentColor"
                >
                  <path
                    d="M19.9947 25.291C12.8063 25.291 6.66632 26.4243 6.66632 30.9577C6.66632 35.4927 12.768 36.666 19.9947 36.666C27.183 36.666 33.323 35.5343 33.323 30.9993C33.323 26.4643 27.223 25.291 19.9947 25.291"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.4"
                    d="M19.9947 20.9728C24.8913 20.9728 28.8147 17.0478 28.8147 12.1528C28.8147 7.25782 24.8913 3.33282 19.9947 3.33282C15.0997 3.33282 11.1747 7.25782 11.1747 12.1528C11.1747 17.0478 15.0997 20.9728 19.9947 20.9728"
                    fill="currentColor"
                  />
                </svg>
                <div className="mt-2">
                  Plan
                  <br />
                  details
                </div>
              </div>
            </Col>}
          </Row>
        </div>

        {step === 0 && <>

          <div>
            <div>
              <Row>
                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter First Name"
                      name="firstName" value={firstName}
                      onChange={(e) => setFirstName(e?.target?.value)}
                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && firstName?.length <= 0 ? <span className="text-danger">First Name is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>

                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Last Name"
                      name="lastName" value={lastName}
                      onChange={(e) => setLastName(e?.target?.value)}

                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && lastName?.length <= 0 ? <span className="text-danger">Last Name is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Contact Number</Form.Label>
                  <div className="mobile-number-section">

                    <div className="number-section-change">+61</div>
                    <Form.Control
                      type="text"
                      name="contactNumber"
                      maxLength={9}
                      onChange={(e) => {
                        var tests = /^[0-9\b]+$/;
                        if (
                          e.target.value === "" ||
                          tests.test(e.target.value)
                        ) {

                          return setContactNumber(e?.target?.value);
                        } else {
                          return null;
                        }
                      }}
                      value={contactNumber}
                      placeholder="Enter Contact Number"
                    />



                  </div>

                  <Form.Text className="text-muted">

                    {persondetailerror && contactNumber?.length <= 0 ? <span className="text-danger">Contact number is Required !</span> :
                      <span className="text-danger">
                        {contactNoError}
                      </span>
                    }
                  </Form.Text>
                </Col>
                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter Email Id"
                      name="email" value={email}
                      onChange={(e) => setEmail(e?.target?.value)}
                      disabled
                      className="email-disabled"
                    />
                    <Form.Text className="text-muted">
                      {/* {persondetailerror && contactNumber?.length <= 0 ? <span className="text-danger">Email is Required !</span> : null} */}
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>

              <Row>
                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>House No./Unit No.</Form.Label>
                    <Form.Control type="text" placeholder="Enter House No./Unit No."
                      name="houseNo" value={houseNo}
                      onChange={(e) => setHouseNo(e?.target?.value)}
                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && houseNo?.length <= 0 ? <span className="text-danger">House No is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>

                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control type="text" placeholder="Enter Street Address"
                      name="streetAddress" value={streetAddress}
                      onChange={(e) => setStreetAddress(e?.target?.value)}

                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && streetAddress?.length <= 0 ? <span className="text-danger">Street is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Post code</Form.Label>
                    {/* <Form.Control type="text" placeholder="Enter postelCode"
                      name="pincode" ref={ref} value={postelCode}
                      onChange={(e) => setPostalCode(e?.target?.value)}
                      maxLength={4}
                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && postelCode?.length <= 0 ? <span className="text-danger">Postal Code is Required !</span> : null}
                    </Form.Text> */}
                    <GooglePersonalPostalCode
                      setPostalCode={setPostalCode}
                      setCityName={setCityName}
                      setStateName={setStateName}
                      setStateShortCode={setStateShortCode}
                      persondetailerror={persondetailerror}
                      postelCode={postelCode}
                    />
                  </Form.Group>

                </Col>

                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="Enter City"
                      name="city" value={cityName}
                      onChange={(e) => setCityName(e?.target?.value)}

                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && cityName?.length <= 0 ? <span className="text-danger">City is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>

              <Row>
                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" placeholder="Enter stateName"
                      name="state" value={stateName}
                      onChange={(e) => setStateName(e?.target?.value)}
                      disabled
                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && stateName?.length <= 0 ? <span className="text-danger">state is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>

                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>State Code</Form.Label>
                    <Form.Control type="text" placeholder="Enter City"
                      name="stateshortcode" value={stateShortCode}
                      onChange={(e) => setStateShortCode(e?.target?.value)}
                      disabled
                    />
                    <Form.Text className="text-muted">
                      {persondetailerror && stateShortCode?.length <= 0 ? <span className="text-danger">stateShortCode is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>

                <Col>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Country</Form.Label>
                    <Form.Select aria-label="Default select example" name="countryId" value={countryId} disabled
                      onChange={(e) => setCountryId(e?.target?.value)}
                    >
                      <option value="1">Australia</option>

                    </Form.Select>
                    <Form.Text className="text-muted">
                      {persondetailerror && countryId?.length <= 0 ? <span className="text-danger">countryId is Required !</span> : null}
                    </Form.Text>
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Profile Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="profileDescription"
                    value={
                      profileDescription
                    }

                    maxLength={2000}
                    onChange={(e) => setProfileDescription(e?.target?.value)}
                  />
                  <Form.Text className="text-muted">
                    {persondetailerror && profileDescription?.length <= 0 ? <span className="text-danger">Profile Description is Required !</span> : null}
                  </Form.Text>
                </Col>
              </Row>
              <div>
                <div className="btn-end mt-3 mb-2">
                  <Button
                    className="next-btn"
                    onClick={SubmitPersonalDetails}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>

          </div>
        </>}

        {step === 1 && <>
          <div>

            <Row>
              <Col>
                <div className="d-flex gap-2 mt-3 mb-4" >
                  <Form.Check
                    onChange={CheckSameAddress}
                    value={sameAddress}
                    type="checkbox"
                    id="1"
                    checked={postelCode == businessPinCode ? true : false}
                  ></Form.Check>{" "}
                  <label for="1" style={{ cursor: "pointer" }}>
                    Same as Personal Address
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business Name"
                    name="businessName" value={businessName}
                    onChange={(e) => setBussinessName(e?.target?.value)}
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessName?.length <= 0 ? <span className="text-danger">Business Name is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Website</Form.Label>
                  <Form.Control type="email" placeholder="Enter Business Website Url"
                    name="websiteAddress" value={websiteAddress}
                    onChange={(e) => setWebsiteAddress(e?.target?.value)}

                  />
                  <Form.Text className="text-muted">
                    {/* {businesserrror && websiteAddress?.length <= 0 ? <span className="text-danger">Business Website is Required !</span> : null} */}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Service Type</Form.Label>

                  <Form.Select aria-label="Default select example" value={categoryTypeId} name="categoryTypeId" onChange={(e) => {
                    setCategoryTypeId(e?.target?.value)
                    setOfferingCategories("");
                    setReactSelectHandle(null);
                  }}>
                    <option value="" disabled selected>
                      --Please choose a Service Type--
                    </option>
                    <option value="1">Product</option>
                    <option value="2">Service</option>
                    <option value="0">Product and Service</option>
                  </Form.Select>
                  <Form.Text className="text-muted">
                    {businesserrror && categoryTypeId?.length <= 0 ? <span className="text-danger">Business Service Type is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Categories </Form.Label>

                  <Select
                    defaultValue={[]}
                    isMulti
                    name="offeringCategories"
                    options={categories}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={reactSelectHandle}
                    onChange={(e) => {
                      hadleOfferingCategory(e);
                    }}
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && offeringCategories?.length <= 0 ? <span className="text-danger">Business Categories is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label>Business Contact No</Form.Label>
                <div className="mobile-number-section">
                  <div className="number-section-change">+61</div>
                  <Form.Control
                    type="text"
                    name="businessContactNumber"
                    maxLength={9}
                    onChange={(e) => {
                      var tests = /^[0-9\b]+$/;
                      if (
                        e.target.value === "" ||
                        tests.test(e.target.value)
                      ) {

                        return setBussinessContactNumber(e?.target?.value);
                      } else {
                        return null;
                      }
                    }}
                    value={businessContactNumber}
                    placeholder="Enter Business Contact Number"
                  />
                </div>
                <Form.Text className="text-muted">
                  {businesserrror && businessContactNumber?.length <= 0 ? <span className="text-danger">Business Contact Number is Required !</span> : <>

                    {businessContactNoerror && businessContactNumber?.length <= 8 ? <span className="text-danger">Business Contact Number Must be 9 digit</span> : null}
                  </>}
                </Form.Text>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter Business Email"
                    name="businessEmailId" value={businessEmailId}
                    onChange={(e) => setBussinessEmailId(e?.target?.value)}
                    required
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessEmailId?.length <= 0 ? <span className="text-danger">Business Email is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Post Code</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business Pincode"
                    name="businessPinCode" value={businessPinCode}
                    onChange={(e) => setBussinessPincode(e?.target?.value)}
                    ref={ref}
                    maxLength={4}
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessPinCode?.length <= 0 ? <span className="text-danger">Business Pincode is Required !</span> : null}
                  </Form.Text>
                </Form.Group> */}
                <Googleautocomplete
                  businessPinCode={businessPinCode}
                  setBussinessPincode={setBussinessPincode}
                  businesserrror={businesserrror}

                  // lists

                  setBussinessCityName={setBussinessCityName}
                  setBussinessState={setBussinessState}
                  setBussinessStateShortCode={setBussinessStateShortCode}
                  setBussinessCountryName={setBussinessCountryName}

                />
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business City</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business City"
                    name="businessCityName" value={businessCityName}
                    onChange={(e) => setBussinessCityName(e?.target?.value)}
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessCityName?.length <= 0 ? <span className="text-danger">Business City Name is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business State</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business State"
                    name="businessState" value={businessState}
                    onChange={(e) => setBussinessState(e?.target?.value)}
                    disabled
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessState?.length <= 0 ? <span className="text-danger">Business State is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business State Code</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business State Code"
                    name="businessStateShortCode" value={businessStateShortCode}
                    onChange={(e) => setBussinessStateShortCode(e?.target?.value)}
                    disabled
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessStateShortCode?.length <= 0 ? <span className="text-danger">Business State Code is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Business Country</Form.Label>
                  <Form.Control type="text" placeholder="Enter Business Country"
                    name="businessCountryName" value={businessCountryName}
                    onChange={(e) => setBussinessCountryName(e?.target?.value)}
                    disabled
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && businessCountryName?.length <= 0 ? <span className="text-danger">Business Country is Required !</span> : null}
                  </Form.Text>
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>ABN/ACN Number</Form.Label>
                  <Form.Control type="text" placeholder="Enter ABN/ACN Number"
                    name="abnNumber" value={abnNumber}
                    // onChange={(e) => setBussinessStateShortCode(e?.target?.value)}
                    onChange={(e) => {
                      var tests = /^[0-9\b]+$/;
                      if (
                        e.target.value === "" ||
                        tests.test(e.target.value)
                      ) {
                        return setABNNumber(e?.target?.value);
                      } else {
                        return null;
                      }
                    }}
                    maxLength={11}
                  />
                  <Form.Text className="text-muted">
                    {businesserrror && abnNumber?.length <= 0 ? <span className="text-danger">Business ABN/ACN Number is Required !</span> : (
                      <>
                        {
                          businessContactNoerror && abnNumber?.length <= 10 ? <span className="text-danger">ABN/ACN Number Must be 11 digit</span> : null
                        }
                      </>
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
            </Row>


            <Row>
              <Col>
                <Form.Label>Business Address 1</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={45}
                  name="bussinessAddressLineOne"
                  value={bussinessAddressLineOne}
                  onChange={(e) => setBussinessAddressLineOne(e?.target?.value)}
                  placeholder="Enter Bussiness Address 1"
                />
                <Form.Text className="text-muted">
                  {businesserrror && bussinessAddressLineOne?.length <= 0 ? <span className="text-danger">Business Address Line is Required !</span> : null}
                </Form.Text>
              </Col>
              <Col>

                <Form.Label>Business Upload ABN/ACN Certificate</Form.Label>
                <div className="form-group">

                  <div className="d-flex gap-2">
                    <label htmlFor="upload-file">
                      <img
                        src={fileupload}
                        alt="no image"
                        className="uploadfiles"
                      />
                    </label>

                    <div className="mt-2">
                      {businessDocumentFile.length > 0 && (
                        <div>
                          <div>
                            {businessDocumentFile[0].originalName ? (
                              <a
                                href={`${process.env.REACT_APP_IMAGE_URL}/${businessDocumentFile[0].name}`}
                                download={`${businessDocumentFile[0].originalName}`}
                              >
                                {businessDocumentFile[0].originalName}
                              </a>
                            ) : (
                              businessDocumentFile[0].name
                            )}{" "}
                            <img
                              src={Tick}
                              width="10px"
                              height="10px"
                            />
                          </div>
                        </div>
                      )}
                      {businessDocumentFile.length > 1 && (
                        <div>
                          <div>
                            {businessDocumentFile[0].originalName ? (
                              <a
                                href={`${process.env.REACT_APP_IMAGE_URL}/${businessDocumentFile[0].name}`}
                                download={businessDocumentFile[0].originalName}
                              >
                                {businessDocumentFile[0].originalName}
                              </a>
                            ) : (
                              businessDocumentFile[0].name
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <Form.Control
                    multiple
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    name="files"
                    onChange={HandleFileupload}
                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                  />


                </div>
              </Col>

            </Row>
            <div className="mt-2">
              <Row>
                <Col>
                  <Form.Label>Business Description</Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="productDescription"
                    placeholder="Enter Bussiness Description"
                    value={productDescription}
                    onChange={(e) => setProductDescription(e?.target?.value)}
                    maxLength={2000}

                  />
                  <Form.Text className="text-muted">
                    {businesserrror && productDescription?.length <= 0 ? <span className="text-danger">Business Description is Required !</span> : null}
                  </Form.Text>
                </Col>
              </Row>
            </div>

            <div className="button-gap-section mt-4 mb-2">
              <div>
                {step === 1 && (
                  <Button className="back-btn" onClick={minusstep}>
                    Back
                  </Button>
                )}
              </div>
              <div></div>
              <div className="btn-end">
                <Button
                  className="next-btn"
                  type="submit"
                  onClick={SubmitBusinessSection}
                >

                  Next

                </Button>
              </div>
            </div>
          </div>
        </>}

        {step === 2 && <>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Legal Business Name</Form.Label>
                <Form.Control type="text" placeholder="Enter legalBusiness Name "
                  name="legalBusinessName" value={legalBusinessName}
                  onChange={(e) => setLegalBusinessName(e?.target?.value)}
                />
                <Form.Text className="text-muted">
                  {taxationerror && legalBusinessName?.length <= 0 ? <span className="text-danger">Legal Business Name is Required !</span> : null}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col>

              <Form.Label>Organization Type</Form.Label>
              <Form.Select aria-label="Default select example" name="organizationTypeId" value={organizationTypeId}
                onChange={(e) => setOrganizationTypeId(e?.target?.value)}>
                <option value="" selected disabled>---please Select Organization Type---</option>
                <option value={"1"}>Sole trader</option>
                <option value={"2"}>Company</option>
                <option value={"3"}>Partnership</option>
                <option value={"4"}>Co-operative</option>
                <option value={"5"}>Joint venture</option>
              </Form.Select>
              <Form.Text className="text-muted">
                {taxationerror && organizationTypeId?.length <= 0 ? <span className="text-danger">Organization Type is Required !</span> : null}
              </Form.Text>

            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>GST Number</Form.Label>
                <Form.Control type="text" placeholder="Enter GST Number  "
                  name="gstNumber" value={gstNumber}
                  maxLength={11}
                  onChange={(e) => {
                    var tests = /^[0-9\b]+$/;
                    if (
                      e.target.value === "" ||
                      tests.test(e.target.value)
                    ) {
                      return setGstNumber(e?.target?.value);
                    } else {
                      return null;
                    }
                  }}
                />
                <Form.Text className="text-muted">
                  {/* {taxationerror && gstNumber?.length <= 0 ? <span className="text-danger">GST Number is Required !</span> : null} */}
                  {taxgstnumbererror && gstNumber?.length <= 10 ? <span className="text-danger">GST Number Must be 11 digit</span> : null}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>TFN Number</Form.Label>
                <Form.Control type="text" placeholder="Enter TNF Number "
                  name="tfnNumber" value={tfnNumber}
                  maxLength={9}
                  onChange={(e) => {
                    var tests = /^[0-9\b]+$/;
                    if (
                      e.target.value === "" ||
                      tests.test(e.target.value)
                    ) {
                      return setTnfNumber(e?.target?.value);
                    } else {
                      return null;
                    }
                  }}

                />
                <Form.Text className="text-muted">
                  {taxgstnumbererror && tfnNumber?.length <= 8 ? <span className="text-danger">TNF Number Must be 9 digit</span> : null}

                  {/* {taxationerror && tfnNumber?.length <= 0 ? <span className="text-danger">TFN Number is Required !</span> : null} */}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <div className="button-gap-section mt-4 mb-3">
            <div>
              {step === 2 && (
                <Button className="back-btn" onClick={minusstep}>
                  Back
                </Button>
              )}
            </div>
            <div></div>
            <div className="btn-end">
              <Button
                className="next-btn"
                onClick={SubmitTaxationSection}
              >
                Next
              </Button>
            </div>
          </div>
        </>}

        {step === 3 && <>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Business Account Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Business Account Name"
                  name="accountHolderName" value={accountHolderName}
                  onChange={(e) => setAccountHolderName(e?.target?.value)}
                />
                <Form.Text className="text-muted">
                  {bankingerror && accountHolderName?.length <= 0 ? <span className="text-danger">Business Account Name is Required !</span> : null}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Bank Name"
                  name="bankName" value={bankName}
                  onChange={(e) => setBankName(e?.target?.value)}
                />
                <Form.Text className="text-muted">
                  {bankingerror && bankName?.length <= 0 ? <span className="text-danger">Bank Name is Required !</span> : null}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>BSB Number</Form.Label>
                <Form.Control type="text" placeholder="Enter BSB Number"
                  name="bsbNumber" value={bsbNumber}
                  onChange={(e) => {
                    var tests = /^[0-9\b]+$/;
                    if (
                      e.target.value === "" ||
                      tests.test(e.target.value)
                    ) {
                      return setBSBNumber(e?.target?.value);
                    } else {
                      return null;
                    }
                  }}
                  maxLength={6}
                />
                <Form.Text className="text-muted">

                  {bankingerror && bsbNumber?.length <= 0 ? <span className="text-danger">BSB Number  is Required !</span> : <>
                    {bankbsnerror && bsbNumber ? <span className="text-danger">Must be 6 BSB Number</span> : null}
                  </>}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Account Number</Form.Label>
                <Form.Control type="text" placeholder="Enter Account Number"
                  name="accountNumber" value={accountNumber}
                  onChange={(e) => {
                    var tests = /^[0-9\b]+$/;
                    if (
                      e.target.value === "" ||
                      tests.test(e.target.value)
                    ) {
                      return setAccountNumber(e?.target?.value);
                    } else {
                      return null;
                    }
                  }}
                  minLength={4}
                  maxLength={9}
                />
                <Form.Text className="text-muted">
                  {bankingerror && accountNumber?.length <= 0 ? <span className="text-danger">Account Number is Required !</span> : <>
                    {bankbsnerror && accountNumber ? <span className="text-danger">Account Number ( Min 5 and Max 9 ) is Required </span> : null}
                  </>}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>SWIFT Code</Form.Label>
                <Form.Control type="text" placeholder="Enter SWIFT Code"
                  name="swiftCode" value={swiftCode}
                  onChange={(e) => setSwiftCode(e?.target?.value)}
                  minLength={9}
                  maxLength={11}
                />
                <Form.Text className="text-muted">
                  {/* {bankingerror && accountNumber?.length <= 0 ? <span className="text-danger">SWIFT Code is Required !</span> : null} */}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <div className="button-gap-section mt-4 mb-3">
            <div>
              {step === 3 && (
                <Button className="back-btn" onClick={minusstep}>
                  Back
                </Button>
              )}
            </div>
            <div></div>
            <div className="btn-end">
              <Button
                className="next-btn"

                onClick={userInfo.stateId === 2 ? SubmitBankDetails : SubmitPlanName}
              >

                {userInfo.stateId === 2 ? <>
                  <div>
                    Next
                  </div>
                </> : <>


                  {createprofileloader ? <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ms-2">Loading...</span>
                  </> : <>
                    Update
                  </>}
                </>}

              </Button>
            </div>
          </div>
        </>}


        {step === 4 && <>

          <div className="mt-5">
            <Card.Body>

              <div className="main-card">
                <h3 className="flexiable"></h3>
                <div className="loreams"></div>
              </div>

              <div>
                <div className="subscribeplanmainsection">


                  <Card className=" m-0 p-0">
                    <div className="subscribemainpage">
                      <div className="subtitle">

                        <span>
                          Subscription Plans & Pricing
                        </span>
                      </div>
                      <div className="row">
                        {plandetails?.map((item, index) => {
                          return (
                            <div className="subscribecard"
                              key={index}
                              onClick={() => {
                                setBgColor(index)
                                ChangePlanName(item?.id)
                              }}
                              style={{
                                color:
                                  bgColor === index ? "white" : "gray",
                                background:
                                  bgColor === index ? "#e95a5a" : "white",
                              }}
                            >
                              <div className="subname">
                                {item?.planName}
                              </div>
                              <div className="subprice">
                                <div className="subpricename">
                                  A$ {item?.planPrice}
                                </div>
                                <div className="mt-2">
                                  {item?.durationMonth} Months
                                </div>
                              </div>

                              <div className="subdes">
                                {item?.planDescription}
                              </div>
                              <div className="mt-5">
                                <hr />
                              </div>

                              <div className="mt-2">
                                {item?.allowedProducts ? <>Products Allowed - {item?.allowedProducts}</> : null}
                              </div>

                              <div className="mt-3">
                                {item?.allowedServices ? <>Services Allowed - {item?.allowedServices}</> : null}

                              </div>
                              <div className="mt-5">
                                {ReactHtmlParser(item?.planDetails.replace(/&lt;/g, "<"))}

                              </div>
                            </div>
                          )

                        })}
                      </div>
                    </div>
                  </Card>


                </div>
              </div>

              <div className="button-gap-section mb-2">
                <div>
                  {step === 4 && (
                    <Button className="back-btn" onClick={minusstep}>
                      Back
                    </Button>
                  )}
                </div>
                <div></div>
                <div className="btn-end ">

                  {planName ? <>
                    <Button
                      className="next-btn"
                      type="submit"
                      onClick={SubmitPlanName}
                    >

                      {createprofileloader1 ? <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-2">Loading...</span>
                      </> : <>
                        Save
                      </>}


                    </Button>
                  </> : <>

                    <Button
                      className="next-btn"
                      type="submit"
                    // onClick={HandleChangePlanError}
                    >
                      Select Plan
                    </Button>
                  </>}
                </div>
              </div>

            </Card.Body>

          </div>

        </>}

      </Card>
    </div>
  );
}
export default UserProfile;
