import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import * as moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import './styles.scss';
import { ServiceDetailsEdit, ServiceDetailsEditVariations } from '../../../../../services/services/services';
function Serviceview() {
    const { id } = useParams();
    const [datas, setDatas] = useState([]);
    const [datas1, setDatas1] = useState([]);

    console.log(datas1, 'datas1')

    const [invoiceloading, setInvoiceLoading] = useState(false);
    const [invoiceloading1, setInvoiceLoading1] = useState(false);
    const [billdata, setBillData] = useState("");
    const [billdata1, setBillData1] = useState("");

    useEffect(() => {
        getOrderservice();

        ServiceDetailsEditVariations(id).then((res) => {
            console.log(res);
            setDatas(res);
        }).catch((err) => {
            console.log(err);
        })
    }, [id])

    const getOrderservice = () => {
        let isCancelled = false;
        if (!isCancelled) {
            // ServiceDetailsEdit
            ServiceDetailsEdit(id).then((res) => {


                setDatas1(res);
            }).catch((err) => {
                console.log(err);
            })
        }
        return () => {
            isCancelled = false;
        }

    }




    return (
        <div>
            <h3 className="mb-4">Service Details </h3>

            <>
                <Row>
                    <Col>
                        <Card className="p-0 ">
                            <Card.Header className="d-flex justify-content-between">
                            </Card.Header>
                            <Card.Body className="m-0">
                                <div className="new-user-info">

                                    <Row>

                                        <Col>
                                            <div className='mt-4 mb-3'>
                                                <div className="col-md-6">
                                                    <h6>service Name:</h6>
                                                    <p>{datas1?.serviceName} </p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='mt-4 mb-3'>
                                                <div className="col-md-6">
                                                    <h6>service Description:</h6>
                                                    <p>{datas1?.serviceDescription} </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="new-user-info mt-3">
                                    <h4>Service Details</h4>



                                    <div className='container mt-4'>

                                        {datas?.map((item, index) => {
                                            return (
                                                <div key={index}>

                                                    <div className='row gap-1'>

                                                        <div className='card12 col-lg-6 col-md-10 col-xs-10 col-sm-10 ' key={index}

                                                        >

                                                            <div className="row mt-4">
                                                                <div className="col-md-6">
                                                                    <h6>Title</h6>
                                                                    <p>{item?.title}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h6>Duration</h6>
                                                                    <p>{item?.durationDetails}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-md-6">
                                                                    <h6>Start Date</h6>
                                                                    <p>{moment(item?.startDate).format("LLLL")}</p>

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h6>End Date</h6>
                                                                    <p>{moment(item?.endDate).format("LLLL")}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-md-6">
                                                                    <h6>NumberOfPeopleAllowed</h6>
                                                                    <p>{item?.numberOfPeopleAllowed}</p>

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h6>LinkDescription</h6>
                                                                    <p>{item?.linkDescription ? item?.linkDescription : "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-md-6">
                                                                    <h6>Address</h6>
                                                                    <p>{item?.address ? item?.address : "-"}</p>

                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h6>IsCancellationAvailable</h6>
                                                                    <p>{item?.isCancellationAvailable ? "CancellationAvailable" : null}</p>
                                                                </div>
                                                            </div>
                                                            <div className="row mt-4">
                                                                <div className="col-md-6">
                                                                    <h6>Price</h6>
                                                                    <p>A$ {item?.price}</p>

                                                                </div>
                                                                <div className="col-md-6">

                                                                </div>
                                                            </div>
                                                            <div>
                                                                <h4 className='mb-2'>workingDays</h4>
                                                                {item?.workingDays?.map((items, index) => {
                                                                    return (
                                                                        <div className='card23 mb-4 mt-2'

                                                                            key={index}>
                                                                            <div className="row mt-4">
                                                                                <div className="col-md-6">
                                                                                    <h6>DayName</h6>
                                                                                    <p>{items?.dayName}</p>

                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <h6>WorkingHours</h6>
                                                                                    <p>{items?.workingHours}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>





                                </div>
                                <div className='mt-3 mb-4'>
                                    <h4>Service Banner Image</h4>
                                    <div>
                                        <img

                                            src={`${process.env.REACT_APP_IMAGE_URL}/${datas1?.serviceThumbImage}`}
                                            className='card-image-box1'
                                            alt="no image" />
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <h4>Service Images</h4>
                                    <Row>
                                        {datas1?.serviceImages?.map((item1, index) => {
                                            return (
                                                <div className='card col-lg-2' key={index}>
                                                    <img

                                                        src={`${process.env.REACT_APP_IMAGE_URL}/${item1?.name}`}
                                                        className='card-image-box'
                                                        alt="no image" />
                                                </div>
                                            )
                                        })}
                                    </Row>


                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </>

        </div>
    )
}

export default Serviceview;