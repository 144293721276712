import { HttpInterceptor } from "../auth/http-interceptors";
export function CreateService(data) {
    return HttpInterceptor
        .post(`/seller/create-service`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function CreateServiceVariations(data) {
    return HttpInterceptor
        .post(`/seller/create-service-variations`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function CreateServiceUploadimages(data) {
    return HttpInterceptor
        .post(`/seller/update-service`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function Getservicelist(id, filterstateid) {
    return HttpInterceptor
        .get(`/seller/services-list/${id}/?sortBy=_id:desc&&stateId=${filterstateid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

// serach services

export function ServicesSearch(id, searchName) {
    return HttpInterceptor
        .get(`/seller/services-list/${id}/?sortBy=_id:desc&&search=${searchName}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}


export function GetserviceFilterPagination(id, current) {
    return HttpInterceptor
        .get(`/seller/services-list/${id}?sortBy=_id:desc&page=${current}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function Getservicevariationlist(id) {
    return HttpInterceptor
        .get(`/seller/service-variations-list/${id}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function GetSingleServiceList(id) {
    return HttpInterceptor.get(`/seller/service-details/${id}`).then((res) => {
        return res;
    })
        .catch((err) => console.log(err));
}





export function DeleteService(data) {
    return HttpInterceptor
        .post(`/seller/create-service`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}