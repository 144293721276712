import React, { memo, Fragment, useEffect, useState } from "react";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import Card from "../../../components/bootstrap/card";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./styles/Profileuser.scss";
import {
  SocialUpdateUser,
  UserConfirmPasswordChange,
  UserProfileBannerUpload,
  UserProfileImageUpload,
  userProfileInformation,
  UserProfileUpdateData,
} from "../../../services/profile_user_services/profile_user_services";
import Spinners from "../../../loadspinner/LoadSpinner";
import {
  toastchangepassword,
  toastimageupload,
  toastsocialprofile,
  toastuserprofile,
} from "../../../toastcontents/ToastContent";
import * as yup from "yup";
import { useFormik } from "formik";
import { useOutletContext } from "react-router-dom";

import uploadbannerlogo from '../../../assets/womeynlogos/upload-image-banner.png';

const Profileuser = memo((props) => {
  const [paiduser, setPaidUser] = useState("");

  const userId = localStorage.getItem("ispaid");
  const [loading, setLoading] = useState(false);
  const { userInfo } = useOutletContext();

  const [showuploadbutton, setShowUploadButton] = useState(false);
  const [loadingsocial, setLoadingSocial] = useState(false);
  const [loadingprofile, setLoadingProfile] = useState(false);
  const [loadingpassword, setLoadingPassword] = useState(false);
  const [showicon, setShowicon] = useState(false);
  const [showicon1, setShowicon1] = useState(false);
  const [showicon2, setShowicon2] = useState(false);
  const [imageuploads, setImageUpload] = useState("");

  const [uploadbannerimageloader, setUploadBannerLoader] = useState(false);
  const imageperson =
    "https://sialifehospital.com/wp-content/uploads/2021/04/testimonial-1.png";
  const history = useNavigate();
  const handleSocialSubmit = (values) => {
    setLoadingSocial(true);
    SocialUpdateUser(userInfo.id, values)
      .then((res) => {
        toast.success(toastsocialprofile);
        setLoadingSocial(false);
      })
      .catch((err) => console.log(err));
  };
  const formikUserSocialProfileDetails = useFormik({
    initialValues: {
      facebookUrl: userInfo.facebookUrl ? userInfo.facebookUrl : "",
      twitterUrl: userInfo.twitterUrl ? userInfo.twitterUrl : "",
      instagramUrl: userInfo.instagramUrl ? userInfo.instagramUrl : "",
      linkedinUrl: userInfo.linkedinUrl ? userInfo.linkedinUrl : "",
      youtubeUrl: userInfo.youtubeUrl ? userInfo.youtubeUrl : "",
    },
    onSubmit: (values) => {
      handleSocialSubmit(values);
    },
    validationSchema: yup.object({
      facebookUrl: yup.string().url("Provide valid website address"),
      twitterUrl: yup.string().url("Provide valid website address"),
      instagramUrl: yup.string().url("Provide valid website address"),
      linkedinUrl: yup.string().url("Provide valid website address"),
      youtubeUrl: yup.string().url("Provide valid website address"),
    }),
  });
  const formikUserProfileDetails = useFormik({
    initialValues: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      contactNumber: userInfo.contactNumber,
      alternateContactNumber: userInfo.alternateContactNumber,
      gender: userInfo.gender,
      email: userInfo.email,
    },
    onSubmit: (values) => {
      handleUserProfileSubmit(values);
    },
    validationSchema: yup.object({
      firstName: yup.string().required("First Name is Required !"),
      lastName: yup.string().required("Last Name is Required !"),
      contactNumber: yup.number().required("Contact Number is Requi1red !"),
      gender: yup.string().required("Gender is Requi1red !"),
    }),
  });
  const formikPasswordUpdate = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: (values) => {
      handleSubmitChangePassword(values);
    },
    validationSchema: yup.object({
      oldPassword: yup
        .string()
        .required("Old Password is Required !")
        .min(8, "Must be 8 Digits"),
      newPassword: yup
        .string()
        .required("New Password is Required !")
        .min(8, "Must be 8 Digits"),
      confirmNewPassword: yup
        .string()
        .required("Confirm New Password")
        .min(8, "Must be 8 Digits"),
    }),
  });

  const handleUserProfileSubmit = (values) => {
    const datas = {
      firstName: values.firstName,
      lastName: values.lastName,
      contactNumber: values.contactNumber,
      alternateContactNumber: values.alternateContactNumber,
      gender: values.gender,
    };
    setLoadingProfile(true);
    UserProfileUpdateData(userInfo.id, datas)
      .then((res) => {
        toast.success(toastuserprofile);
        //
        setLoadingProfile(false);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitChangePassword = (values) => {
    setLoadingPassword(true);
    UserConfirmPasswordChange(values)
      .then((res) => {
        if (res) {
          setLoadingPassword(false);
          toast.success(toastchangepassword);
          localStorage.removeItem("auth");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userdetails");
          localStorage.removeItem("userid");
          setTimeout(() => {
            history("/");
          }, 1500);
        }
      })
      .catch((err) => {
        setLoadingPassword(false);
        toast.error(err.message);
      });
  };

  const handleSubmitImgaes = (e) => {

    if (e.target.files[0].size > 4000) {
      setImageUpload(e.target.files[0]);
    } else {
      toast.error("Your image is Not support width and height");
    }
  };
  const uploadImages = () => {
    const formData = new FormData();
    formData.append("upl", imageuploads);
    UserProfileImageUpload(userInfo.id, formData)
      .then((res) => {
        toast.success("Profile image added successfully");
        setTimeout(() => {
          setShowUploadButton(true);
        }, 800);
      })
      .catch((err) => console.log(err));
    setShowUploadButton(false);
  };

  const ChangePlan = () => {
    setLoading(true);
    setTimeout(() => {
      history("/womeyn/subscribe/user");
      setLoading(false);
    }, 1000);
  };
  // upload banner image state
  const [uploadbannerimage, setUploadBannerimage] = useState(null);
  const [uploadbannerimage1, setUploadBannerimage1] = useState(userInfo?.bannerImage);

  const uploadBannerImage = async () => {
    setUploadBannerLoader(true);
    try {
      if (uploadbannerimage?.length === 0) {
        toast.error("Please Upload Banner Image")
      }
      else {
        const uploadimage = new FormData();
        uploadimage.append("upl", uploadbannerimage);
        const response = await UserProfileBannerUpload(uploadimage);
        if (response) {
          toast.success("Sucessfully Upload Banner Image");
          setTimeout(() => {
            setUploadBannerLoader(false);

          }, 800);
        }
      }
    } catch (error) {
      console.log(error);
      setUploadBannerLoader(false);
    }
  }

  const handleChangeBnnaerimage = async (e) => {
    if (e.target.files[0]) {
      setUploadBannerimage(e.target.files[0]);
    }
  }
  return (
    <Fragment>
      <Row>
        <Col xl="4" lg="4" className="">
          <Card style={{ padding: "0px" }}>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  {userInfo?.firstName} {userInfo?.lastName}
                </h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="mb-2 text-danger">
                Image Size Maximum Dimensions are 282 x 350 pixels.
              </div>
              <div className="main-choose-image-section mb-5 mt-2">
                <div>
                  <div className="main-image-upload-section">

                    <label htmlFor="upload-button">
                      <div className="box-image-upload">
                        {imageuploads === null && (
                          <div>
                            <img
                              src={imageperson ? imageperson : "https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo="}
                              alt="profile-pic"
                              width={"110px"}
                              height={"110px"}
                              style={{
                                borderRadius: "50%", cursor: "pointer", objectFit: "cover",
                                border: "3px solid #e95a5a"
                              }}
                            />
                          </div>
                        )}
                        {imageuploads ? (
                          <div>
                            <>
                              <img
                                alt="profile-pic"
                                width={"110px"}
                                height={"110px"}
                                style={{
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  objectFit: "cover",
                                  border: "3px solid #e95a5a"
                                }}
                                src={imageuploads ? URL.createObjectURL(imageuploads) : "https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo="}
                              />

                              {showuploadbutton ? (
                                <></>
                              ) : (
                                <>
                                  <div className="mt-2">
                                    <Button
                                      onClick={uploadImages}
                                      className="upload-btn"
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                </>
                              )}
                            </>
                          </div>
                        ) : (
                          <>
                            {userInfo.profileImageName ? (
                              <>

                                <img
                                  width={"110px"}
                                  height={"110px"}
                                  style={{
                                    borderRadius: "50%",
                                    cursor: "pointer",
                                    objectFit: "cover",
                                    border: "3px solid #e95a5a"
                                  }}
                                  src={`${process.env.REACT_APP_IMAGE_URL}/${userInfo.profileImageName}`}
                                  alt="profile-pic"
                                />
                              </>
                            ) : (
                              <Image
                                className="theme-color-default-img cursor profile-pic rounded avatar-100"
                                src="https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo="
                                alt="profile-pic"
                              />
                            )}
                          </>
                        )}
                        {userInfo.profileImageName ? <>

                          <div className="upload-image-icons">
                            <svg
                              className="upload-image-icons p-1"
                              width="22"
                              height="25"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                              />
                            </svg>
                          </div>
                        </> : null}

                      </div>
                    </label>
                  </div>
                  <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={handleSubmitImgaes}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="mt-5 social-app-urls">
                <Form
                  onSubmit={formikUserSocialProfileDetails.handleSubmit}
                  validateOnChange={true}
                >
                  <Form.Group className="form-group">
                    <Form.Label>Facebook Url:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Facebook Url"
                      id="facebookUrl"
                      name="facebookUrl"
                      value={formikUserSocialProfileDetails.values.facebookUrl}
                      onChange={formikUserSocialProfileDetails.handleChange}
                      onBlur={formikUserSocialProfileDetails.handleBlur}
                    />

                    {formikUserSocialProfileDetails.errors.facebookUrl &&
                      formikUserSocialProfileDetails.touched.facebookUrl ? (
                      <div className="">
                        <span className="error-message">
                          {formikUserSocialProfileDetails.errors.facebookUrl}
                        </span>
                      </div>
                    ) : formikUserSocialProfileDetails.touched.facebookUrl &&
                      formikUserSocialProfileDetails.values.facebookUrl ? (
                      <div className="text-success">{/* Looks Good! 😎 */}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="form-group mt-4">
                    <Form.Label>Twitter Url:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Twitter Url"
                      name="twitterUrl"
                      value={formikUserSocialProfileDetails.values.twitterUrl}
                      onChange={formikUserSocialProfileDetails.handleChange}
                      onBlur={formikUserSocialProfileDetails.handleBlur}
                    />
                    {formikUserSocialProfileDetails.errors.twitterUrl &&
                      formikUserSocialProfileDetails.touched.twitterUrl ? (
                      <div className="">
                        <span className="error-message">
                          {formikUserSocialProfileDetails.errors.twitterUrl}
                        </span>
                      </div>
                    ) : formikUserSocialProfileDetails.touched.twitterUrl &&
                      formikUserSocialProfileDetails.values.twitterUrl ? (
                      <div className="text-success">{/* Looks Good! 😎 */}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="form-group mt-4">
                    <Form.Label>Instagram Url:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Instagram Url"
                      name="instagramUrl"
                      value={formikUserSocialProfileDetails.values.instagramUrl}
                      onChange={formikUserSocialProfileDetails.handleChange}
                      onBlur={formikUserSocialProfileDetails.handleBlur}
                    />
                    {formikUserSocialProfileDetails.errors.instagramUrl &&
                      formikUserSocialProfileDetails.touched.instagramUrl ? (
                      <div className="">
                        <span className="error-message">
                          {formikUserSocialProfileDetails.errors.instagramUrl}
                        </span>
                      </div>
                    ) : formikUserSocialProfileDetails.touched.instagramUrl &&
                      formikUserSocialProfileDetails.values.instagramUrl ? (
                      <div className="text-success">{/* Looks Good! 😎 */}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="mb-0 form-group mt-4">
                    <Form.Label>Linkedin Url:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Linkedin Url"
                      name="linkedinUrl"
                      value={formikUserSocialProfileDetails.values.linkedinUrl}
                      onChange={formikUserSocialProfileDetails.handleChange}
                      onBlur={formikUserSocialProfileDetails.handleBlur}
                    />
                    {formikUserSocialProfileDetails.errors.linkedinUrl &&
                      formikUserSocialProfileDetails.touched.linkedinUrl ? (
                      <div className="">
                        <span className="error-message">
                          {formikUserSocialProfileDetails.errors.linkedinUrl}
                        </span>
                      </div>
                    ) : formikUserSocialProfileDetails.touched.linkedinUrl &&
                      formikUserSocialProfileDetails.values.linkedinUrl ? (
                      <div className="text-success">{/* Looks Good! 😎 */}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group className="mb-0 form-group mt-4">
                    <Form.Label>Youtube Url:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Youtube Url"
                      name="youtubeUrl"
                      value={formikUserSocialProfileDetails.values.youtubeUrl}
                      onChange={formikUserSocialProfileDetails.handleChange}
                      onBlur={formikUserSocialProfileDetails.handleBlur}
                    />
                    {formikUserSocialProfileDetails.errors.youtubeUrl &&
                      formikUserSocialProfileDetails.touched.youtubeUrl ? (
                      <div className="">
                        <span className="error-message">
                          {formikUserSocialProfileDetails.errors.youtubeUrl}
                        </span>
                      </div>
                    ) : formikUserSocialProfileDetails.touched.youtubeUrl &&
                      formikUserSocialProfileDetails.values.youtubeUrl ? (
                      <div className="text-success">{/* Looks Good! 😎 */}</div>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <div className="d-flex justify-content-end mb-1 mt-5">
                    <Button
                      type="submit"
                      className="upload-btn"
                      variant="btn btn-primary"
                    >
                      {loadingsocial ? (
                        <>
                          <Spinners />
                          <span className="ms-2">Loading...</span>
                        </>
                      ) : (
                        <>Update</>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
          <div>
            <Card>
              <div className="mb-2 text-danger">
                Image Size Maximum Dimensions are 1127 x 177 pixels.
              </div>
              <Card.Body>
                {uploadbannerimage1 || uploadbannerimage ? <></> : <>
                  <input type="file" id="upload-banner" style={{ display: "none" }}
                    onChange={handleChangeBnnaerimage}
                    accept="/*"
                  />
                  <label htmlFor="upload-banner" className="d-flex gap-1 align-items-center" style={{ cursor: "pointer" }}>
                    <div style={{ position: "relative" }}>
                      <img
                        src={`https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo=`}
                        alt="no image"
                        className="upload-banner-images"
                      />
                      <div style={{
                        position: "absolute",
                        top: "-15px",
                        right: "-5px"
                      }}>
                        <svg
                          className="upload-image-icons p-1"
                          width="22"
                          height="25"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffffff"
                            d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </label>
                </>}
                <div className="banner-image-box">
                  {uploadbannerimage ? <>
                    <img src={uploadbannerimage ? URL.createObjectURL(uploadbannerimage) : 'https://media.istockphoto.com/id/1371904339/vector/young-smiling-woman-jane-peeking-out-and-looking-from-behind-round-hole-searching-concept-3d.jpg?s=612x612&w=0&k=20&c=8WEdrO7y4nlUHH9CRu9lKmlspgKtJK_mBAS9tqgMxlo='} alt="no image"
                      className="upload-banner-images"
                    />
                  </> : <>
                    {uploadbannerimage1 ? <>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_URL}/${uploadbannerimage1}`}
                        alt="no image"
                        className="upload-banner-images"
                      />
                    </> : <>
                    </>}

                  </>}
                  <div className="edit-icon-banner">
                    {uploadbannerimage1 || uploadbannerimage ? <>
                      <input type="file" id="upload-banner" style={{ display: "none" }}
                        onChange={handleChangeBnnaerimage}
                        accept="/*"
                      />
                      <label htmlFor="upload-banner" className="d-flex gap-1 align-items-center" style={{ cursor: "pointer" }}>
                        <svg
                          className="upload-image-icons p-1"
                          width="22"
                          height="25"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#ffffff"
                            d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                          />
                        </svg>
                      </label>
                    </> : <>
                    </>}
                  </div>
                </div>
                <div className="mt-3">
                  {uploadbannerimage ? <div className="mt-4 mb-4 d-flex align-items-center justify-content-center" onClick={uploadBannerImage}>
                    <Button> {uploadbannerimageloader ? "loading..." : "Upload Banner Image"}</Button>
                  </div> : null}
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col xl="8" lg="8">
          <Card style={{ padding: "0px" }}>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Profile Information</h4>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="new-user-info">
                <Form onSubmit={formikUserProfileDetails.handleSubmit}>
                  <div className="row">
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="fname">First Name:</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={formikUserProfileDetails.values.firstName}
                        placeholder="First Name"
                        onChange={formikUserProfileDetails.handleChange}
                      />
                      <div>
                        <div className="">
                          {formikUserProfileDetails.errors.firstName &&
                            formikUserProfileDetails.touched.firstName ? (
                            <span className="error-message">
                              {formikUserProfileDetails.errors.firstName}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div></div>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group">
                      <Form.Label htmlFor="lname">Last Name:</Form.Label>
                      <Form.Control
                        type="text"
                        id="lname"
                        placeholder="Last Name"
                        name="lastName"
                        value={formikUserProfileDetails.values.lastName}
                        onChange={formikUserProfileDetails.handleChange}
                      />
                      <div className="">
                        {formikUserProfileDetails.errors.lastName &&
                          formikUserProfileDetails.touched.lastName ? (
                          <span className="error-message">
                            {formikUserProfileDetails.errors.lastName}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div></div>
                    </Form.Group>

                    <Form.Group className="col-sm-12 form-group">
                      <Form.Label>Gender:</Form.Label>
                      <Form.Select
                        id="validationDefault04"
                        name="gender"
                        value={formikUserProfileDetails.values.gender}
                        onChange={formikUserProfileDetails.handleChange}
                        required
                      >
                        <option label="Male" value="male">
                          Male
                        </option>
                        <option label="Female" value="female">
                          Female
                        </option>
                      </Form.Select>
                      <div className="">
                        {formikUserProfileDetails.errors.gender &&
                          formikUserProfileDetails.touched.gender ? (
                          <span className="error-message">
                            {formikUserProfileDetails.errors.gender}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div></div>
                    </Form.Group>
                    <Form.Group className="col-md-6  form-group">
                      <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                      <div className="mobile-number-section">
                        <div className="number-section-change">+61</div>
                        <Form.Control
                          type="text"
                          id="mobno"
                          name="contactNumber"
                          value={formikUserProfileDetails.values.contactNumber}
                          placeholder="Mobile Number"
                          onChange={formikUserProfileDetails.handleChange}
                          maxLength={9}

                        />

                        <div className="">
                          {formikUserProfileDetails.errors.contactNumber &&
                            formikUserProfileDetails.touched.contactNumber ? (
                            <span className="error-message">
                              {formikUserProfileDetails.errors.contactNumber}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Form.Group>
                    <Form.Group className="col-md-6  form-group">
                      <Form.Label htmlFor="altconno">
                        Alternate Contact:
                      </Form.Label>

                      <div className="mobile-number-section">
                        <div className="number-section-change">+61</div>
                        <Form.Control
                          type="text"
                          id="altconno"
                          name="alternateContactNumber"
                          value={
                            formikUserProfileDetails.values.alternateContactNumber
                          }
                          onChange={formikUserProfileDetails.handleChange}
                          placeholder="Mobile Number"
                          maxLength={9}

                        />

                        <div className="">
                          {formikUserProfileDetails.errors.contactNumber &&
                            formikUserProfileDetails.touched.contactNumber ? (
                            <span className="error-message">
                              {formikUserProfileDetails.errors.contactNumber}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div></div>
                    </Form.Group>
                    <Form.Group className="col-md-6  form-group">
                      <Form.Label htmlFor="email">Email:</Form.Label>
                      <Form.Control
                        type="email"
                        id="email"
                        name="email"
                        value={formikUserProfileDetails.values.email}
                        placeholder="Email"
                        disabled
                        onChange={formikUserProfileDetails.handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="btn btn-primary"
                      type="submit"
                      className="upload-btn"
                    >
                      {loadingprofile ? (
                        <>
                          <Spinners />
                          <span className="ms-2">Loading...</span>
                        </>
                      ) : (
                        <> Update</>
                      )}
                    </Button>
                  </div>
                </Form>
                <hr />

                <div className="mt-4">
                  <h4 className="mb-4 mt-3">Plan Details</h4>

                  {userInfo?.categoryTypeId === 0 && (
                    <Row>
                      <Col>
                        <div className="d-flex gap-3 align-items-center">

                          <span className="plannames">Plan Name :</span>
                          <span className="plannamecolor">


                            {userInfo?.planName}
                          </span>



                        </div>
                      </Col>

                      <Col>
                        <div className="d-flex gap-3 align-items-center">

                          <span className="plannames">Available Products Count :</span>
                          <span className="plannamecolor">


                            {userInfo?.availableProductsCount}
                          </span>

                        </div>
                      </Col>

                      <div className="mt-4 mb-4">
                        <div className="d-flex gap-3 align-items-center">

                          <span className="plannames">Available Services Count :</span>
                          <span className="plannamecolor">


                            {userInfo?.availableServicesCount}
                          </span>

                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button
                          className="upload-btn"
                          variant="btn btn-primary"
                          onClick={ChangePlan}
                        >
                          {loading ? (
                            <>
                              <Spinners />
                              <span className="ms-2">Loading...</span>
                            </>
                          ) : (
                            <>Change Plan</>
                          )}
                        </Button>
                      </div>
                    </Row>
                  )}

                  {userInfo?.categoryTypeId === 2 && (
                    <>
                      <Row>



                        <div className="mt-4 mb-4">
                          <div className="d-flex gap-3 align-items-center">
                            <div>
                              <h5> Subscribe Service Categories :</h5>
                            </div>
                            <div>
                              <h6> {userInfo?.availableServicesCount}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="upload-btn"
                            variant="btn btn-primary"
                            onClick={ChangePlan}
                          >
                            {loading ? (
                              <>
                                <Spinners />
                                <span className="ms-2">Loading...</span>
                              </>
                            ) : (
                              <>Change Plan</>
                            )}
                          </Button>
                        </div>
                      </Row>
                    </>
                  )}

                  {userInfo?.categoryTypeId === 1 && (
                    <>
                      <Row>
                        <Col>
                          <div className="d-flex gap-3 align-items-center">
                            <div>
                              <h5>Plan Name :</h5>
                            </div>

                            <div>


                              {userInfo?.planName}

                            </div>
                          </div>
                        </Col>

                        <Col>
                          <div className="d-flex gap-3 align-items-center">
                            <div>
                              <h5>Available Products Count :</h5>
                            </div>
                            <div>
                              <h6> {userInfo?.availableProductsCount}</h6>
                            </div>
                          </div>
                        </Col>


                        <div className="d-flex justify-content-end">
                          <Button
                            className="upload-btn"
                            variant="btn btn-primary"
                            onClick={ChangePlan}
                          >
                            {loading ? (
                              <>
                                <Spinners />
                                <span className="ms-2">Loading...</span>
                              </>
                            ) : (
                              <>Change Plan</>
                            )}
                          </Button>
                        </div>
                      </Row>
                    </>
                  )}
                </div>

                <hr />
                <Form onSubmit={formikPasswordUpdate.handleSubmit}>
                  <div className="mt-5">
                    <h5 className="mb-4 mt-3">Change Password</h5>
                    <div className="row">
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="uname">Email</Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          value={userInfo.email}
                          placeholder="User Email"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="opass">Old Password</Form.Label>
                        <div className="password-section-icons">
                          <Form.Control
                            type={showicon ? "text" : "password"}
                            name="oldPassword"
                            value={formikPasswordUpdate.values.oldPassword}
                            onChange={formikPasswordUpdate.handleChange}
                            placeholder="Old Password"
                          />
                          <div className="passsword-svg mt-2">
                            {showicon ? (
                              <svg
                                width="20"
                                onClick={() => setShowicon(!showicon)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                width="20"
                                onClick={() => setShowicon(!showicon)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.80327 15.2526C10.4277 15.6759 11.1888 15.9319 11.9987 15.9319C14.1453 15.9319 15.8919 14.1696 15.8919 12.0037C15.8919 11.1866 15.6382 10.4186 15.2186 9.78855L14.1551 10.8617C14.3307 11.1964 14.4283 11.5902 14.4283 12.0037C14.4283 13.3525 13.3354 14.4551 11.9987 14.4551C11.5889 14.4551 11.1986 14.3567 10.8668 14.1795L9.80327 15.2526ZM18.4288 6.54952C19.8436 7.84907 21.0438 9.60149 21.9415 11.7083C22.0195 11.8954 22.0195 12.112 21.9415 12.2892C19.8534 17.1921 16.1358 20.1259 11.9987 20.1259H11.9889C10.1058 20.1259 8.30063 19.5056 6.71018 18.3735L4.81725 20.2834C4.67089 20.4311 4.4855 20.5 4.30011 20.5C4.11472 20.5 3.91957 20.4311 3.78297 20.2834C3.53903 20.0373 3.5 19.6435 3.69515 19.358L3.72442 19.3186L18.1556 4.75771C18.1751 4.73802 18.1946 4.71833 18.2044 4.69864L18.2044 4.69863C18.2239 4.67894 18.2434 4.65925 18.2532 4.63957L19.1704 3.71413C19.4631 3.42862 19.9217 3.42862 20.2046 3.71413C20.4974 3.99964 20.4974 4.4722 20.2046 4.75771L18.4288 6.54952ZM8.09836 12.0075C8.09836 12.2635 8.12764 12.5195 8.16667 12.7558L4.55643 16.3984C3.5807 15.2564 2.7318 13.8781 2.05854 12.293C1.98049 12.1158 1.98049 11.8992 2.05854 11.7122C4.14662 6.80933 7.86419 3.88534 11.9916 3.88534H12.0013C13.3966 3.88534 14.7529 4.22007 16.0018 4.85015L12.7429 8.13841C12.5087 8.09903 12.255 8.0695 12.0013 8.0695C9.84494 8.0695 8.09836 9.83177 8.09836 12.0075Z"
                                  fill="currentColor"
                                ></path>{" "}
                              </svg>
                            )}
                          </div>
                        </div>
                        <div>
                          {formikPasswordUpdate.errors.oldPassword &&
                            formikPasswordUpdate.touched.oldPassword ? (
                            <span className="error-message">
                              {formikPasswordUpdate.errors.oldPassword}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="pass">New Password</Form.Label>
                        <div className="password-section-icons">
                          <Form.Control
                            type={showicon1 ? "text" : "password"}
                            placeholder="New Password"
                            name="newPassword"
                            value={formikPasswordUpdate.values.newPassword}
                            onChange={formikPasswordUpdate.handleChange}
                          />
                          <div className="passsword-svg mt-2">
                            {showicon1 ? (
                              <svg
                                width="20"
                                onClick={() => setShowicon1(!showicon1)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                width="20"
                                onClick={() => setShowicon1(!showicon1)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.80327 15.2526C10.4277 15.6759 11.1888 15.9319 11.9987 15.9319C14.1453 15.9319 15.8919 14.1696 15.8919 12.0037C15.8919 11.1866 15.6382 10.4186 15.2186 9.78855L14.1551 10.8617C14.3307 11.1964 14.4283 11.5902 14.4283 12.0037C14.4283 13.3525 13.3354 14.4551 11.9987 14.4551C11.5889 14.4551 11.1986 14.3567 10.8668 14.1795L9.80327 15.2526ZM18.4288 6.54952C19.8436 7.84907 21.0438 9.60149 21.9415 11.7083C22.0195 11.8954 22.0195 12.112 21.9415 12.2892C19.8534 17.1921 16.1358 20.1259 11.9987 20.1259H11.9889C10.1058 20.1259 8.30063 19.5056 6.71018 18.3735L4.81725 20.2834C4.67089 20.4311 4.4855 20.5 4.30011 20.5C4.11472 20.5 3.91957 20.4311 3.78297 20.2834C3.53903 20.0373 3.5 19.6435 3.69515 19.358L3.72442 19.3186L18.1556 4.75771C18.1751 4.73802 18.1946 4.71833 18.2044 4.69864L18.2044 4.69863C18.2239 4.67894 18.2434 4.65925 18.2532 4.63957L19.1704 3.71413C19.4631 3.42862 19.9217 3.42862 20.2046 3.71413C20.4974 3.99964 20.4974 4.4722 20.2046 4.75771L18.4288 6.54952ZM8.09836 12.0075C8.09836 12.2635 8.12764 12.5195 8.16667 12.7558L4.55643 16.3984C3.5807 15.2564 2.7318 13.8781 2.05854 12.293C1.98049 12.1158 1.98049 11.8992 2.05854 11.7122C4.14662 6.80933 7.86419 3.88534 11.9916 3.88534H12.0013C13.3966 3.88534 14.7529 4.22007 16.0018 4.85015L12.7429 8.13841C12.5087 8.09903 12.255 8.0695 12.0013 8.0695C9.84494 8.0695 8.09836 9.83177 8.09836 12.0075Z"
                                  fill="currentColor"
                                ></path>{" "}
                              </svg>
                            )}
                          </div>
                        </div>
                        <div>
                          {formikPasswordUpdate.errors.newPassword &&
                            formikPasswordUpdate.touched.newPassword ? (
                            <span className="error-message">
                              {formikPasswordUpdate.errors.newPassword}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Group>
                      <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="rpass">
                          Confirm Password
                        </Form.Label>
                        <div className="password-section-icons">
                          <Form.Control
                            type={showicon2 ? "text" : "password"}
                            id="confirmNewPassword"
                            placeholder="Confirm Password"
                            name="confirmNewPassword"
                            value={
                              formikPasswordUpdate.values.confirmNewPassword
                            }
                            onChange={formikPasswordUpdate.handleChange}
                          />
                          <div className="passsword-svg mt-2">
                            {showicon2 ? (
                              <svg
                                width="20"
                                onClick={() => setShowicon2(!showicon2)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                width="20"
                                onClick={() => setShowicon2(!showicon2)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.80327 15.2526C10.4277 15.6759 11.1888 15.9319 11.9987 15.9319C14.1453 15.9319 15.8919 14.1696 15.8919 12.0037C15.8919 11.1866 15.6382 10.4186 15.2186 9.78855L14.1551 10.8617C14.3307 11.1964 14.4283 11.5902 14.4283 12.0037C14.4283 13.3525 13.3354 14.4551 11.9987 14.4551C11.5889 14.4551 11.1986 14.3567 10.8668 14.1795L9.80327 15.2526ZM18.4288 6.54952C19.8436 7.84907 21.0438 9.60149 21.9415 11.7083C22.0195 11.8954 22.0195 12.112 21.9415 12.2892C19.8534 17.1921 16.1358 20.1259 11.9987 20.1259H11.9889C10.1058 20.1259 8.30063 19.5056 6.71018 18.3735L4.81725 20.2834C4.67089 20.4311 4.4855 20.5 4.30011 20.5C4.11472 20.5 3.91957 20.4311 3.78297 20.2834C3.53903 20.0373 3.5 19.6435 3.69515 19.358L3.72442 19.3186L18.1556 4.75771C18.1751 4.73802 18.1946 4.71833 18.2044 4.69864L18.2044 4.69863C18.2239 4.67894 18.2434 4.65925 18.2532 4.63957L19.1704 3.71413C19.4631 3.42862 19.9217 3.42862 20.2046 3.71413C20.4974 3.99964 20.4974 4.4722 20.2046 4.75771L18.4288 6.54952ZM8.09836 12.0075C8.09836 12.2635 8.12764 12.5195 8.16667 12.7558L4.55643 16.3984C3.5807 15.2564 2.7318 13.8781 2.05854 12.293C1.98049 12.1158 1.98049 11.8992 2.05854 11.7122C4.14662 6.80933 7.86419 3.88534 11.9916 3.88534H12.0013C13.3966 3.88534 14.7529 4.22007 16.0018 4.85015L12.7429 8.13841C12.5087 8.09903 12.255 8.0695 12.0013 8.0695C9.84494 8.0695 8.09836 9.83177 8.09836 12.0075Z"
                                  fill="currentColor"
                                ></path>{" "}
                              </svg>
                            )}
                          </div>
                        </div>
                        <div>
                          {formikPasswordUpdate.errors.confirmNewPassword &&
                            formikPasswordUpdate.touched.confirmNewPassword ? (
                            <span className="error-message">
                              {formikPasswordUpdate.errors.confirmNewPassword}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end  mt-2">
                    <Button
                      className="upload-btn"
                      type="submit"
                      variant="btn btn-primary"
                    >
                      {loadingpassword ? (
                        <>
                          <Spinners />
                          <span className="ms-2">Loading...</span>
                        </>
                      ) : (
                        <>Update</>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

Profileuser.displayName = "Profileuser";
export default Profileuser;
