import React, { useEffect, useState, useRef } from 'react'
import { toast } from "react-toastify";


let autoComplete;
function Democheck() {

    const [query, setQuery] = useState("");

    const autoCompleteRef = useRef(null);

    function initMap() {
        const map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: 40.749933, lng: -73.98633 },
            zoom: 13,
            mapTypeControl: false,
        });
        const card = document.getElementById("pac-card");
        const input = document.getElementById("pac-input");

        const biasInputElement = document.getElementById("use-location-bias");
        const strictBoundsInputElement = document.getElementById("use-strict-bounds");
        const options = {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            types: ["establishment"],
        };

        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(card);

        const autocomplete = new window.google.maps.places.Autocomplete( autoCompleteRef.current, options);

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo("bounds", map);

        const infowindow = new window.google.maps.InfoWindow();
        const infowindowContent = document.getElementById("infowindow-content");

        infowindow.setContent(infowindowContent);

        const marker = new window.google.maps.Marker({
            map,
            anchorPoint: new window.google.maps.Point(0, -29),
        });

        autocomplete.addListener("place_changed", () => {
            infowindow.close();
            marker.setVisible(false);

            const place = autocomplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);
            }

            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
            infowindowContent.children["place-name"].textContent = place.name;
            infowindowContent.children["place-address"].textContent =
                place.formatted_address;
            infowindow.open(map, marker);
        });

        // Sets a listener on a radio button to change the filter type on Places
        // Autocomplete.
        function setupClickListener(id, types) {
            const radioButton = document.getElementById(id);

            radioButton.addEventListener("click", () => {
                autocomplete.setTypes(types);
                input.value = "";
            });
        }

        setupClickListener("changetype-all", []);
        setupClickListener("changetype-address", ["address"]);
        setupClickListener("changetype-establishment", ["establishment"]);
        setupClickListener("changetype-geocode", ["geocode"]);
        setupClickListener("changetype-cities", ["(cities)"]);
        setupClickListener("changetype-regions", ["(regions)"]);
        biasInputElement.addEventListener("change", () => {
            if (biasInputElement.checked) {
                autocomplete.bindTo("bounds", map);
            } else {
                // User wants to turn off location bias, so three things need to happen:
                // 1. Unbind from map
                // 2. Reset the bounds to whole world
                // 3. Uncheck the strict bounds checkbox UI (which also disables strict bounds)
                autocomplete.unbind("bounds");
                autocomplete.setBounds({ east: 180, west: -180, north: 90, south: -90 });
                strictBoundsInputElement.checked = biasInputElement.checked;
            }

            input.value = "";
        });
        strictBoundsInputElement.addEventListener("change", () => {
            autocomplete.setOptions({
                strictBounds: strictBoundsInputElement.checked,
            });
            if (strictBoundsInputElement.checked) {
                biasInputElement.checked = strictBoundsInputElement.checked;
                autocomplete.bindTo("bounds", map);
            }

            input.value = "";
        });
    }

    window.initMap = initMap;


    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (
                    script.readyState === "loaded" ||
                    script.readyState === "complete"
                ) {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    function handleScriptLoad(updateQuery, autoCompleteRef) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            // { types: ["postal_code"], componentRestrictions: { country: "AUS" } }

            { types: ["establishment"], componentRestrictions: { country: "AUS" }, strictBounds: false }

            // "establishment",
        );



        autoComplete.setFields([
            "address_components",
            "formatted_address",
            "geometry",
            "name"
        ]);
        // fields: ["formatted_address", "geometry", "name"],

        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );



    }
    const getAddressFromGoogle = (event) => {
        let value = event.target.value || " ";
        if (value?.length === 0) {
            toast.error("error post code")
        }
        if (value) {
            setQuery(value);



        }
    };
    async function handlePlaceSelect(updateQuery) {
        const addressObject = autoComplete.getPlace();
        const query = addressObject;
        updateQuery(query);

    }



    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );

        // loadScript(
        //   `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=places&v=weekly`,
        //   () => handleScriptLoad(setQuery, autoCompleteRef)
        // );

        // https://maps.googleapis.com/maps/api/js?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&callback=initMap&libraries=places&v=weekly

    }, []);
    return (
        <>
            <div class="pac-card" id="pac-card">
                <div>
                    <div id="title">Autocomplete search</div>
                    <div id="type-selector" class="pac-controls">
                        <input
                            type="radio"
                            name="type"
                            id="changetype-all"
                            checked="checked"
                        />
                        <label for="changetype-all">All</label>

                        <input type="radio" name="type" id="changetype-establishment" />
                        <label for="changetype-establishment">establishment</label>

                        <input type="radio" name="type" id="changetype-address" />
                        <label for="changetype-address">address</label>

                        <input type="radio" name="type" id="changetype-geocode" />
                        <label for="changetype-geocode">geocode</label>

                        <input type="radio" name="type" id="changetype-cities" />
                        <label for="changetype-cities">(cities)</label>

                        <input type="radio" name="type" id="changetype-regions" />
                        <label for="changetype-regions">(regions)</label>
                    </div>
                    <br />
                    <div id="strict-bounds-selector" class="pac-controls">
                        <input type="checkbox" id="use-location-bias" value="" checked />
                        <label for="use-location-bias">Bias to map viewport</label>

                        <input type="checkbox" id="use-strict-bounds" value="" />
                        <label for="use-strict-bounds">Strict bounds</label>
                    </div>
                </div>
                <div id="pac-container">
                    <input id="pac-input" type="text" placeholder="Enter a location" />
                </div>
            </div>
            <div id="map"></div>
            <div id="infowindow-content">
                <span id="place-name" class="title"></span><br />
                <span id="place-address"></span>
            </div>
            <div className="form-group">

                <input
                    type="text"
                    name="postelCode"
                    id="postalCode"
                    ref={autoCompleteRef}
                    // onChange={
                    //   getAddressFromGoogle ||
                    //   formikPersonalDetails.handleChange
                    // }
                    placeholder="Enter Post code"
                    value={
                        query
                    }
                    maxLength={5}
                />
                <div className="mt-2">
                    {/* {formikPersonalDetails.errors.postelCode &&
                              formikPersonalDetails.touched.postelCode ? (
                              <span className="error-message">
                                {formikPersonalDetails.errors.postelCode}
                              </span>
                            ) : (
                              ""
                            )} */}

                    {/* {personalscodeerror && <span className="text-danger mt-2">{autoCompleteRef?.current?.value?.length <= 2 ? "Post Code Required" : null}</span>} */}
                </div>
            </div>
        </>
    )
}

export default Democheck