import React from 'react'
import './styles/Paegnotfound.scss';
import pagenotfound from '../../../assets/loginLogos/pagenotfound1.gif';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
function PageNotFound() {
    const history = useNavigate();
    return (
        <div className='page-not'>
            <img src={pagenotfound} alt='no image' className='not-image' />
            <div>
                <Button className='back-login' onClick={() => history(-1)}>Back to Page</Button>
            </div>
        </div>
    )
}

export default PageNotFound