export const variationsTimesam = [
    {
        id: 1,
        time: "1:00 AM"
    },
    {
        id: 2,
        time: "2:00 AM"

    },
    {
        id: 3,
        time: "3:00 AM"

    },
    {
        id: 4,
        time: "4:00 AM"

    },
    {
        id: 5,
        time: "5:00 AM"

    },
    {
        id: 6,
        time: "6:00 AM"

    },
    {
        id: 7,
        time: "7:00 AM"

    },
    {
        id: 8,
        time: "8:00 AM"

    },
    {
        id: 9,
        time: "9:00 AM"

    },
    {
        id: 10,
        time: "10:00 AM"

    },
    {
        id: 11,
        time: "11:00 AM"

    },
    {
        id: 12,
        time: "12:00 AM"

    },
    {
        id: 13,
        time: "1:00 PM"
    },
    {
        id: 14,
        time: "2:00 PM"

    },
    {
        id: 15,
        time: "3:00 PM"

    },
    {
        id: 16,
        time: "4:00 PM"

    },
    {
        id: 17,
        time: "5:00 PM"

    },
    {
        id: 18,
        time: "6:00 PM"

    },
    {
        id: 19,
        time: "7:00 PM"

    },
    {
        id: 20,
        time: "8:00 PM"

    },
    {
        id: 21,
        time: "9:00 PM"

    },
    {
        id: 22,
        time: "10:00 PM"

    },
    {
        id: 23,
        time: "11:00 PM"

    },
    {
        id: 24,
        time: "12:00 PM"

    },
];


export const WorkingDays = [
    {
        id: 1,
        name: "Sunday"
    },
    {
        id: 2,
        name: "Monday"
    },
    {
        id: 3,
        name: "Tuesday"
    },
    {
        id: 4,
        name: "Wednesday"
    },
    {
        id: 5,
        name: "Thursday"
    },
    {
        id: 6,
        name: "Friday"
    },
    {
        id: 7,
        name: "Saturday"
    }
]
