import { useState, memo, Fragment, useEffect } from "react";
import Card from "../../../../components/bootstrap/card";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import support_services from "../../../../services/support_services/support_services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddTickets = memo((props) => {
  let history = useNavigate();
  const [uploadingFiles, setUploadingFiles] = useState([]);

  const saveTicket = (data) => {
    support_services
      .saveTicket(data)
      .then(async (result) => {
        toast.success("Ticket Added Successful!!");
        setTimeout(() => {
          history("/womeyn/tickets");
        }, 1200);
        // if (uploadingFiles) {
        //     let imageUploaded = await uploadImages(result.id);
        //     console.log("imageUploaded", imageUploaded)
        //     toast.success("Ticket Added Successfully!!");
        //     setTimeout(() => {
        //         history("/womeyn/tickets");
        //     }, 1200);
        // } else {
        //     toast.success("Ticket Added Successfully!!");
        //     setTimeout(() => {
        //         history("/womeyn/tickets");
        //     }, 1200);
        // }
      })
      .catch((err) => {
        toast.error("Failed to add Ticket !");
        if (err) {
          throw err;
        }
      });
  };
  const goBack = () => {
    history("/womeyn/tickets");
  };
  const uploadImages = (messageId) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      if (uploadingFiles.length > 0) {
        for (let i = 0; i <= uploadingFiles.length; i++) {
          if (uploadingFiles[i]) {
            formData.append("upl", uploadingFiles[i]);
          }
          if (i === uploadingFiles.length - 1) {
            support_services
              .uploadChatImages(messageId, formData)
              .then((response) => {
                resolve(true);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      } else {
        resolve(true);
      }
    });
  };
  const formiTicketDetails = useFormik({
    initialValues: {
      category: "",
      subject: "",
      message: "",
    },
    onSubmit: (values) => {
      saveTicket(values);
    },
    validationSchema: yup.object({
      category: yup.string().required("Category is Required !"),
      subject: yup.string().required("Subject is Required !"),
      message: yup.string().required("Message  is Required !"),
    }),
  });
  const handleUploader = (e) => {
    setUploadingFiles((oldArray) => [...oldArray, ...e.target.files]);
  };
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between mt-3 mb-5 ms-2">
        <h3>Tickets</h3>
      </div>
      <div>
        <Card>
          <Card.Body className="m-0 p-0">
            <div>
              <div>
                <h3>Add New Ticket</h3>
              </div>
              <div className="mb-4 mt-4">
                <Form
                  onSubmit={formiTicketDetails.handleSubmit}
                  validateOnChange={false}
                >
                  <div>
                    <Row>
                      <Col lg="12">
                        <div className="form-group">
                          <Form.Label htmlFor="styleName">
                            Choose Category
                          </Form.Label>
                          <Form.Select
                            name="category"
                            onChange={formiTicketDetails.handleChange}
                            value={formiTicketDetails.values.category}
                          >
                            <option value="" selected disabled>
                              --- Please Choose One ---
                            </option>
                            <option value="Billing">Billing</option>
                            <option value="Products">Products</option>
                            <option value="Services">Services</option>
                            <option value="Others">Others...</option>

                          </Form.Select>
                          <div className="">
                            {formiTicketDetails.errors.category &&
                              formiTicketDetails.touched.category ? (
                              <span className="error-message">
                                {formiTicketDetails.errors.category}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg="12">
                        <div className="form-group">
                          <Form.Label htmlFor="styleName">Subject</Form.Label>
                          <Form.Control
                            type="text"
                            name="subject"
                            id="subject"
                            onChange={formiTicketDetails.handleChange}
                            value={formiTicketDetails.values.subject}
                            placeholder="Enter your Subject here"
                          />
                          <div className="">
                            {formiTicketDetails.errors.subject &&
                              formiTicketDetails.touched.subject ? (
                              <span className="error-message">
                                {formiTicketDetails.errors.subject}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg="12">
                        <div className="form-group">
                          <Form.Label htmlFor="styleName">Message</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={10}
                            name="message"
                            value={formiTicketDetails.values.message}
                            onChange={formiTicketDetails.handleChange}
                            placeholder="Enter your Message here"
                          />
                          <div className="">
                            {formiTicketDetails.errors.message &&
                              formiTicketDetails.touched.message ? (
                              <span className="error-message">
                                {formiTicketDetails.errors.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                      {/* <Col lg="12">
                                            <div className="form-group">
                                                <Form.Label htmlFor="styleName">Attachment</Form.Label>
                                                <>
                                                    <label htmlFor={"upload-button"}>
                                                        <span className="input-group-text mgs-icon attachment-icon">
                                                            Choose File
                                                        </span>
                                                    </label></>
                                                <input
                                                    id="upload-button"
                                                    type="file"
                                                    name="myImage"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { handleUploader(e) }}
                                                    multiple
                                                />
                                            </div>
                                        </Col> */}
                    </Row>
                  </div>
                  <div className="text-end">
                    <Button variant="secondary" onClick={goBack}>
                      Cancel
                    </Button>{" "}
                    <Button type="submit">Create Ticket</Button>
                  </div>
                </Form>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  );
});

export default AddTickets;
