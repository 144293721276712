import { HttpInterceptor } from "../auth/http-interceptors";


export function SubscribeCheckout(data) {
    return HttpInterceptor.post(`/seller/checkout`, data).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err));
}


export function GetSubscribeCheckoutTransactionId(id) {
    return HttpInterceptor.get(`/seller/create-subscription/${id}`).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err));
}


export function UserSubscribeCheck() {
    return HttpInterceptor.get(`/seller/check-subscription`).then((res) => {
        return res?.data;
    }).catch((err) => console.log(err));
}

