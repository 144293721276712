import React, { useEffect } from 'react'
import './styles/Subscribecancel.scss';
import { useNavigate } from 'react-router-dom';
import { GetSubscribeCheckoutTransactionId } from '../../../../services/subscribe_user/subscribe_users';
import { toast } from 'react-toastify';
function Subscribecancel() {
    const queryParameters = new URLSearchParams(window.location.search)
    const Transaction_id = queryParameters.get("transaction_id");
    const history = useNavigate();
    useEffect(() => {
        GetSubscribeCheckoutTransactionId(Transaction_id).then((res) => {
            toast.success(res.message);
            setTimeout(() => {
                history("/womeyn/dashboard")
            }, 1200);
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            Transaction Failed...
        </div>
    )
}

export default Subscribecancel