import { useState, memo, Fragment, useEffect, useCallback, useMemo } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Row, Col, Form, Spinner, Toast } from "react-bootstrap";
import Card from "../../../../../components/bootstrap/card";
import "./styles/AddProducts.scss";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import camera from "../../../../../assets/loginLogos/bxs_camera.svg";
import ProductServices, {
  GetProductListEdit,
} from "../../../../../services/product_services/product_services";
import { toast } from "react-toastify";
import SelectBox from "../../../../components/select-box";
import AddVariationModal from "../../../../../components/modal/add-variation";
import product_services from "../../../../../services/product_services/product_services";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isObject, useFormik } from "formik";
import * as yup from "yup";
import SalePriceField from "./SalePriceField";
import { useOutletContext } from "react-router-dom";
import UploadImageMapper from "./ImageMapper";

import JoditEditor from "jodit-react";

import parse from 'html-react-parser';

const AddProduct = memo((props) => {



  const [content, setContent] = useState("");
  const [logs, setLogs] = useState([]);
  const appendLog = useCallback(
    (message) => {
      const newLogs = [...logs, message];
      setLogs(newLogs);
    },
    [logs, setLogs]
  );

  const config = useMemo(
    () => ({
      zIndex: 0,

      enableDragAndDropFileToEditor: true,
      saveModeInCookie: false,
      spellcheck: true,
      editorCssClass: false,
      triggerChangeEvent: true,
      width: 1000,
      tabIndex: -1,
      toolbar: true,

      imageDefaultWidth: 100,
      events: {},

      uploader: {
        insertImageAsBase64URI: true,
      },
      placeholder: 'Product Description',
      showXPathInStatusbar: false,
      limitChars: 2000,
      limitWords: 1000,
    }),
    []
  )

  const onChange = useCallback(
    (newContent) => {
      appendLog(`onChange triggered with ${newContent}`);
    },
    [appendLog]
  );

  useEffect(() => {
  }, [onChange]);

  const onBlur = useCallback(
    (newContent) => {
      appendLog(`onBlur triggered with ${newContent}`);
      setContent(newContent);
    },
    [appendLog, setContent]
  );










  const [checkshipping, setShipping] = useState("");

  const { id } = useParams();
  const { userInfo } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [newVariations, setNewVariations] = useState([]);
  const [imageurlsbanner, setImagebanner] = useState("");
  const userid = localStorage.getItem("userid");
  const location = useLocation();
  const [initialValues, setIntialValues] = useState(
    location.state ? location.state.product : []
  );
  const history = useNavigate();
  const [step, setIndex] = useState(1);
  const [error1, setError1] = useState(false);
  const [error3, setError3] = useState(false);
  const [error5, setError5] = useState(false);
  const [checkboxs, setCheckBoxs] = useState(
    initialValues.variationIds ? initialValues.variationIds.split(",") : []
  );
  const [selectedImage, setSelectedImage] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState();
  // const [updateImages, setUpdateImages] = useState(new Array(initialValues.productImages.length).fill(null));
  const [updateImages, setUpdateImages] = useState(
    initialValues.productImages ? initialValues.productImages : ""
  );




  const [productId, setProductId] = useState("");
  const [productShippingerror, setProductShippingError] = useState(false);


  const [productlist, setProductList] = useState({
    categoryId: initialValues.categoryId ? initialValues.categoryId : "",
    productHaveVariations: initialValues.productHaveVariations
      ? initialValues.productHaveVariations
      : false,
    variations: [],
    productDescription: initialValues.productDescription
      ? initialValues.productDescription
      : "",
  });

  const [formDatas, setFormDatas] = useState(null);
  //Model Props
  const [categories, setCategories] = useState([]);
  const [productcounts, setCounts] = useState([]);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const { categoryId, productHaveVariations, variations, productDescription } =
    productlist;

  const handleproductImageurl = (e) => {
    setImagebanner(e.target.files[0]);
  };
  useEffect(() => {
    if (categories.length === 0) {
      GetCategories();
    }
    product_services
      .ProductAvailablecountCheck()
      .then((res) => {
        setCounts(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [categories]);
  useEffect(() => {
    if (categoryId) {
      product_services
        .GetVariationList(JSON.parse(userid), categoryId)
        .then((results) => {
          setNewVariations(results.results);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [categoryId, productId, checkshipping]);
  const GetCategories = () => {
    ProductServices.GetCategories()
      .then((res) => {
        setCategories(res);
      })
      .catch((err) => console.log(err));
  };
  const add = () => {
    setIndex(step + 1);
  };
  const minus = () => {
    setIndex(step - 1);
  };
  const handleChangeProduct = (e) => {
    setProductList({ ...productlist, [e.target.name]: e.target.value });

  };
  const handleImageChange = (e) => {
    setSelectedImage([...selectedImage, e.target.files[0]]);
  };
  const handleVariationModal = () => {
    setShowVariationModal(!showVariationModal);
  };
  const handleUploadBannerImage = (productId) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      if (uploadingFiles && uploadingFiles["0"]) {
        formData.append("upl", uploadingFiles["0"]);
        product_services
          .UploadBannerImage(formData, productId)
          .then((result) => {
            resolve(true);
          })
          .catch((err) => {
            if (err) reject(err);
          });
      } else {
        resolve(true);
      }
    });
  };

  const handleSubmitProduct = (e) => {
    formDatas.append("productDescription", productlist.productDescription);
    formDatas.append("sellerId", JSON.parse(localStorage.getItem("userid")));
    formDatas.append("updatedImages", updateImages);
    setLoading(true);
    if (initialValues.id) {
      ProductServices.EditProduct(initialValues.id, formDatas)
        .then(async (result) => {
          if (result) {
            let bannerImageUpload = await handleUploadBannerImage(result.id);
            if (bannerImageUpload) {
              toast.success("Product Updated Successful!!");
              setTimeout(() => {
                history("/womeyn/product-list");
                setLoading(false);
              }, 1200);
            } else {
              toast.error("Failed to add Product banner images !");
            }
          }
        })
        .catch((err) => {
          if (err) {
            toast.success("Failed to add this product !");
          }
        });
    } else {



      
      ProductServices.ProductCreate(formDatas)
        .then(async (result) => {
          if (result) {
            let bannerImageUpload = await handleUploadBannerImage(result.id);
            if (bannerImageUpload) {
              toast.success("Product Added Successful!!");
              setTimeout(() => {
                history("/womeyn/product-list");
                setLoading(false);
              }, 1200);
            } else {
              toast.error("Failed to add Product banner images !");
            }
          }
        })
        .catch((err) => {
          if (err) {
            toast.error("Failed to add this product !");
          }
        });
    }
  };

  const handleUploadingFiles = (e, imageNumber) => {
    if (e.target.files[0]) {
      setUploadingFiles((files) => ({
        ...files,
        [imageNumber]: e.target.files[0],
      }));
      let updatedImage = updateImages;
      updatedImage[imageNumber - 1] = e.target.files[0];
      setUpdateImages(updatedImage);
    }
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (uploadingFiles) {
      for (let i = 0; i <= Object.keys(uploadingFiles).length; i++) {
        if (uploadingFiles[Object.keys(uploadingFiles)[i]]) {
          formData.append(
            "upl",
            uploadingFiles[Object.keys(uploadingFiles)[i]]
          );
          if (i === Object.keys(uploadingFiles).length - 1) {
            for (var key in productlist) {

              if (key !== "productDescription")
                formData.append(key, productlist[key]);
            }
            formData.append("variationIds", checkboxs.toString());
            formData.append("imageUrls", "Test");
            //formData.append("productThumbImage", 'testr');
            setFormDatas(formData);
            add();
          }
        }
      }
    } else {
      for (var key in productlist) {
        if (key !== "productDescription")
          formData.append(key, productlist[key]);
      }
      formData.append("variationIds", checkboxs.toString());
      formData.append("imageUrls", "Test");
      // formData.append("productThumbImage", 'testr');

      setFormDatas(formData);
      add();
    }
  };

  const handleClick = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;

    if (checked) {
      setCheckBoxs([...checkboxs, value]);
    } else {
      setCheckBoxs(checkboxs.filter((e) => e != value));
    }
  };

  const handleChangeSelect = (category) => {


    // setCategoryId(category?.id)
    setProductList((prevState) => {
      return {
        ...prevState,
        categoryId: category.id,
      };
    });
  };

  const handleVariation = (data) => {
    setProductList((prevState) => {
      return {
        ...prevState,
        variations: [...variations, { name: data.name, id: data.id }],
        productHaveVariations: true,
      };
    });
    setCheckBoxs([...checkboxs, data.id]);
  };

  const productDescriptionChange = (data) => {
    setProductList((prevState) => {
      return {
        ...prevState,
        productDescription: data,
      };
    });
  };


  const [chargevalue, setChargeValue] = useState(initialValues?.localDeliveryCharge ? initialValues?.localDeliveryCharge : "");
  const [chargevalue1, setChargeValue1] = useState(initialValues?.localDeliveryCharge ? initialValues?.localDeliveryCharge : "");

  const handleChangelocalcharge = (e) => {
    if (chargevalue?.length === 0) {
      setProductShippingError(true);
    }
   
       setChargeValue(e?.target?.value);
    setChargeValue1(e?.target?.value);
    
   
  }
  const handleChangelocalcharge1 = () => {
    // handleChangelocalcharge();
  }

  const formikProductDetails = useFormik({
    initialValues: {
      productId: initialValues.productId ? initialValues.productId : "",
      productName: initialValues.productName ? initialValues.productName : "",
      brandName: initialValues.brandName ? initialValues.brandName : "",
      manufacturerName: initialValues.manufacturerName
        ? initialValues.manufacturerName
        : "",
      styleName: initialValues.styleName ? initialValues.styleName : "",
      targetGender: initialValues.targetGender
        ? initialValues.targetGender
        : "",
      actualPrice: initialValues.actualPrice ? initialValues.actualPrice : "",
      offerPercentag: initialValues.offerPercentag
        ? initialValues.offerPercentag
        : "",
      salePrice: initialValues.salePrice ? initialValues.salePrice : "",
      packageHeight: initialValues.packageHeight
        ? initialValues.packageHeight
        : "",
      packageWidth: initialValues.packageWidth
        ? initialValues.packageWidth
        : "",
      packageWeight: initialValues.packageWeight
        ? initialValues.packageWeight
        : "",
      quantityLeft: initialValues.quantityLeft
        ? initialValues.quantityLeft
        : "1",
      modelName: initialValues.modelName ? initialValues.modelName : "",
      isShippingRequired: initialValues.isShippingRequired
        ? initialValues.isShippingRequired
        : "",
      OutsideCountryDeliveryCharge: initialValues.OutsideCountryDeliveryCharge
        ? initialValues.OutsideCountryDeliveryCharge
        : "",
      // localDeliveryCharge: initialValues.localDeliveryCharge
      //   ? initialValues.localDeliveryCharge
      //   : "",
    },
    onSubmit: (values) => {
      // if (chargevalue || chargevalue == "") {
      if (values?.isShippingRequired == 2) {
        setChargeValue("");
        if (chargevalue == "" || chargevalue == undefined) {
          if (step === 2) {
            // add();
          }
          const actual = values.actualPrice.toString();
          const ph = values.packageHeight.toString();
          const pweight = values.packageWeight.toString();
          const pwidth = values.packageWidth.toString();
          const offerper = values.offerPercentag.toString();

          if (values.packageWeight > 9) {
            setProductList((prevState) => {
              return {
                ...prevState,
                ...values,
                localDeliveryCharge: "",
                actualPrice: actual.includes(".") ? values?.actualPrice : values?.actualPrice + "." + "00",
                // packageHeight: ph.includes(".") ? values?.packageHeight : values?.packageHeight + "." + "00",
                // packageWeight: pweight.includes(".") ? values?.packageWeight : values?.packageWeight + "." + "00",
                // packageWidth: pwidth.includes(".") ? values?.packageWidth : values?.packageWidth + "." + "00",
                salePrice: salePriceValue.includes(".") ? values?.salePrice : values?.salePrice + "." + "00",
                // offerPercentag: offerper.includes(".") ? values?.offerPercentag : values?.offerPercentag + "." + "00",
              };
            });
            add();
          }
          else {
            toast.error("Package Weight Minimum 10")
          }
          const salePriceValue = values.salePrice.toString();
        }
      }
      else {
        const actual = values.actualPrice.toString();
        const ph = values.packageHeight.toString();
        const pweight = values.packageWeight.toString();
        const pwidth = values.packageWidth.toString();
        const salePriceValue = values.salePrice.toString();
        const formatToTwoDecimalPlaces = (value) => {
          if (!value) return null; // Return null if the value is not provided
          const numberValue = parseFloat(value); // Convert the value to a float
          return numberValue.toFixed(2); // Format the number to two decimal places
        };
        
        const localDeliveryCharges =
          chargevalue !== null && chargevalue !== undefined
            ? formatToTwoDecimalPlaces(chargevalue)
            : productlist?.localDeliveryCharge
            ? formatToTwoDecimalPlaces(productlist.localDeliveryCharge)
            : null;



        // handleChangelocalcharge();
        if (values?.isShippingRequired == 1) {
          if (chargevalue || productlist?.localDeliveryCharge) {
            setProductShippingError(false);
            if (step === 2) {
              add();
            }
            setProductList((prevState) => {
              return {
                ...prevState,
                ...values,
                actualPrice: actual.includes(".") ? values?.actualPrice : values?.actualPrice + "." + "00",
                // packageHeight: ph.includes(".") ? values?.packageHeight : values?.packageHeight + "." + "00",
                // packageWeight: pweight.includes(".") ? values?.packageWeight : values?.packageWeight + "." + "00",
                // packageWidth: pwidth.includes(".") ? values?.packageWidth : values?.packageWidth + "." + "00",
                salePrice: salePriceValue.includes(".") ? values?.salePrice.toFixed(2) : values?.salePrice + "." + "00",
                localDeliveryCharge:localDeliveryCharges 
                // chargevalue ? chargevalue.includes(".")?chargevalue.toFixed(2):chargevalue+"."+"00" : null || productlist?.localDeliveryCharge ? productlist?.localDeliveryCharge.includes(".")?productlist?.localDeliveryCharge.toFixed(2):productlist?.localDeliveryCharge+"."+"00" : null,
              };
            });
          }
          else{
            setProductShippingError(true);

          }
        }



      }

    },
    validationSchema: yup.object({
      productId: yup.string().required("Product ID is Required !"),
      productName: yup.string().required("Product Name is Required !"),
      brandName: yup.string().required("BrandName is Required !"),
      manufacturerName: yup.string().required("ManufacturerName is Required !"),
      //  modalName: yup.string().required("Business State is Required !"),
      //   styleName: yup.string().required("ABN Number is Required !"),
      targetGender: yup.string().required("Target Gender is Required !"),
      packageHeight: yup.string().required("package height is Required !"),
      packageWidth: yup.string().required("package width is Required !"),
      packageWeight: yup.string().required("package weight is Required ! "),
      quantityLeft: yup.string().required("Quantity is Required !"),
      isShippingRequired: yup.string().required("Shipping  is Required !"),
      actualPrice: yup
        .number("Must be an number")
        .required("Actual price is required"),
      // localDeliveryCharge: yup.string().required("Local Delivery Charge is Required !"),
      offerPercentag: yup.number("Must be an number"),
      salePrice: yup.number("Must be an number"),
    }),
  });
  const createProductId = () => {
    let uuids = Date.now();
    setProductId(uuids);
    formikProductDetails.values.productId = uuids;
  };

  const prepCategories = categories.map((item) => ({
    value: item.id,
    label: item.path,
  }));

  const navigates = () => {
    history("/womeyn/subscribe/user");
  };





  useEffect(() => {
    ProductServices.GetProductListEdit(id).then((res) => {
      setContent(res?.productDescription)
    }).catch((err) => {
      console.log(err)
    })
  }, [id])






  if (id ? null : productcounts?.availableProductsCount === 0) {
    return <div>{navigates()}</div>;
  } else {
    if (userInfo.stateId === 1) {
      return (
        <Fragment>
          <div className="mt-5 mb-5 kalai-box" style={{ paddingBottom: "3%" }}>
            <Row>
              <Col>
                <div className="containers">
                  <ProgressBar
                    percent={((step - 1) * 100) / 4}
                    height={3}
                    filledBackground="#e95a5a"
                  >
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <>
                          <div
                            className={`step ${accomplished ? "completed" : ""
                              }`}
                          >
                            1
                          </div>
                          <div className="kalai">
                            Choose Category{" "}
                          </div>
                        </>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <>
                          <div
                            className={`step ${accomplished ? "completed" : ""
                              }`}
                          >
                            2
                          </div>

                          <div className="kalai">Product Details</div>
                        </>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <>
                          <div
                            className={`step ${accomplished ? "completed" : ""
                              }`}
                          >
                            3
                          </div>
                          <div className="kalai">Variations</div>
                        </>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => (
                        <>
                          <div
                            className={`step ${accomplished ? "completed" : ""
                              }`}
                          >
                            4
                          </div>
                          <div className="kalai">Upload Images</div>
                        </>
                      )}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished, index }) => (
                        <>
                          <div
                            className={`step ${accomplished ? "completed" : ""
                              }`}
                          >
                            5
                          </div>
                          <div className="kalai">Description</div>
                        </>
                      )}
                    </Step>
                  </ProgressBar>
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-5">
            {/* <button onClick={testStripe}>Click</button> */}
            <Row>
              <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                <Col className="product-card-container mt-5 ms-2">
                  <Card className="col-md-12 col-sm-12 col-lg-9">
                    <Card.Body className="p-0">
                      {step === 1 && (
                        <>
                          <div
                            id="basic"
                            className="iq-product-tracker-card show b-0"
                          >
                            <div>
                              <h3>{id ? <>Edit Product</> : <>Add New Product</>}</h3>
                            </div>
                            <div className="mb-5 mt-4">
                              <Col lg="12">
                                <div
                                  className=" "
                                  style={{ background: "#F9F9F9" }}
                                >
                                  <Form.Group>

                                    <SelectBox
                                      options={prepCategories}
                                      onChange={(value) => {
                                        handleChangeSelect(value);
                                      }}
                                      value={categoryId}
                                    ></SelectBox>
                                  </Form.Group>
                                  <div className="categoryId">
                                    {error1 && categoryId === "" ? (
                                      <span className="error-message">
                                        Please choose a category
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </div>

                            <hr className="hr-horizontal" />
                          </div>
                          <div className="btn-end">
                            <Button
                              onClick={add}
                              className="next-btn"
                              disabled={!categoryId}
                            >
                              Next
                            </Button>
                          </div>
                        </>
                      )}
                      {step === 2 && (
                        <>
                          <div className="b-0">
                            <div>
                              <Form
                                onSubmit={formikProductDetails.handleSubmit}
                                validateOnChange={false}
                              >
                                <div>
                                  <Row>
                                    <Col lg="12">
                                      <div className="form-group">
                                        <Form.Label htmlFor="productId">
                                          Product Id
                                          <span className="productid_tag">
                                            {" "}
                                            If you dont have a product ID? Then
                                            <Button
                                              type="button"
                                              onClick={createProductId}
                                              variant="link"
                                            >
                                              {" "}
                                              click here to generate{" "}
                                            </Button>
                                          </span>
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="productId"
                                          value={
                                            formikProductDetails.values
                                              .productId
                                          }
                                          placeholder="Product id"
                                          onChange={
                                            formikProductDetails.handleChange
                                          }
                                        />
                                        <div className="">
                                          {formikProductDetails.errors
                                            .productId &&
                                            formikProductDetails.touched
                                              .productId ? (
                                            <span className="error-message">
                                              {
                                                formikProductDetails.errors
                                                  .productId
                                              }
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    {/* <Col lg="4">
                                                                    <div className='mb-4 mt-4'>
                                                                        <Button type="button" onClick={createProductId} variant="link">Generate Product ID</Button>
                                                                    </div>
                                                                </Col> */}
                                  </Row>
                                </div>
                                <div className="mb-4 mt-4">
                                  <Col lg="12">
                                    <div className="form-group">
                                      <Form.Label htmlFor="productName">
                                        Product Name*
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="productName"
                                        value={
                                          formikProductDetails.values
                                            .productName
                                        }
                                        placeholder="Product name"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .productName &&
                                          formikProductDetails.touched
                                            .productName ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .productName
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                                <div className="d-flex gap-6 justify-content-between mb-4 mt-4 ">
                                  <Col lg="5">
                                    <div className="form-group">
                                      <Form.Label htmlFor="brandName">
                                        Brand Name
                                        {/* (optional) */}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="brandName"
                                        value={
                                          formikProductDetails.values.brandName
                                        }
                                        placeholder="Brand name"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .brandName &&
                                          formikProductDetails.touched
                                            .brandName ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .brandName
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="6 ">
                                    <div className="form-group">
                                      <Form.Label htmlFor="manufacturerName">
                                        Manufacturer's Name
                                        {/* (optional) */}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="manufacturerName"
                                        value={
                                          formikProductDetails.values
                                            .manufacturerName
                                        }
                                        placeholder="Manufacturer name"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .manufacturerName &&
                                          formikProductDetails.touched
                                            .manufacturerName ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .manufacturerName
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </div>

                                <div className="row gap-3 justify-content-between mb-4 mt-4">
                                  <Col lg="3">
                                    <div className="form-group">
                                      <Form.Label htmlFor="actualPrice">
                                        Selling Price*
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="actualPrice"
                                        value={
                                          formikProductDetails.values
                                            .actualPrice
                                        }
                                        placeholder="Actual Price"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .actualPrice &&
                                          formikProductDetails.touched
                                            .actualPrice ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .actualPrice
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>

                                  <Col lg="3">
                                    <div className="form-group">
                                      <Form.Label htmlFor="Offer Percentage">
                                        Offer Percentage
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="offerPercentag"
                                        value={
                                          formikProductDetails.values
                                            .offerPercentag
                                        }
                                        placeholder="Offer Percentage"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .offerPercentag &&
                                          formikProductDetails.touched
                                            .offerPercentag ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .offerPercentag
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="3">
                                    <SalePriceField
                                      id="salePrice"
                                      type="number"
                                      name="salePrice"
                                      value={
                                        formikProductDetails.values.salePrice
                                      }
                                      values={formikProductDetails.values}
                                      setFieldValue={
                                        formikProductDetails.setFieldValue
                                      }
                                      onChange={
                                        formikProductDetails.handleChange
                                      }
                                    ></SalePriceField>
                                  </Col>
                                </div>

                                <div className="d-flex gap-3 justify-content-between mb-4 mt-4">
                                  <Col lg="3">
                                    <div className="form-group">
                                      <Form.Label htmlFor="packageWidth">
                                        Package width (cm)
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="packageWidth"
                                        value={
                                          formikProductDetails.values
                                            .packageWidth
                                        }
                                        placeholder="Package width (cm)"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .packageWidth &&
                                          formikProductDetails.touched
                                            .packageWidth ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .packageWidth
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="3 ">
                                    <div className="form-group">
                                      <Form.Label htmlFor="packageHeight">
                                        Package height (cm)
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="packageHeight"
                                        value={
                                          formikProductDetails.values
                                            .packageHeight
                                        }
                                        placeholder="Package height (cm)"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .packageHeight &&
                                          formikProductDetails.touched
                                            .packageHeight ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .packageHeight
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="3">
                                    <div className="form-group">
                                      <Form.Label htmlFor="packageWeight ">
                                        Package weight (gms)
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="packageWeight"
                                        value={
                                          formikProductDetails.values
                                            .packageWeight
                                        }
                                        placeholder="Product weight (gms)"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .packageWeight &&
                                          formikProductDetails.touched
                                            .packageWeight ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .packageWeight
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </div>

                                <div className="mb-4 mt-4">
                                  <Col lg="12">
                                    <div className="form-group">
                                      <Form.Label htmlFor="quantityLeft">
                                        Quantity*
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        name="quantityLeft"
                                        value={
                                          formikProductDetails.values
                                            .quantityLeft
                                        }
                                        placeholder="Quantity"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                        defaultValue={"1"}
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .quantityLeft &&
                                          formikProductDetails.touched
                                            .quantityLeft ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .quantityLeft
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </div>
                                <div className="d-flex justify-content-between mb-4 mt-4">
                                  <Col lg="5">
                                    <div className="form-group">
                                      <Form.Label htmlFor="modelName">
                                        Model Number
                                        {/* (optional) */}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="modelName"
                                        value={
                                          formikProductDetails.values.modelName
                                        }
                                        placeholder="Model number"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      // onChange={(e) => {
                                      //   var tests = /^[0-9a-zA-Z(\-)]+$/;
                                      //   if (
                                      //     e.target.value === "" ||
                                      //     tests.test(e.target.value)
                                      //   ) {
                                      //     return formikProductDetails.handleChange(
                                      //       e
                                      //     );
                                      //   } else {
                                      //     return null;
                                      //   }
                                      // }}
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .modelName &&
                                          formikProductDetails.touched
                                            .modelName ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .modelName
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="6 ">
                                    <div className="form-group">
                                      <Form.Label htmlFor="styleName">
                                        Style Name
                                        {/* (optional) */}
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="styleName"
                                        value={
                                          formikProductDetails.values.styleName
                                        }
                                        placeholder="Style name"
                                        onChange={
                                          formikProductDetails.handleChange
                                        }
                                      />
                                      <div className="">
                                        {formikProductDetails.errors
                                          .styleName &&
                                          formikProductDetails.touched
                                            .styleName ? (
                                          <span className="error-message">
                                            {
                                              formikProductDetails.errors
                                                .styleName
                                            }
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </div>

                                <div className="d-flex justify-content-between mb-4 mt-4">
                                  <Col lg="5">
                                    <Form.Label htmlFor=" Target Gender">
                                      Target Gender
                                      {/* (optional) */}
                                    </Form.Label>
                                    <Form.Select
                                      id="validationDefault04"
                                      name="targetGender"
                                      value={
                                        formikProductDetails.values.targetGender
                                      }
                                      onChange={
                                        formikProductDetails.handleChange
                                      }
                                    >
                                      <option value="" disabled>
                                        --Please choose a Target Gender--*
                                      </option>
                                      <option label="Male" value="Male">
                                        Male
                                      </option>
                                      <option label="Female" value="Female">
                                        Female
                                      </option>
                                      <option label="All Sex" value="AllSex">
                                        All Sex
                                      </option>
                                    </Form.Select>
                                    <div className="">
                                      {formikProductDetails.errors
                                        .targetGender &&
                                        formikProductDetails.touched
                                          .targetGender ? (
                                        <span className="error-message">
                                          {
                                            formikProductDetails.errors
                                              .targetGender
                                          }
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg="6">
                                    <Form.Label htmlFor="Shipping By">
                                      Shipping By
                                      { }
                                    </Form.Label>
                                    <Form.Select
                                      id="validationDefault05"
                                      name="isShippingRequired"
                                      value={
                                        formikProductDetails.values
                                          .isShippingRequired
                                      }
                                      onChange={
                                        formikProductDetails.handleChange
                                      }
                                    >
                                      <option value="" disabled>
                                        --Please choose Shipping By--*
                                      </option>
                                      <option label="Myself" value={1}>
                                        Myself
                                      </option>
                                      <option
                                        label="ShipEngine offered by Womeyn"
                                        value={2}
                                      >
                                        ShipEngine offered by Womeyn
                                      </option>
                                    </Form.Select>
                                    <div className="">
                                      {formikProductDetails.errors
                                        .isShippingRequired &&
                                        formikProductDetails.touched
                                          .isShippingRequired ? (
                                        <span className="error-message">
                                          {
                                            formikProductDetails.errors
                                              .isShippingRequired
                                          }
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </Col>
                                </div>

                                {formikProductDetails.values
                                  .isShippingRequired == 1 ? (
                                  <div>
                                    <div className="mb-4 mt-4">
                                      <Col lg="12">
                                        <div className="form-group"></div>
                                      </Col>
                                    </div>
                                    <div className="mb-4 mt-4">
                                      <Col lg="12">
                                        <div className="form-group">
                                          <Form.Label htmlFor="chargevalue">
                                            Delivery Charge
                                          </Form.Label>
                                          <Form.Control
                                            type="text"
                                            name="chargevalue"
                                            // value={
                                            //   formikProductDetails.values
                                            //     .localDeliveryCharge
                                            // }
                                            value={chargevalue?chargevalue:productlist?.localDeliveryCharge}
                                            placeholder="Delivery Charge"
                                            onChange={
                                              handleChangelocalcharge
                                            }
                                          />
                                          <div className="">
                                            {/* {formikProductDetails.errors
                                              .localDeliveryCharge &&
                                              formikProductDetails.touched
                                                .localDeliveryCharge ? (
                                              <span className="error-message">
                                                {
                                                  formikProductDetails.errors
                                                    .localDeliveryCharge
                                                }
                                              </span>
                                            ) : (
                                              ""
                                            )} */}
                                          </div>
                                        </div>
                                        <div>
                                          {productShippingerror && chargevalue?.length <= 0 ? <span className="text-danger">Delivery Charge is Required</span> : null}
                                        </div>
                                      </Col>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="w-100 button-gap-section mt-5">
                                  <div>
                                    <Button
                                      className="back-btn"
                                      onClick={minus}
                                    >
                                      Back
                                    </Button>
                                  </div>
                                  <div>
                                    <Button className="next-btn" type="submit">
                                      Next
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </>
                      )}

                      {step === 3 && (
                        <>
                          <div className="b-0 ">
                            <div>
                              <h6>Choose variation type</h6>
                            </div>
                            <div className="d-flex gap-5 text-center d-flex align-items-center mb-2 mt-4">
                              <div>
                                <span className="mt-2 does">
                                  Does this product have variations ?
                                </span>
                              </div>
                              <div className="d-flex">
                                <div className="d-flex gap-5">
                                  <div className="custom-control d-flex gap-2">
                                    <input
                                      type="radio"
                                      value={1}
                                      name="productHaveVariations"
                                      checked={productHaveVariations == true}
                                      onChange={handleChangeProduct}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="male"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="custom-control d-flex gap-2">
                                    <input
                                      type="radio"
                                      value={0}
                                      name="productHaveVariations"
                                      checked={productHaveVariations == false}
                                      onChange={handleChangeProduct}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="female"
                                    >

                                      No
                                    </label>
                                  </div>
                                </div>
                                {error3 && productHaveVariations === "" ? (
                                  <span className="error-message">
                                    Please provide a productHaveVariations
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="mt-5">
                              <Row>

                                {variations.length > 0
                                  ? variations.map((item) => {
                                    return (
                                      <Col>
                                        <Form.Check
                                          disabled={
                                            productHaveVariations != "1"
                                          }
                                          onChange={handleClick}
                                          checked={
                                            checkboxs.indexOf(item?.id) !== -1
                                              ? true
                                              : false
                                          }
                                          type="checkbox"
                                          name={item.name}
                                          value={item.id}
                                        />
                                        {item.name}
                                      </Col>
                                    );
                                  })
                                  : ""}
                              </Row>
                              {/* {console.log("fsdhkjfsdk", newVariations)} */}
                              <Row>
                                {newVariations && newVariations.length > 0
                                  ? newVariations.map((item) => {
                                    return (
                                      <Col>
                                        <Form.Check
                                          checked={
                                            checkboxs.indexOf(item?.id) !== -1
                                              ? true
                                              : false
                                          }
                                          disabled={
                                            productHaveVariations != "1"
                                          }
                                          onChange={handleClick}
                                          type="checkbox"
                                          name={item?.name}
                                          value={item?.id}
                                        />
                                        {item.name}
                                      </Col>
                                    );
                                  })
                                  : ""}
                              </Row>
                              {variations === "" ? (
                                <span className="error-message">
                                  Please provide a productHaveVariations
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                              <Button
                                className=""
                                disabled={
                                  productHaveVariations == "1" ? false : true
                                }
                                onClick={handleVariationModal}
                              >
                                Add new variation
                              </Button>
                            </div>
                          </div>
                          <div className="w-100 button-gap-section mt-5">
                            <div>
                              <Button className="back-btn" onClick={minus}>
                                Back
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="next-btn"
                                onClick={add}
                                disabled={
                                  productHaveVariations == 1 &&
                                  checkboxs.length === 0
                                }
                              >
                                Next
                              </Button>
                            </div>
                          </div>
                          <AddVariationModal
                            handleVariation={handleVariation}
                            show={showVariationModal}
                            categoryId={categoryId}
                            handleClose={handleVariationModal}
                          ></AddVariationModal>
                        </>
                      )}

                      {step === 4 && (
                        <>
                          <div className="mb-4">
                            <h5>Hero Image</h5>
                          </div>
                          <div className="text-danger mb-5">
                        Image Size Maximum Dimensions are 1200 x 1200 pixels.
                        </div>
                          <div className="d-flex justify-content-center">
                            {uploadingFiles && uploadingFiles[0] ? (
                              <>
                                <label htmlFor="upload-button-banner-image">
                                  <div className="main-camera-sections">
                                    <img
                                      src={URL.createObjectURL(
                                        uploadingFiles[0]
                                      )}
                                      alt="Avatar"
                                      class="image"
                                      width="120px"
                                      height="120px"
                                    />
                                  </div>
                                </label>
                              </>
                            ) : initialValues.productThumbImage ? (
                              <label htmlFor="upload-button-banner-image">
                                <div className="main-camera-sections">
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${initialValues.productThumbImage}`}
                                    alt="Avatar"
                                    class="image"
                                    width="120px"
                                    height="120px"
                                  />
                                </div>

                              </label>
                            ) : (
                              <label htmlFor="upload-button-banner-image">
                                <div className="main-camera-section">
                                  <div className="inside-camera-section-upload">
                                    <img src={camera} alt="no image" />
                                  </div>
                                </div>
                              </label>
                            )}
                          </div>
                          <div className="mt-5 mb-5">
                            <hr />
                          </div>
                          <div className="mb-4 mt-4">
                            <h5>Product Images</h5>
                          </div>
                          <div className="text-danger mb-5">
                        Image Size Maximum Dimensions are 1200 x 1200 pixels.
                        </div>
                          <UploadImageMapper
                            uploadedFiles={initialValues.productImages}
                            uploadingFiles={uploadingFiles}
                            Productid={id}
                          ></UploadImageMapper>
                          <div className="w-100 button-gap-section mt-5">
                            <div>
                              <Button className="back-btn" onClick={minus}>
                                Back
                              </Button>
                            </div>
                            <div>
                              <Button
                                className="next-btn"
                                onClick={handleImageUpload}
                              >
                                Next
                              </Button>
                            </div>
                          </div>

                          <div>
                            <input
                              id="upload-button-banner-image"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 0);
                              }}
                              multiple
                            />
                          </div>
                          {/* <div>
                                                <input
                                                    id="upload-button-banner"
                                                    type="file"
                                                    name="myImage"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => { handleUploadingFiles(e, 0) }}
                                                    multiple
                                                />
                                            </div> */}
                          <div>
                            <input
                              id="upload-button1"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 1);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button2"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 2);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button3"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 3);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button4"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 4);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button5"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 5);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button6"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 6);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button7"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleUploadingFiles(e, 7);
                              }}
                              multiple
                            />
                            <input
                              id="upload-button8"
                              type="file"
                              name="myImage"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => handleUploadingFiles(e, 8)}
                              multiple
                            />
                          </div>
                        </>
                      )}

                      {step === 5 && (
                        <>
                          <div>
                            <span>Add Product Description</span>
                          </div>
                          <div className="mt-3">





                            {/* <JoditEditor

                              // value={content}
                              value={content}
                              config={config}
                              tabIndex={1}
                              onBlur={onBlur}
                              onChange={onChange}
                            /> */}

                            <Form.Control
                              as="textarea"
                              rows={10}
                              minLength={0}
                              maxLength={500}
                              name="productDescription"
                              value={productDescription}
                              onChange={handleChangeProduct}
                              placeholder="Enter your product Description here"
                            />
                            {/* <Editor
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor"
                                                    onContentStateChange={productDescriptionChange}
    
                                                >
                                                    {/* <Form.Control as="textarea" rows={10} name="productDescription" disabled
                                                        value={JSON.stringify(productDescription, null, 4)} placeholder='Enter your product Description here' /> 
                                                <textarea
                                                    disabled
                                                    value={JSON.stringify(productDescription, null, 4)}
                                                ></textarea>
                                            </Editor> */}
                            {error5 && productDescription.length <= 0 ? (
                              <span className="error-message">
                                Please provide a productDescription
                              </span>
                            ) : (
                              ""
                            )}
                            <div className="w-100 button-gap-section mt-5">
                              <div>
                                {step >= 2 && (
                                  <Button className="back-btn" onClick={minus}>
                                    Back
                                  </Button>
                                )}
                              </div>
                              <Button
                                className="next-btn"
                                onClick={handleSubmitProduct}
                              >
                                {loading ? (
                                  <>
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    <span className="ms-2">Loading...</span>
                                  </>
                                ) : (
                                  <>Finish</>
                                )}
                              </Button>
                            </div>
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </div>
            </Row>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div
            className="mt-5 mb-5 kalai-box"
            style={{ paddingBottom: "3%" }}
          ></div>
          <div className="mt-5">
            <div>
              <span>Please wait for admin approval</span>
            </div>
          </div>
        </Fragment>
      );
    }
  }
});

AddProduct.displayName = "AddProduct";
export default AddProduct;
