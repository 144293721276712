import { memo, Fragment, useState } from "react";

// React-bootstrap
import {
  Row,
  Col,
  Form,
  Image,
  Button,
  ListGroup,
  Alert,
} from "react-bootstrap";

// Router
import { Link, useNavigate } from "react-router-dom";

import facebook from "../../assets/images/brands/fb.svg";
import google from "../../assets/images/brands/gm.svg";
import instagram from "../../assets/images/brands/im.svg";

import { toast } from "react-toastify";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import login from "../../assets/loginLogos/login.png";
import logowomenyn from "../../assets/loginLogos/women_white_log.svg";
import circlethree from "../../assets/loginLogos/circle.svg";
import circleone from "../../assets/loginLogos/circle2.svg";
import circletwo from "../../assets/loginLogos/circle1.svg";
import "./styles/Signup.scss";
import Spinners from "../../loadspinner/LoadSpinner";
import { toastusersignup } from "../../toastcontents/ToastContent";
import { Signups } from "../../services/otp_service/otp-services";

const Signup = memo(() => {
  const history = useNavigate();
  const [formState, setFormState] = useState(false);
  const [showicon, setShowicon] = useState(false);
  const [showicon1, setShowicon1] = useState(false);
  const [termstrueerror, setTermstrueerror] = useState(false);
  const [termstrue, setTermstrue] = useState(false);
  const [matchcheck1, setMatchCheck1] = useState(false);
  const [matchcheck2, setMatchCheck2] = useState(false);



  const CheckBoxCheckCondition = () => {
    if (termstrue) {
      setTermstrueerror(false)

    }
    else {
      setTermstrueerror(true)
    }

  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      contactNumber: "",
      email: "",
      password: "",
      confirmPassword: ""

    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First Name is Required"),
      lastName: Yup.string()
        .required("Last Name is Required"),
      contactNumber: Yup.string()
        .max(9, "Must be 9 Numbers")
        .required("Contact Number Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email Required"),
      password: Yup.string()
        .min(5, "Atleast 6 characters long")
        .max(50, "Too Long")
        .required("Password is Required"),
      confirmPassword: Yup.string()
        .required("Confirm Password is Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      CheckBoxCheckCondition();
      var firstName = values.firstName;
      var lastName = values.lastName;
      var email = values.email;
      var password = values.password;
      var contactNumber = `${values.contactNumber}`;
      var gender = "female";
      const datas = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        contactNumber: contactNumber,
        gender: gender,
      };

      var passwordRegex = /(?=^.{8,}$)(?=.{0,}[A-Z])(?=.{0,}[a-z])(?=.{0,}\W)(?=.{0,}\d)/;
      if (passwordRegex.test(datas?.password)) {
        if (termstrue) {
          setMatchCheck1(true);
          setMatchCheck2(false);
          setFormState(true);
          Signups(datas)
            .then((response) => {

              if (response) {
                toast.success(toastusersignup);
                setTimeout(() => {
                  history("/womeyn/dashboard");
                }, 1000);
                setFormState(false);
              }
              else {
                setFormState(false)
                toast.error("Email already taken");
              }
            })
            .catch((err) => {
              setFormState(false);
            });
        }
      }

      else {
        setMatchCheck2(true);

      }



    },
  });





  const Teramspush = () => {
    window.open(`/termsandconditions`)
  }


  const Privtepush = () => {
    window.open(`/privacypolicy`)
  }



  const handleTermsConditions = () => {
    setTermstrue((pre) => !pre);
  }


  return (
    <>
      <div className="signup-main-section">
        <div className="inside-signup-section">
          <div className="left-signup-section d-md-block d-none">
            <div className="logo">
              <img src={logowomenyn} alt="no image" className="womenlogsize" />
            </div>
            <div className="circle">
              <img src={circlethree} alt="no image" className="circles" />
            </div>
            <div className="sellers">
              <h5 className="we">We are trusted by </h5>
              <div>
                <h6 className="seller">Sellers</h6>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "94%",
                paddingTop: "7%",
              }}
            >
              <img src={login} className="Image-fluid" alt="images" />
            </div>
          </div>
          <div className="right-signup-section">
            <div className="inside-right-singup-section">
              <div className="circleone">
                <img
                  src={circleone}
                  alt="no circleone"
                  className="circleone-image"
                />
              </div>
              <div>
                <img
                  src={circletwo}
                  alt="no circletwo"
                  className="circletwo-image"
                />
              </div>
              <div className="first-right-signup-section">
                <div className="qutations-section-first">
                  <div className="qutations"></div>
                  <div className="and-love-by">
                    And loved by
                    <br />
                    <span className="customer">Sellers</span>
                  </div>
                </div>
              </div>
              <div className="second-right-signup-section mb-5 mt-5">
                <div className="mt-3 mb-4">
                  <span className="signin-section"> Sign Up</span>{" "}
                  <span>to stay connected</span>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <Row className="d-flex justify-content-between">
                    <Col sm="12" md="12" lg="6" className="form-group">
                      <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          name="firstName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          isValid={
                            formik.touched.firstName && !formik.errors.firstName
                          }
                          isInvalid={
                            formik.touched.firstName &&
                            !!formik.errors.firstName
                          }
                        />
                        <Form.Text className="text-danger">
                          {formik.touched.firstName &&
                            formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </Form.Text>
                        {/* <Form.Control.Feedback type="valid">
                                                    Looks Good! 😎
                                                </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    <Col sm="12" md="12" lg="6" className="form-group">
                      <Form.Group controlId="firstName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          isValid={
                            formik.touched.lastName && !formik.errors.lastName
                          }
                          isInvalid={
                            formik.touched.lastName && !!formik.errors.lastName
                          }
                        />
                        <Form.Text className="text-danger">
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </Form.Text>
                        {/* <Form.Control.Feedback type="valid">
                                                    Looks Good! 😎
                                                </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col sm="12" md="12" lg="6" className="form-group">
                      <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          name="email"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          isValid={formik.touched.email && !formik.errors.email}
                          isInvalid={
                            formik.touched.email && !!formik.errors.email
                          }
                        />
                        <Form.Text className="text-danger">
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </Form.Text>
                        {/* <Form.Control.Feedback type="valid">
                                                    Looks Good! 😎
                                                </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    <div className="form-group col-sm-12 col-md-12 col-lg-6">
                      <Form.Group controlId="email">
                        <Form.Label>Contact Number</Form.Label>
                        <div className="contact-number-section">
                          <div className="box-number-section">+61</div>
                          <Form.Control
                            name="contactNumber"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.contactNumber}
                            isValid={
                              formik.touched.contactNumber &&
                              !formik.errors.contactNumber
                            }
                            isInvalid={
                              formik.touched.contactNumber &&
                              !!formik.errors.contactNumber
                            }
                            maxLength={9}
                          />
                        </div>
                        <Form.Text className="text-danger">
                          {formik.touched.contactNumber &&
                            formik.errors.contactNumber ? (
                            <div className="text-danger">
                              {formik.errors.contactNumber}
                            </div>
                          ) : null}
                        </Form.Text>
                        {/* <Form.Control.Feedback type="valid">
                                                    Looks Good! 😎
                                                </Form.Control.Feedback> */}
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="d-flex justify-content-between">
                    <Col sm="12" md="12" lg="6" className="form-group">
                      <Form.Group controlId="password">
                        <Form.Label>Password</Form.Label>
                        <div className="password-section-icons">
                          <Form.Control
                            name="password"
                            type={showicon ? "text" : "password"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          // isValid={formik.touched.password && !formik.errors.password}
                          // isInvalid={formik.touched.password && !!formik.errors.password}
                          />
                          <div className="passsword-svg mt-2">
                            {showicon ? (
                              <svg
                                width="20"
                                onClick={() => setShowicon(!showicon)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                width="20"
                                onClick={() => setShowicon(!showicon)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.80327 15.2526C10.4277 15.6759 11.1888 15.9319 11.9987 15.9319C14.1453 15.9319 15.8919 14.1696 15.8919 12.0037C15.8919 11.1866 15.6382 10.4186 15.2186 9.78855L14.1551 10.8617C14.3307 11.1964 14.4283 11.5902 14.4283 12.0037C14.4283 13.3525 13.3354 14.4551 11.9987 14.4551C11.5889 14.4551 11.1986 14.3567 10.8668 14.1795L9.80327 15.2526ZM18.4288 6.54952C19.8436 7.84907 21.0438 9.60149 21.9415 11.7083C22.0195 11.8954 22.0195 12.112 21.9415 12.2892C19.8534 17.1921 16.1358 20.1259 11.9987 20.1259H11.9889C10.1058 20.1259 8.30063 19.5056 6.71018 18.3735L4.81725 20.2834C4.67089 20.4311 4.4855 20.5 4.30011 20.5C4.11472 20.5 3.91957 20.4311 3.78297 20.2834C3.53903 20.0373 3.5 19.6435 3.69515 19.358L3.72442 19.3186L18.1556 4.75771C18.1751 4.73802 18.1946 4.71833 18.2044 4.69864L18.2044 4.69863C18.2239 4.67894 18.2434 4.65925 18.2532 4.63957L19.1704 3.71413C19.4631 3.42862 19.9217 3.42862 20.2046 3.71413C20.4974 3.99964 20.4974 4.4722 20.2046 4.75771L18.4288 6.54952ZM8.09836 12.0075C8.09836 12.2635 8.12764 12.5195 8.16667 12.7558L4.55643 16.3984C3.5807 15.2564 2.7318 13.8781 2.05854 12.293C1.98049 12.1158 1.98049 11.8992 2.05854 11.7122C4.14662 6.80933 7.86419 3.88534 11.9916 3.88534H12.0013C13.3966 3.88534 14.7529 4.22007 16.0018 4.85015L12.7429 8.13841C12.5087 8.09903 12.255 8.0695 12.0013 8.0695C9.84494 8.0695 8.09836 9.83177 8.09836 12.0075Z"
                                  fill="currentColor"
                                ></path>{" "}
                              </svg>
                            )}
                          </div>
                        </div>
                        <Form.Text className="text-danger">
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger">
                              {formik.errors.password}
                            </div>
                          ) : null}

                          {matchcheck1 ? <div className='text-success'>
                            Password Matched!
                          </div> : <>
                          </>}
                          {matchcheck2 && <div className='text-danger shorttexts'>
                            At least one letter,
                            one capital letter,
                            one number,
                            one special character,
                          </div>}
                        </Form.Text>
                        {/* <Form.Control.Feedback type="valid">
                                                    Looks Good! 😎
                                                </Form.Control.Feedback> */}
                      </Form.Group>
                    </Col>
                    <Col sm="12" md="12" lg="6" className="form-group">
                      <Form.Group controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <div className="password-section-icons">
                          <Form.Control
                            name="confirmPassword"
                            type={showicon1 ? "text" : "password"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                          // isValid={formik.touched.confirmPassword && !formik.errors.confirmPassword}
                          // isInvalid={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
                          />
                          <div className="passsword-svg mt-2">
                            {showicon1 ? (
                              <svg
                                width="20"
                                onClick={() => setShowicon1(!showicon1)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                width="20"
                                onClick={() => setShowicon1(!showicon1)}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M9.80327 15.2526C10.4277 15.6759 11.1888 15.9319 11.9987 15.9319C14.1453 15.9319 15.8919 14.1696 15.8919 12.0037C15.8919 11.1866 15.6382 10.4186 15.2186 9.78855L14.1551 10.8617C14.3307 11.1964 14.4283 11.5902 14.4283 12.0037C14.4283 13.3525 13.3354 14.4551 11.9987 14.4551C11.5889 14.4551 11.1986 14.3567 10.8668 14.1795L9.80327 15.2526ZM18.4288 6.54952C19.8436 7.84907 21.0438 9.60149 21.9415 11.7083C22.0195 11.8954 22.0195 12.112 21.9415 12.2892C19.8534 17.1921 16.1358 20.1259 11.9987 20.1259H11.9889C10.1058 20.1259 8.30063 19.5056 6.71018 18.3735L4.81725 20.2834C4.67089 20.4311 4.4855 20.5 4.30011 20.5C4.11472 20.5 3.91957 20.4311 3.78297 20.2834C3.53903 20.0373 3.5 19.6435 3.69515 19.358L3.72442 19.3186L18.1556 4.75771C18.1751 4.73802 18.1946 4.71833 18.2044 4.69864L18.2044 4.69863C18.2239 4.67894 18.2434 4.65925 18.2532 4.63957L19.1704 3.71413C19.4631 3.42862 19.9217 3.42862 20.2046 3.71413C20.4974 3.99964 20.4974 4.4722 20.2046 4.75771L18.4288 6.54952ZM8.09836 12.0075C8.09836 12.2635 8.12764 12.5195 8.16667 12.7558L4.55643 16.3984C3.5807 15.2564 2.7318 13.8781 2.05854 12.293C1.98049 12.1158 1.98049 11.8992 2.05854 11.7122C4.14662 6.80933 7.86419 3.88534 11.9916 3.88534H12.0013C13.3966 3.88534 14.7529 4.22007 16.0018 4.85015L12.7429 8.13841C12.5087 8.09903 12.255 8.0695 12.0013 8.0695C9.84494 8.0695 8.09836 9.83177 8.09836 12.0075Z"
                                  fill="currentColor"
                                ></path>{" "}
                              </svg>
                            )}
                          </div>
                        </div>
                        <Form.Text className="text-danger">
                          {formik.touched.confirmPassword &&
                            formik.errors.confirmPassword ? (
                            <div className="text-danger">
                              {formik.errors.confirmPassword}
                            </div>
                          ) : null}

                        </Form.Text>

                      </Form.Group>
                    </Col>

                  </Row>
                  <div className="mt-2 aggregate d-flex gap-2 w-100"  >
                    <Form.Control
                      type="checkbox"
                      name="confirm"
                      className="form-check-input"
                      onChange={handleTermsConditions}
                    // checked={termstrue ? "true" : "false"}

                    />
                    <div  >

                      By continuing, you aggree to Womeyn's <span className="termsofuse" onClick={Teramspush}>Terms of Use</span> and <span className="termsofuse" onClick={Privtepush}>Privacy Policy</span>

                    </div>
                  </div>
                  <Form.Text className="text-danger mb-2">

                    {termstrueerror ? "Terms an Conditions Is Required" : null}

                  </Form.Text>
                  <div className="pb-2 mt-2">
                    <Button type="submits" className="submit-btn">
                      {formState ? (
                        <>

                          <Spinners />
                          <span className="ms-2">Loading...</span>
                        </>
                      ) : (
                        <>Sign Up</>
                      )}
                    </Button>
                    <div className="dont-haves mt-4 mb-5">
                      Already have an account?{" "}
                      <Link to="/" className="text-underline">
                        Click here to sign In.
                      </Link>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="third-right-signup-section"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

Signup.displayName = "Signup";
export default Signup;
