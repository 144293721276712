import { HttpInterceptor } from "../auth/http-interceptors";

class orderServices {
  getOrderList = (params, stateId) => {
    let url;
    if (params) {
      if (params.id) {
        url = "/seller/orders?sortBy=updatedAt:desc&&orderId=" + params.id;
      } else if (params.startDate && params.endDate) {
        url = `/seller/orders?sortBy=updatedAt:desc&&stateId=${stateId ? stateId : 0
          }&fromDate=${params.startDate}&toDate=${params.endDate}`;
      }
    } else {
      if (stateId !== 0) {
        url = "/seller/orders?sortBy=updatedAt:desc&&stateId=" + stateId;
      } else {
        url = "/seller/orders?sortBy=updatedAt:desc&&limit=10";
      }
    }
    // https://womeynapi.cdp360.in/v1/seller/orders?stateId=0&fromDate=2023-03-21&toDate=2023-03-22
    return new Promise((resolve, reject) => {
      HttpInterceptor.get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  SearchOrdersList = (searchorders) => {
    return new Promise((resolve, reject) => {
      HttpInterceptor.get(`/seller/orders?sortBy=updatedAt:desc&&search=${searchorders}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  Stausfilterorder = (filterstateid) => {


    return new Promise((resolve, reject) => {
      HttpInterceptor.get(`/seller/orders?sortBy=updatedAt:desc&&stateId=${filterstateid}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  cancelOrder = (orderId) => {
    let url = "/seller/cancel-order/" + orderId;

    return new Promise((resolve, reject) => {
      HttpInterceptor.post(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  paginationOrders = (current) => {
    return new Promise((resolve, reject) => {
      HttpInterceptor.get(`/seller/orders?sortBy=updatedAt:desc?limit=10&&page=${current}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new orderServices();
