

import React, { Fragment, memo, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { SubscribeCheckout, UserSubscribeCheck } from "../../../services/subscribe_user/subscribe_users";
import "./styles/Subscribe.scss";
import { createUserProfileData, getOfferingCategories } from "../../../services/profile_user_services/profile_user_services";
import { toast } from "react-toastify";
import { SubscribeplanData } from "../../../services/subscrive_plan_services/subscribe_plan_services";
import ReactHtmlParser from "react-html-parser";

const Subscribe = memo((props) => {
  const { name } = useParams();

  const { userInfo } = useOutletContext();

  const [plandetails, setPlanDetails] = useState([]);

  const setIntialOfferingCategories = (ofc) => {
    if (ofc) {
      let ofcc = ofc.split(",");
      ofcc = ofcc.map((item) => {
        return { value: item, label: item };
      });
      return ofcc;
    } else {
      return "";
    }
  };
  const [offeringCategories, setOfferingCategories] = useState("");
  const [categories, setCategories] = useState("");

  const [reactSelectHandle, setReactSelectHandle] = useState(
    setIntialOfferingCategories(offeringCategories)
  );
  const [categoryId, setCategoryId] = useState(2);

  const [plannameuser, setPlanName] = useState("");

  const f = reactSelectHandle?.length * 15;

  const datacard = [
    {
      id: 1,
      subscribe: "Bronze",
      name: "Starter",


      amount: "FREE",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWYt_w2PXfP5XDVLqU_xYZ44EM7UKG58QLSw&usqp=CAU",
      desc1: " Product Listing - 5",
      desc2: " Digital Marketing - NILL",
      desc3: " Promotions - NILL",
      desc4: " Banners - NILL",
      desc5: " Sale -  0",
      content:
        "Our most advanced tools, unlimited contacts, and priority support built for teams.",
    },
    {
      id: 2,
      name: "Plus",
      subscribe: "PLUS",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4h5LZwSYJNG8SzTpy9wjBmoAFcMa7Yznq0g&usqp=CAU",
      desc1: " Product Listing - 6 to 20",
      desc2: " Digital Marketing - Generic",
      desc3: " Promotions - 1",
      desc4: " Banners - Paid",
      desc5: " Sale -  0",
      amount: "20",
      content:
        "Our most advanced tools, unlimited contacts, and priority support built for teams.",
    },
    {
      id: 3,
      name: "Premium",
      subscribe: "PREMIUM",
      amount: "50",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSO3IWhiPfGOpdJAooRNLK0cvIWlWB1JDjPT2njdgwphh2_B4UjPHCBHu3P2WPLt0tCEqQ&usqp=CAU",
      desc1: " Product Listing - 21 to 100",
      desc2: " Digital Marketing - Targeted",
      desc3: " Promotions - 2",
      desc4: " Banners - Yes",
      desc5: " Sale - 1",
      content:
        "Our most advanced tools, unlimited contacts, and priority support built for teams.",
    },
    {
      id: 4,
      subscribe: "ELITE",
      name: "Elite",
      amount: "100",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWYt_w2PXfP5XDVLqU_xYZ44EM7UKG58QLSw&usqp=CAU",
      desc1: " Product Listing - 101 to 250",
      desc2: " Digital Marketing - Targeted",
      desc3: " Promotions - 4",
      desc4: " Banners - Yes",
      desc5: " Sale - 2",
      content:
        "Our most advanced tools, unlimited contacts, and priority support built for teams.",
    },
    {
      id: 5,
      name: "Business Listing",
      subscribe: "Business Listing",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4h5LZwSYJNG8SzTpy9wjBmoAFcMa7Yznq0g&usqp=CAU",
      desc1: " Product Listing - 10",
      desc2: " Digital Marketing - Generic",
      desc3: " Promotions - Paid",
      desc4: " Banners - Paid",
      desc5: " Sale - Paid",
      amount: "15",
      content:
        "Our most advanced tools, unlimited contacts, and priority support; built for teams.",
    },
  ];


  const [usersubscribecheck, setUsersubscribecheck] = useState("");
  useEffect(() => {
    UserSubscribeCheck().then((res) => {
      setUsersubscribecheck(res?.isPlanSubscribed)
    }).catch((err) => {
      console.log(err);
    })

  }, [plannameuser]);


  const HandleSubscribePage = (name) => {
    const FormDatas = new FormData();
    FormDatas.append("planName", name);
    createUserProfileData(FormDatas).then((res) => {
      toast.success("Free Bronze Subscribed..!")
    }).catch((err) => {
      console.log(err);
    });
  }

  const UserSubscribe = (subscribe) => {
    const userId = localStorage.getItem("userid");
    const useremail = localStorage.getItem("useremail");
    const BodySubscribeuserData = {
      subscriptionDetails: {
        selectedPlan: subscribe?.id,
        sellerId: JSON.parse(userId),
      },
      paymentDetails: {
        payment_method_types: ["card"],
        client_reference_id: JSON.parse(userId),
        customer_email: JSON.parse(useremail),
        mode: "payment",
        line_items: [
          {
            price_data: {
              currency: "A$",
              product_data: {
                name: `Plan ${subscribe?.planName} Subscription`,
              },
              unit_amount: subscribe?.planPrice + "00",
            },
            quantity: 1,
          },
        ],
        success_url: `${process.env.REACT_APP_PAYMENT_SUCCESS_URL}?transaction_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${process.env.REACT_APP_PAYMENT_CANCEL_URL}?transaction_id={CHECKOUT_SESSION_ID}`,
      },
    };
    SubscribeCheckout(BodySubscribeuserData)
      .then((res) => {
        if (res?.url) {
          window.location = res.url;
        }
        else {
          toast.success(res?.message);
        }
      })
      .catch((err) => console.log(err));
  };

  
  useEffect(() => {
    if (categoryId) {
      getOfferingCategories(categoryId)
        .then((categories) => {
          let react_select_categories = categories.map((item) => {
            return { value: item.name, label: item.name };
          });
          setCategories(react_select_categories);
        })
        .catch((err) => {
          if (err) console.log(err);
        });
    }
  }, [categoryId]);

  useEffect(() => {
    const changeNumber = userInfo?.categoryTypeId === 0 ? 3 : userInfo?.categoryTypeId;
    SubscribeplanData().then((res) => {
      const datasd = res?.results?.filter((item) => item?.planTypeId === changeNumber);
      setPlanDetails(datasd);
    }).catch((err) => {
      console.log(err);
    })
  }, [userInfo?.categoryTypeId]);


  return (
    <Fragment>
      <div className="subscribeplanmainsection">
        <Card className=" m-0 p-0">
          <div className="subscribemainpage">
            <div className="subtitle">

              <span>
                Subscription Plans & Pricing
              </span>

          
            </div>
            <div className="row">
              {plandetails?.map((item, index) => {
                return (
                  <div className="subscribecard" key={index}>
                    <div className="subname">
                      {item?.planName}
                    </div>
                    <div className="subprice">
                      <div className="subpricename">
                        A$ {item?.planPrice}
                      </div>
                      <div className="mt-2">
                        {item?.durationMonth} Months
                      </div>
                    </div>
                    <div>
                      {usersubscribecheck && userInfo?.planName && item?.planName === "Starter" ? (
                        <>

                         

                          <button className="btn-subcribed">
                            SUBSCRIBED
                          </button>

                        </>
                      ) : (
                        <>

                          {userInfo?.planName === item?.planName ? <>
                            <button className="btn-subcribed">
                              SUBSCRIBED 
                            </button>
                          </> : <>
                            <button
                              className="btn-subcribe"
                              onClick={() => UserSubscribe(item)}
                            >
                              SUBSCRIBE
                            </button>
                          </>}
                        </>
                      )}
                    </div>
                    <div className="subdes">
                      {item?.planDescription}
                    </div>
                    <div className="mt-5">
                      <hr />
                    </div>

                    <div className="mt-2">
                      {item?.allowedProducts ? <>Products Allowed - {item?.allowedProducts}</> : null}
                    </div>

                    <div className="mt-3">
                      {item?.allowedServices ? <>Services Allowed - {item?.allowedServices}</> : null}

                    </div>

                    <div className="mt-4">
                      {ReactHtmlParser(item?.planDetails.replace(/&lt;/g, "<"))}
                    </div>
                  </div>
                )

              })}
            </div>
          </div>

        </Card>


      </div>
















    </Fragment>
  );
});

Subscribe.displayName = "Subscribe";
export default Subscribe;
