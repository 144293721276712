import { HttpInterceptor } from "../auth/http-interceptors"
export function DownloadBillInvoice(orderid) {
    return HttpInterceptor
        .get(`/seller/invoice/${orderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

export function DownloadRevenue(orderid) {
    return HttpInterceptor
        .get(`/seller/revenue/${orderid}`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}

