
import React, { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';
import ListService from './component/listservice/ListService'
import Spinner from 'react-bootstrap/Spinner';

function Servicelist() {
    const userId = localStorage.getItem("ispaid");

    const { userInfo } = useOutletContext();

    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const callBackfunction = () => {

        setTimeout(() => {
            history("/womeyn/subscribe/Service");
        }, 1000);
    }
    return (
        <div>
            <ListService />
        </div>
    )

}

export default Servicelist