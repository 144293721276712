import React, { useEffect } from "react";
import loginServices from "../../../services/login_services/login-services";
import { useNavigate } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';

function LoginSuccess() {
    let history = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            //window.close();
            //alert(7589345798)
            loginServices.oAuthSuccessTokenStage().then(result => {
                console.log("called", result)
                if (result) {
                    setTimeout(() => {
                        history(`/womeyn/dashboard`);
                    }, 1500);
                }
            })
        }, 1000);
    }, []);

    return <div>
        <div className='page-not'>
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="secondary" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="info" />
        </div>
    </div>;
}


export default LoginSuccess;