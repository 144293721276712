// signup start
export const toastusersignup = "user signup successful!! ";

// signup end

// login users start
export const toastlogin = "login user successful!! ";
export const toastlogout = "logout user successful!! ";
// login user end

// user profile update start
export const toastsocialprofile = "social profile update successful!! ";
export const toastuserprofile = "User profile updated successfully!";
export const toastchangepassword = "Changepassword update successful!! ";
export const toastimageupload = "Image upload successful!! ";

// user profile update end

// create profile start
export const toastcreateprofile = "create onboarding profile successful!! ";
export const toastupdateprofile = "update profile successful!! ";
// create profile end

// service cretae start
export const toastcreateservices = "services create successful!! ";
export const toastupdateservices = "services update successful!! ";

// service cretae end

// otp screen start

export const toastotp = "OTP successful ";
export const toastotpcheckmail = "OTP send check your mail ";
export const toastotpresend = "Resend OTP send check your mail ";

// otp screen end
