import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import circlethree from "../../../assets/loginLogos/circle.svg";
import login from "../../../assets/loginLogos/login.png";
import logowomenyn from "../../../assets/loginLogos/women_white_log.svg";
import "../../auth/styles/Signin.scss";
import "./styles/Otp.scss";
// import {
//   ResendOtpCheck,
//   VerifyOtpCheck,
// } from "../../../services/otp_service/otp-services";
import loginServices from "../../../services/login_services/login-services";
import {
  ResendOtpCheck,
  VerifyOtpCheck,
} from "../../../services/otp_service/otp-services";
import {
  toastlogin,
  toastotpresend,
} from "../../../toastcontents/ToastContent";
function OtpCheck() {
  const userid = localStorage.getItem("useremail");

  const [validotpcheck, setValidOtpCheck] = useState("");
  const history = useNavigate();
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const handleChange = (otp) => setOtp(otp);
  const [loading, setLoading] = useState(false);
  const ResendVerifyCheck = () => {
    let datasend = {
      userId: JSON.parse(localStorage.getItem("userid")),
    };
    ResendOtpCheck(datasend)
      .then((res) => {
        toast.success("OTP has been resent successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    localStorage.setItem("auth", true);
    setLoading(true);
    if (otp.length === 0) {
      setError(true);
    }


    if (otp.length === 6) {
      if (otp) {
        if (JSON.parse(localStorage.getItem("userid"))) {
          try {
            let data = {
              userId: JSON.parse(localStorage.getItem("userid")),
              otpValue: otp,
            }
            VerifyOtpCheck(data).then((res) => {
              if (res?.data?.message == "OTP verification success") {
                toast.success("Login successfully Completed");
                setValidOtpCheck("")

                setTimeout(() => {
                  history(`/womeyn/dashboard`);
                  setLoading(false);
                }, 1000);
                localStorage.setItem("auth", true)
              }
              else {
                setValidOtpCheck("Invalid OTP")

              }
            }).catch((err) => {
              setLoading(false);
            });
          } catch (error) {
            setValidOtpCheck("Invalid OTP")
            setLoading(false);
          }
        }

      }



    }

  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, userid, validotpcheck]);
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(59);
    ResendVerifyCheck();
  };

  const BackLoginUser = () => {
    localStorage.clear();
    history("/");
  }


  useEffect(() => {
    if (otp?.length === 6) {
      handleSubmit();
    }
  }, [otp])


  return (
    <>
      <div className="singin-main-section-otp ">
        <div>
          <img src={logowomenyn} alt="no image" className="logo-otp" />
        </div>
        <div className="circle">
          <img src={circlethree} alt="no image" className="circles-otp" />
        </div>
        <div className="sellers-otp">
          <h5 className="we">We are trusted by </h5>
          <div>
            <h6 className="seller-otp-text">Sellers</h6>
          </div>
        </div>
        <div>
          <img src={login} className="Image-fluid last-logo" alt="images" />
        </div>
        <div className="box-otp-check">
          <div className="login-logo-section">OTP</div>
          <div className="we-have-otp-section ">
            We have sent <span className="one-time">OTP</span> to your email
          </div>
          <div className="please-enter-otp-section">
            Please Enter OTP
            <div className="mt-3">
              {seconds > 0 || minutes > 0 ? (
                <span className="minutes-section">
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
              ) : (
                <p style={{ color: "#121E49" }}>Didn't recieve code?</p>
              )}
            </div>
            <div className="otp-box-inputs">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                separator={
                  <span>
                    {" "}
                    <span></span>
                  </span>
                }
                inputStyle="inputStyle"
                focusStyle="focusStyle"
                shouldAutoFocus={true}
              />
            </div>
            <div className="mt-3">
              {error && otp.length <= 5 ? (
                <div className="text-danger">Input Field Empty</div>
              ) : null}
            </div>
            <div>

              <h6 className="invalid-otp">{validotpcheck}</h6>
            </div>
          </div>
          <div className="button-section-otp mt-5">
            <Button
              className="resend-otp-button"
              disabled={seconds > 0 || minutes > 0}
              style={{
                color: seconds > 0 || minutes > 0 ? "#e75b5e" : "#e75b5e",
                backgroundColor: seconds > 0 || minutes > 0 ? "white" : "white",
                border: "2px solid #e75b5e",
                outline: "none",
              }}
              onClick={resendOTP}
            >
              Resend OTP
            </Button>
            <Button className="verify-otp-button" onClick={handleSubmit}>
              {validotpcheck ? <>Verify OTP</> : <>
                {loading ? "Loading..." : "Verify OTP"}
              </>}
            </Button>
          </div>
          <div className="back-to-logins mt-5" onClick={BackLoginUser}>
            Back to Login
          </div>
        </div>
      </div>
    </>
  );
}
export default OtpCheck;
