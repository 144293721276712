import { HttpInterceptor } from "../auth/http-interceptors"

export function userProfileInformation(data) {
    return HttpInterceptor
        .get(`/seller/basicinfo/${data}`)
        .then((res) => {
            return res.data
        })
        .catch((err) => console.log(err));

}
export function userprofileAllGetData() {
    return HttpInterceptor
        .get(`/seller/profile`)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function createUserProfileData(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor
            .post(`/seller/profile`, data)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => console.log(err));
    })
}
export function getOfferingCategories(type) {
    return new Promise((resolve, reject) => {
        HttpInterceptor
            .get(`/seller/master-categories?typeId=${type}`)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => console.log(err));
    })
}
export function SocialUpdateUser(id, data) {
    return HttpInterceptor
        .post(`/seller/updatesocialinfo/${id}`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function UserProfileUpdateData(id, data) {
    return HttpInterceptor
        .post(`/seller/updatebasicinfo/${id}`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function UserConfirmPasswordChange(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor
            .post(`/seller/change-password`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err ? err.response.data : "Error"));
    })
}
export function UserProfileImageUpload(id, data) {
    return HttpInterceptor
        .post(`/seller/update-photo/${id}`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}


export function UserProfileBannerUpload(data) {
    return HttpInterceptor
        .post(`/seller/upload-banner/`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}


