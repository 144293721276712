import { useState, memo, Fragment, useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import support_services from "../../../../services/support_services/support_services";
import ReplyTicket from "./ReplyTicket";
import "../styles/Tickets.scss";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import TicketCard from "../components/TicketCard";

const ViewTicket = memo((props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [ticket, setTicket] = useState([]);
  const [tickets, setTickets] = useState([]);
  const { userInfo } = useOutletContext();

  let history = useNavigate();

  useEffect(() => {
    if (loading) {
      getTickets();
    }
  }, [loading]);

  const getTickets = () => {
    support_services
      .getTickets(id)
      .then((results) => {
        setTicket(results.ticketDetails);
        setTickets(results.ticketReplies);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const goBack = () => {
    history("/womeyn/tickets");
  };
  if (!loading) {
    return (
      <Fragment>
        <div className="mb-3 mt-3">
          <h3>
            Ticket - #{id} - Sub: {ticket.subject}-
            {ticket?.stateId === 3
              ? ": #CLOSED"
              : ticket?.stateId === 1
                ? "New"
                : "Open"}
          </h3>
        </div>
        {ticket?.stateId !== 3 ? (
          <ReplyTicket id={id} getTickets={getTickets}></ReplyTicket>
        ) : (
          ""
        )}
        {ticket?.stateId === 3 ? (
          <div>
            <Button onClick={goBack}>Back</Button>  
          </div>
        ) : (
          ""
        )}

        <Card className="view-ticket-fullcard mt-4">
          {tickets?.map((item) => {
            return (
              <TicketCard
                user={item?.userRole == "seller" ? true : false}
                message={item}
              ></TicketCard>
            );
          })}
        </Card>
      </Fragment>
    );
  } else {
    return <Fragment></Fragment>;
  }
});

export default ViewTicket;
