import { HttpInterceptor } from "../auth/http-interceptors"

export function Gettermsandconditions(data) {
    return new Promise((resolve, reject) => {
        HttpInterceptor.get(`/common/static-info?typeId=${data}`).then((res) => {
            resolve(res?.data);
        }).catch((err) => {
            reject(err);
        })
    })
}