import React, { useEffect, useState } from 'react'
import './styles/Termsconditions.scss';
import { Gettermsandconditions } from '../../../services/termsandcondition_services/termsandconditions_services';
import ReactHtmlParser from "react-html-parser";
import { Card } from 'react-bootstrap';

function Termsandconditions() {

    const [terms, setTerms] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading((pre) => !pre)
        const isCancelled = false
        if (!isCancelled) {
            Gettermsandconditions(1).then((res) => {
                setTerms(res);
                setTimeout(() => {
                    setLoading((pre) => !pre)
                }, 600);
            }).catch((err) => {
                console.log(err);
                setLoading((pre) => !pre)

            })
        }
        return () => {
            isCancelled = false;
        }
    }, [])
    return (
        <div className='termsmainsection'>
            <div className='insidetermssection'>
                <Card>

                <h3>Womeyn Terms and Conditions
                </h3>
                {loading ? <>Loading...</> : <div className='mt-4'>
                    {ReactHtmlParser(terms?.content?.replace(/&lt;/g, "<"))}
                </div>}
                </Card>
            </div>
        </div>
    )
}

export default Termsandconditions