import React, { useEffect, useState } from 'react'
import './styles/Popularproduct.scss';
import { Card } from 'react-bootstrap';
import img5 from '../../../assets/modules/e-commerce/images/product/04.png'
import img6 from '../../../assets/modules/e-commerce/images/product/09.png'
import img7 from '../../../assets/modules/e-commerce/images/product/04.png'
import img8 from '../../../assets/modules/e-commerce/images/product/06.png'
import img9 from '../../../assets/modules/e-commerce/images/product/07.png'
import img10 from '../../../assets/modules/e-commerce/images/product/02.png'
import img11 from '../../../assets/modules/e-commerce/images/product/05.png'
import img12 from '../../../assets/modules/e-commerce/images/product/10.png'
import { Row, Col, Dropdown, Image, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { dashboardPopularproducts } from '../../../services/dashboard_services/dashboard_services';

function Popularproducts() {


    const [popularproducts, setPopularproducts] = useState([]);


    useEffect(() => {
        dashboardPopularproducts().then((res) => {

            setPopularproducts(res?.data[0]);

        }).catch((err) => {
            console.log(err);
        })
    }, [])

    return (
        <Card>
            <span className='fs-5'>
                See all top selling products
            </span>
            <div>
                {popularproducts?.results?.slice(0, 10).map((item, index) => {
                    return (
                        // <div>
                        //     {index+1}
                        // </div>
                        <div>
                            <ul className="list-inline m-0 p-0">
                                <li className="d-flex mb-4 align-items-center">
                                    <div>
                                        <Image src={img7} alt="story-img" className="img-fluid rounded object-contain avatar-90 iq-product-bg" />
                                    </div>
                                    <div className="ms-4">
                                        <Link to="/e-commerce/product-details" className="h5 iq-product-detail mb-0">Biker’s Jacket</Link>
                                        <div className="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2 h5" height="19" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z" fill="#FFD329"></path>
                                            </svg>
                                            <p>3.5</p>
                                        </div>
                                    </div>
                                    <div className="ms-auto submit">
                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.2409 7L0.334735 1.99162C-0.111578 1.53601 -0.111578 0.797321 0.334735 0.341709C0.781049 -0.113902 1.50467 -0.113902 1.95098 0.341709L7.66527 6.17504C8.11158 6.63065 8.11158 7.36935 7.66527 7.82496L1.95098 13.6583C1.50467 14.1139 0.781049 14.1139 0.334735 13.6583C-0.111578 13.2027 -0.111578 12.464 0.334735 12.0084L5.2409 7Z" fill="#8A92A6" />
                                        </svg>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    )
                })}
            </div>
            <div className='mt-5 mb-4 text-center'>
                {popularproducts?.results?.length === 0 && <div>No Popular Products</div>}
            </div>
        </Card>
    )
}

export default Popularproducts







// import React, { useState, useEffect, useRef } from 'react'

// function Popularproducts() {

//     let autoComplete;


//     const [firstname, setFirstName] = useState("");
//     const [lastname, setLastName] = useState("");
//     const [pincodes, setPincodes] = useState("");
//     const [query, setQuery] = useState("");
//     const autoCompleteRef = useRef(null);





//     function handleScriptLoad(updateQuery, autoCompleteRef) {
//         autoComplete = new window.google.maps.places.Autocomplete(
//             autoCompleteRef.current,
//             { types: ["postal_code"], componentRestrictions: { country: "AUS" } }
//         );
//         autoComplete.setFields([
//             "address_components",
//             "formatted_address",
//             "geometry",
//         ]);
//         autoComplete.addListener("place_changed", () =>
//             handlePlaceSelect(updateQuery)
//         );

//     }

//     const getAddressFromGoogle = (event) => {
//         let value = event.target.value;
//         setQuery(value);
//     };
//     async function handlePlaceSelect(updateQuery) {
//         const addressObject = autoComplete.getPlace();


//         const query = addressObject;
//         updateQuery(query);
//         setPincodes(query);
//         // console.log(addressObject);
//     }

//     const loadScript = (url, callback) => {
//         let script = document.createElement("script");
//         script.type = "text/javascript";

//         if (script.readyState) {
//             script.onreadystatechange = function () {
//                 if (
//                     script.readyState === "loaded" ||
//                     script.readyState === "complete"
//                 ) {
//                     script.onreadystatechange = null;
//                     callback();
//                 }
//             };
//         } else {
//             script.onload = () => callback();
//         }

//         script.src = url;
//         document.getElementsByTagName("head")[0].appendChild(script);
//     };
//     useEffect(() => {
//         loadScript(
//             `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
//             () => handleScriptLoad(setQuery, autoCompleteRef)
//         );

//         setPincodes(autoCompleteRef?.current?.value);



//     }, []);

//     useEffect(() => {
//         if (query && query.address_components) {
//             for (const component of query.address_components) {
//                 const componentType = component.types[0];
//                 if (componentType === "locality") {
//                     //   formikPersonalDetails.values.cityName = component.long_name;
//                     setLastName(component.long_name)
//                 }
//                 if (componentType === "administrative_area_level_1") {

//                 }
//                 if (componentType === "country") {
//                     if (component.long_name === "Australia") {
//                         setFirstName(component.long_name)
//                     }
//                 }
//                 if (componentType === "postal_code") {
//                     setQuery(component.long_name);
//                 }
//             }

//         }
//     }, [query]);




//     return (
//         <div>

//             {/* <h1>{pincodes}</h1> */}

//             <div>
//                 <input type="text"
//                     value={query}
//                     ref={autoCompleteRef}
//                     onChange={getAddressFromGoogle}
//                 />

//             </div><br />

//             <div>
//                 <input type="text" value={lastname} />

//             </div><div><br />
//                 <input type="text" value={firstname} />

//             </div>

//             <div className='mt-3'>
//                 <button>submit</button>
//             </div>



//         </div>
//     )
// }

// export default Popularproducts