import { memo } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import moment from "moment";


const TicketCard = memo((props) => {

    return (
        <>
            <Card className={props.user ? "col-lg-12 view-ticket-card" : "col-lg-12 view-ticket-cards"}>
                <Card.Body className="m-0 p-0">
                    <div className="d-flex align-items-center" style={{ cursor: "pointer" }}  >
                        {
                            props.user ? <img className="ms-3"
                                src={
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZLAIXbmewu-VSkq46SwY0Ioz-9NeyBqGU8g&usqp=CAU"
                                } alt="no image" width="45px" height="45px" style={{ borderRadius: "100%" }} /> : ''
                        }

                        <span className="media-support-infos ms-3 text-black">
                            <span className="mb-0 col">{props.user ? props?.message?.fistName : "Admin"}</span>
                            <br />
                            
                            <span className="mb-0 col">
                                {moment(props.createdAt).format(
                                    "LLL"
                                )}
                            </span>
                        </span>
                        {
                            !props.user ? <img className="ms-3"
                                src={   
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZLAIXbmewu-VSkq46SwY0Ioz-9NeyBqGU8g&usqp=CAU"
                                } alt="no image" width="45px" height="45px" style={{ borderRadius: "100%" }} /> : ''
                        }
                    </div>
                    <p className="mt-3 mb-1">{props.message.message}</p>
                    {/* <p className="mb-0">I upgraded my plan. </p> */}
                    {/* <p className='mb-0 text-primary'>Reply</p> */}
                </Card.Body>
            </Card>
        </>
    );
})

export default TicketCard;
