import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom'
import { Button, Row, Col, Form } from "react-bootstrap";
import moment from 'moment';
import { ServiceDetailsEditVariations } from '../../../../../services/services/services';
function ServiceEditScreen() {

    const { id } = useParams();

    const datas = useLocation();
    console.log(datas?.state?.ids, "dT")

    const [servicevariation, setServicevariations] = useState({
        title: "",
        frequencyType: "",
        durationDetails: "",
        price: "",
        isCancellationAvailable: "",
        endDate: "",
        startDate: "",
        isOpenRegistration: "",
        serviceTypeId: "",
        address: "",
        linkDescription: "",
        numberOfPeopleAllowed: "",
        workingDays: []
    });

    const [timerror, setTimeerror] = useState(false);


    const [servicepayment, setServicepayment] = useState({
        paymentTypeId: "",
    });

    const [serviceerrorvariation, setServiceErrorvariation] = useState(false);
    const {
        title,
        frequencyType,
        durationDetails,
        price,
        isCancellationAvailable,
        endDate,
        startDate,
        isOpenRegistration,
        serviceTypeId,
        address,
        linkDescription,
        numberOfPeopleAllowed,
        workingDays
    } = servicevariation;
    const handleChangesvariations = (e) => {
        setServicevariations({
            ...servicevariation,
            [e.target.name]: e.target.value,
        });
    };
    const [selectMonths, setCheckBoxsMonth] = useState([]);

    const [firstdayas, setFirstDays] = useState("");
    const [thirdtimes, setThirdtimes] = useState("");

    const [addcount, setAddCount] = useState(0);

    const handleAddCount = () => {
        setAddCount(addcount + 1);
    };

    const handleminCount = () => {
        if (addcount === 0) {
            setAddCount(0);
        } else {
            setAddCount(addcount - 1);
        }
    };


    const handleMonthData = (e) => {


        if (firstdayas?.length === 0 || secondtimes?.length === 0 || thirdtimes?.length === 0) {
            setTimeerror(true);
        }


        if (firstdayas && secondtimes && thirdtimes) {

            const forms = {
                dayName: firstdayas,
                workingHours: `${secondtimes} to ${thirdtimes}`
            }
            setCheckBoxsMonth([...selectMonths, forms]);
            setFirstDays("");
            setSecondtimes("");
            setThirdtimes("");

            setTimeerror(false);

        }


    };

    const [secondtimes, setSecondtimes] = useState("");


    const WorkingDays = [
        {
            id: 1,
            name: "Sunday"
        },
        {
            id: 2,
            name: "Monday"
        },
        {
            id: 3,
            name: "Tuesday"
        },
        {
            id: 4,
            name: "Wednesday"
        },
        {
            id: 5,
            name: "Thursday"
        },
        {
            id: 6,
            name: "Friday"
        },
        {
            id: 7,
            name: "Saturday"
        }
    ]


    const variationsTimesam = [
        {
            id: 1,
            time: "1:00 AM"
        },
        {
            id: 2,
            time: "2:00 AM"

        },
        {
            id: 3,
            time: "3:00 AM"

        },
        {
            id: 4,
            time: "4:00 AM"

        },
        {
            id: 5,
            time: "5:00 AM"

        },
        {
            id: 6,
            time: "6:00 AM"

        },
        {
            id: 7,
            time: "7:00 AM"

        },
        {
            id: 8,
            time: "8:00 AM"

        },
        {
            id: 9,
            time: "9:00 AM"

        },
        {
            id: 10,
            time: "10:00 AM"

        },
        {
            id: 11,
            time: "11:00 AM"

        },
        {
            id: 12,
            time: "12:00 AM"

        },
        {
            id: 13,
            time: "1:00 PM"
        },
        {
            id: 14,
            time: "2:00 PM"

        },
        {
            id: 15,
            time: "3:00 PM"

        },
        {
            id: 16,
            time: "4:00 PM"

        },
        {
            id: 17,
            time: "5:00 PM"

        },
        {
            id: 18,
            time: "6:00 PM"

        },
        {
            id: 19,
            time: "7:00 PM"

        },
        {
            id: 20,
            time: "8:00 PM"

        },
        {
            id: 21,
            time: "9:00 PM"

        },
        {
            id: 22,
            time: "10:00 PM"

        },
        {
            id: 23,
            time: "11:00 PM"

        },
        {
            id: 24,
            time: "12:00 PM"

        },
    ];

    useEffect(() => {
        const ids = localStorage.getItem("serviceId");
        ServiceDetailsEditVariations(datas?.state?.ids).then((res) => {
            res?.map((item, index) => {
                if (item?.id == id) {
                    console.log(item, "res")
                    setServicevariations(item);
                    setCheckBoxsMonth(item?.workingDays)
                }
            })
        }).catch((err) => {
            console.log(err);
        })
    }, [datas?.ids, id])
    return (
        <Card p="0" m="0">

            {datas?.state?.ids}
            <Card.Body >
                <div className="variation-section-inside">
                    <div className="mb-4">
                        <Form.Label htmlFor="styleName">Add title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            onChange={handleChangesvariations}
                            value={title}
                            placeholder="title"
                            className="serviceform"
                        />
                        {serviceerrorvariation && title?.length <= 0 ? (
                            <span className="error-message">title is Required!!</span>
                        ) : (
                            ""
                        )}
                    </div>
                    <div>
                        <h6>Select frequency for this experience</h6>
                    </div>
                    <div className="per-day-year-section mb-4 mt-3">
                        <div className="count-sections">
                            <div onClick={handleminCount} className="leftsectionplus">
                                -
                            </div>
                            {/* <div>{addcount}</div> */}
                            <div className="middlesectionplus">
                                <input type="text" value={addcount} disabled className="countsection" />
                            </div>
                            <div onClick={handleAddCount} className="rightsectionplus">
                                +
                            </div>
                        </div>

                        <div className="form-sections">
                            <Col>
                                <Form.Select className="serviceform" value={frequencyType} name="frequencyType" onChange={handleChangesvariations}>
                                    <option value="" disabled>--Days--</option>
                                    <option label="Per Day" value="Per Day">
                                        Per Day
                                    </option>
                                    <option label="Per Week" value="Per Week">
                                        Per Week
                                    </option>
                                </Form.Select>
                                {serviceerrorvariation && frequencyType?.length <= 0 ? (
                                    <span className="error-message">frequencyType is Required!!</span>
                                ) : (
                                    ""
                                )}
                            </Col>
                        </div>
                    </div>
                    <div>
                        <h6>Slots or Working Days</h6>
                        <div className="row mt-3">

                            {/* const WorkingDays = [ */}
                            <Col lg={4}>
                                <Form.Select name="hours1" className="serviceform mt-2" onChange={(e) => setFirstDays(e?.target.value)} value={firstdayas}>
                                    <option value="">--Days--</option>
                                    {WorkingDays?.map((item, index) => {
                                        return (
                                            <>
                                                <option label={item?.name} value={item?.name} name={item?.name}>
                                                    {item?.name}
                                                </option>
                                            </>
                                        )
                                    })}

                                </Form.Select>

                                <div className="">

                                    {timerror && firstdayas?.length <= 0 ? <span className="text-danger">Working Days is Required</span> : null}
                                </div>
                            </Col>



                            <Col lg={3}>
                                <Form.Select name="hours1" className="serviceform mt-2" onChange={(e) => setSecondtimes(e?.target.value)} value={secondtimes}>
                                    <option value="">--Slot Time--</option>
                                    {variationsTimesam?.map((item, index) => {
                                        return (
                                            <>
                                                <option label={item?.time} value={item?.time} name={item?.time}>
                                                    {item?.time}
                                                </option>
                                            </>
                                        )
                                    })}

                                </Form.Select>

                                <div className="">

                                    {timerror && secondtimes?.length <= 0 ? <span className="text-danger">Slot Time is Required</span> : null}
                                </div>
                            </Col>

                            <Col lg={3}>
                                <Form.Select name="hours1" className="serviceform mt-2" onChange={(e) => setThirdtimes(e?.target.value)} value={thirdtimes}>
                                    <option value="">--Slot Time--</option>
                                    {variationsTimesam?.map((item, index) => {
                                        return (
                                            <>
                                                <option label={item?.time} value={item?.time} name={item?.time}>
                                                    {item?.time}
                                                </option>
                                            </>
                                        )
                                    })}

                                </Form.Select>

                                <div className="">
                                    {timerror && thirdtimes?.length <= 0 ? <span className="text-danger">Slot Time is Required</span> : null}
                                </div>


                            </Col>

                            <Col lg={2}>
                                <div className="buttonslots">
                                    <button onClick={handleMonthData} className={"slotboxtimes"}>+ Add Slot </button>
                                </div>
                            </Col>


                            <div className="mainslots row mt-5 mb-3">
                                {selectMonths?.map((item, index) => {
                                    return (
                                        <div className="slottimesbox mt-2">
                                            <div className={"insidesoltstimeboxs"}>

                                                <div className="mb-2 mt-1">
                                                    {item?.dayName}
                                                </div>
                                                <div className="mb-2 mt-1">
                                                    {item?.workingHours}
                                                </div>

                                                <div className="insidedeletetimes">
                                                    <Button
                                                        className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                                                        to="#"
                                                        role="button"
                                                    // onClick={() => handledeleteMonthTimes(index + 1)}
                                                    >
                                                        <span className="btn-inner">{"remove"}</span>
                                                    </Button>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>



                            {/* {DataMonths.map((item, index) => {
                return (
                  <Col lg="12">
                    <div className="d-flex justify-space-between">
                      <div>
                        <label class="control" name={item.name}>
                          <input
                            onChange={(e) => handleMonthData(item?.name, e)}
                            type="checkbox"
                            name={item?.name}
                            value={item?.name}
                          />
                          <span class="control__content">{item.name}</span>
                        </label>
                      </div>
                      <div className="d-flex justify-space-around w-100 gap-5 ms-5">
                        <Col lg="5">
                          <Form.Select className="serviceform mt-2" onChange={(e) => {
                            handleMonthData(item?.name, e)

                          }} >
                            <option value="">--Time--</option>
                            {item?.variationstime?.map((item, index) => {
                              return (
                                <>
                                  <option label={item?.time} value={item?.time} name={item?.time}>
                                    {item?.time}
                                  </option>
                                </>
                              )
                            })}

                          </Form.Select>
                        </Col>
                        <div className="d-flex align-items-center justify-content-center">
                          To
                        </div>
                        <Col lg={5}>
                          <Form.Select name="hours1" className="serviceform mt-2" onChange={(e) => {
                            handleMonthData(item?.name, e)

                          }}>
                            <option value="">--Time--</option>
                            {item?.variationstime1?.map((item, index) => {
                              return (
                                <>
                                  <option label={item?.time} value={item?.time} name={item?.time}>
                                    {item?.time}
                                  </option>
                                </>
                              )
                            })}

                          </Form.Select>
                        </Col>
                      </div>
                    </div>
                  </Col>
                );
              })} */}
                        </div>
                    </div>


                    <div className="date-section-form mb-4 mt-3">
                        <Col>
                            <Form.Label htmlFor="styleName">
                                <h6>Duration details</h6>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="durationDetails"
                                value={durationDetails}
                                onChange={handleChangesvariations}
                                placeholder="1 hr per day"
                                className="serviceform"
                            />

                            {serviceerrorvariation && durationDetails?.length <= 0 ? (
                                <span className="error-message">DurationDetails is Required!!</span>
                            ) : (
                                ""
                            )}

                        </Col>
                        <Col>
                            <Form.Label htmlFor="styleName">
                                <h6>Price</h6>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="price"
                                className="serviceform"
                                placeholder="$100"
                                value={price}
                                onChange={handleChangesvariations}
                            />

                            {serviceerrorvariation && price?.length <= 0 ? (
                                <span className="error-message">price is Required!!</span>
                            ) : (
                                ""
                            )}
                        </Col>
                    </div>

                    <div className="mb-4 d-flex">
                        Cancellation available ?{" "}
                        <div className="d-flex gap-3">
                            <div>
                                <span className="ms-3 ">
                                    <input
                                        type="radio"
                                        value="true"
                                        onChange={handleChangesvariations}
                                        checked={isCancellationAvailable == "true"}
                                        className="checkedcolor"
                                        name="isCancellationAvailable"

                                    />
                                    <span className="ms-2">Yes</span>
                                </span>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    value="false"
                                    name="isCancellationAvailable"
                                    checked={isCancellationAvailable == "false"}
                                    onChange={handleChangesvariations}
                                    className="checkedcolor"
                                />
                                <span className="ms-2">No</span>
                            </div>

                            <div>
                                {serviceerrorvariation && isCancellationAvailable?.length <= 0 ? (
                                    <span className="error-message">isCancellationAvailable is Required!!</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>


                    </div>


                    <div className="date-section-form mb-4">
                        <Col>
                            <Form.Label htmlFor="styleName">
                                <h6>Start date {servicevariation?.startDate}</h6>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="startDate"
                                onChange={handleChangesvariations}
                                value={startDate}
                                style={{ cursor: "pointer" }}
                                className="serviceform"
                            // min={moment().format("YYYY-MM-DD")}
                            />
                            {serviceerrorvariation && startDate?.length <= 0 ? (
                                <span className="error-message">startDate is Required !!</span>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col>
                            <Form.Label htmlFor="styleName">
                                <h6>End date</h6>
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="endDate"
                                onChange={handleChangesvariations}
                                value={endDate}
                                style={{ cursor: "pointer" }}
                                className="serviceform"
                            // min={startDate}
                            />
                            {serviceerrorvariation && endDate.length <= 0 ? (
                                <span className="error-message">endDate is Required !!!</span>
                            ) : (
                                ""
                            )}
                        </Col>
                    </div>
                    <div className="mb-4 ">
                        <Col>
                            <div className="d-flex gap-2">
                                <Form.Check type="checkbox" name="isOpenRegistration" value="1"
                                    onChange={handleChangesvariations}

                                />
                                <span>Open registration (No end date)</span>
                                {serviceerrorvariation && isOpenRegistration.length <= 0 ? (
                                    <span className="error-message">isOpenRegistrations is Required !!!</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        </Col>
                    </div>

                    <div className="mb-4 d-flex">
                        Is this an online/ offline service ?{" "}
                        <div className="d-flex gap-3">
                            <div>
                                <span className="ms-3">
                                    <input
                                        type="radio"
                                        value="1"
                                        onChange={handleChangesvariations}
                                        checked={serviceTypeId == "1"}
                                        className="checkedcolor"
                                        name="serviceTypeId"
                                        id="online"
                                    />
                                    <span className="ms-2">online</span>
                                </span>
                            </div>
                            <div>
                                <input
                                    type="radio"
                                    id="offline"
                                    value="2"
                                    name="serviceTypeId"
                                    checked={serviceTypeId == "2"}
                                    onChange={handleChangesvariations}
                                    className="checkedcolor"
                                />
                                <span className="ms-2">offline</span>
                            </div>
                        </div>
                        {serviceerrorvariation && serviceTypeId?.length <= 0 ? (
                            <span className="error-message">service is Required!!</span>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="mt-5 mb-4">
                        {serviceTypeId == 1 && (
                            <div>
                                <Form.Label htmlFor="styleName">
                                    Online link description
                                </Form.Label>

                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    name="linkDescription"
                                    value={serviceTypeId == 2 ? "" : linkDescription}
                                    onChange={handleChangesvariations}
                                    placeholder="We will send online link before 24 hrs"
                                />
                                {serviceerrorvariation && linkDescription.length <= 0 ? (
                                    <span className="error-message">linkDescription is Required !!!</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                        {serviceTypeId == 2 && (
                            <div>
                                <Form.Label htmlFor="styleName">Address(offline)</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    onChange={handleChangesvariations}
                                    value={serviceTypeId == 1 ? "" : address}
                                    placeholder="Address(offline)"
                                    className="serviceform"
                                />
                                {serviceerrorvariation && address.length <= 0 ? (
                                    <span className="error-message">address is Required !!!</span>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                    </div>
                    <div className="mb-0">
                        <Form.Label htmlFor="styleName">
                            *Number of people allowed to book this package at a time

                        </Form.Label>
                        <Form.Control
                            type="number"
                            onChange={handleChangesvariations}
                            name="numberOfPeopleAllowed"
                            value={numberOfPeopleAllowed}
                            placeholder={`*No of people allowed in 1 session (${serviceTypeId == 1 && "Online"
                                } ${serviceTypeId == 2 && "Offline"})`}
                            className="serviceform"
                        />
                        {serviceerrorvariation && numberOfPeopleAllowed.length <= 0 ? (
                            <span className="error-message">
                                numberOfPeopleAllowed is Required !!!
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <div className="w-100 d-flex align-items-center gap-4 mt-5 ms-4">
                    <button className="btn backs" >
                        Close
                    </button>
                    <Button >Save</Button>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ServiceEditScreen