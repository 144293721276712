import { memo, Fragment, useState, useEffect } from "react";

//Component
// import Chatheader from '../../../../components/chat-header'
import Rightchat from "../../../../views/modules/chat/components/right-chat";
import Leftchat from "../../../../views/modules/chat/components/left-chat";
import Chatfooter from "../../../../views/modules/chat/components/chat-footer";
// import Card from '../../../../components/bootstrap/card'

//Img
import img1 from "../../../../assets/modules/chat/images/avatar/01.png";
import img2 from "../../../../assets/modules/chat/images/avatar/02.png";
import img3 from "../../../../assets/modules/chat/images/avatar/03.png";
import img4 from "../../../../assets/modules/chat/images/avatar/04.png";
import img5 from "../../../../assets/modules/chat/images/avatar/06.png";
import img6 from "../../../../assets/modules/chat/images/avatar/05.png";
import img7 from "../../../../assets/modules/chat/images/avatar/09.png";
import img8 from "../../../../assets/modules/chat/images/avatar/11.png";
import img9 from "../../../../assets/modules/chat/images/avatar/12.png";
import img10 from "../../../../assets/modules/chat/images/avatar/08.png";
import img11 from "../../../../assets/modules/chat/images/avatar/07.png";
import Chatheader from "../../../../views/modules/chat/components/chat-header";
import Card from "../../../../components/bootstrap/card";
import Chat from "../../../../views/modules/chat/pages/chat";
import chatServices from "../../../../services/chat_services/chat-services";
import { useOutletContext } from "react-router-dom";

const Ticketsview = memo((props) => {
  const { userInfo } = useOutletContext();
  const [messages, setMessages] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const sendMessage = (message) => {
    return new Promise((resolve, reject) => {
      let data = {
        senderId: userInfo.id,
        receiverId: "636e7d24a1af9d2a2a577b87",
        message: message,
      };
      chatServices
        .sendChatMessage(data)
        .then((result) => {
          loadMessage();
          if (result) {
            resolve(result.id);
          }
        })
        .catch((err) => {
          if (err) {
            console.log(err);
            reject(false);
          }
        });
    });
  };
  const loadMessage = () => {
    chatServices
      .getChatMessages(userInfo.id)
      .then((result) => {
        setMessages(result?.results);
        setLoading(false);
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };
  useEffect(() => {
    if (isLoading) {
      loadMessage();
    }
  }, [isLoading]);
  return (
    <Fragment>
      <div className="tab-content">
        <Chatheader />
        <div
          className="mt-3 mb-5"
          style={{
            width: "100%",
            height: "450px",
            overflow: "hidden",
            overflowY: "auto",
            padding: "0px 30px",
          }}
        >
          {messages
            ? messages.map((item) => {
                if (userInfo.id === item.senderId) {
                  return (
                    <>
                      <Rightchat
                        attachments={item.attachments}
                        username="Ross Taylor"
                        messageTime="16:34"
                        userImg={img2}
                        message={item.message}
                      />
                    </>
                  );
                } else {
                  return (
                    <>
                      <Leftchat
                        attachments={item.attachments}
                        username="Ross Taylor"
                        messageTime="16:34"
                        userImg={img2}
                        message={item.message}
                      />
                    </>
                  );
                }
              })
            : null}
          {/* <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Leftchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" />
                    <Rightchat username="Ross Taylor" messageTime="16:34" userImg={img2} message="Hey, how are you??" /> */}
        </div>
        <Chatfooter sendMessage={sendMessage} loadMessage={loadMessage} />
      </div>
    </Fragment>
  );
});

Ticketsview.displayName = "Ticketsview";
export default Ticketsview;
