
import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Spinner } from 'react-bootstrap'
import camera from '../../../../../assets/loginLogos/bxs_camera.svg';
import "bootstrap/dist/css/bootstrap.min.css";
import './styles/AddProducts.scss';
import Modal from 'react-bootstrap/Modal';
import { ProductDeleteImage } from "../../../../../services/product_services/product_services";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const DefaultImageIcon = ({ button }) => {
    return (<>
        <label htmlFor={"upload-button" + button}>
            <div className='main-camera-section'>
                <div className='inside-camera-section-upload'>
                    <img src={camera} alt="no image" />
                </div>
            </div>
        </label></>)
}


var remove = (
    <svg
        width="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M19.643 9.48851C19.643 9.5565 19.11 16.2973 18.8056 19.1342C18.615 20.8751 17.4927 21.9311 15.8092 21.9611C14.5157 21.9901 13.2494 22.0001 12.0036 22.0001C10.6809 22.0001 9.38741 21.9901 8.13185 21.9611C6.50477 21.9221 5.38147 20.8451 5.20057 19.1342C4.88741 16.2873 4.36418 9.5565 4.35445 9.48851C4.34473 9.28351 4.41086 9.08852 4.54507 8.93053C4.67734 8.78453 4.86796 8.69653 5.06831 8.69653H18.9388C19.1382 8.69653 19.3191 8.78453 19.4621 8.93053C19.5953 9.08852 19.6624 9.28351 19.643 9.48851Z"
            fill="currentColor"
        ></path>
        <path
            d="M21 5.97686C21 5.56588 20.6761 5.24389 20.2871 5.24389H17.3714C16.7781 5.24389 16.2627 4.8219 16.1304 4.22692L15.967 3.49795C15.7385 2.61698 14.9498 2 14.0647 2H9.93624C9.0415 2 8.26054 2.61698 8.02323 3.54595L7.87054 4.22792C7.7373 4.8219 7.22185 5.24389 6.62957 5.24389H3.71385C3.32386 5.24389 3 5.56588 3 5.97686V6.35685C3 6.75783 3.32386 7.08982 3.71385 7.08982H20.2871C20.6761 7.08982 21 6.75783 21 6.35685V5.97686Z"
            fill="currentColor"
        ></path>
    </svg>
);



const UploadedImages = ({ image, button, Productid }) => {

    const { id } = useParams();
    const [show, setShow] = useState(false);
    const [removeid, setRemoveid] = useState("");
    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState(false);
    const handleShow = (id) => {
        setShow(true);
        setRemoveid(id);
    };



    const DeleteProduct = (imageid) => {
        // setPagerefresh(false)

        const data = {
            parentId: id, itemId: imageid, parentType: 1
        };
        setLoading(true);
        ProductDeleteImage(data)
            .then((res) => {
                toast.success("Product Image Deleted successfully");
                handleClose();
                setLoading(false);
                // setPagerefresh(true);

            })
            .catch((err) => console.log(err));
    };

    if (image) {
        return (
            <Col className='mb-3 image-box-poistion'>
                <label htmlFor={"upload-button" + button}>
                    <div className='main-camera-sections'>
                        <img
                            src={`${process.env.REACT_APP_IMAGE_URL}/${image.name}`}
                            alt="Avatar"
                            class="image"
                            width="120px"
                            height="120px"
                        />

                        {/* <img src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${image.name}`} alt="Avatar" class="image" width="120px" height="120px" /> */}
                    </div>


                </label>
                <div className="remove-image-box">
                    <Button
                        className="btn btn-primary btn-icon btn-sm rounded-pill ms-2"
                        onClick={() => handleShow()}
                    >
                        <span className="btn-inner">{remove}</span>
                    </Button>
                </div>




                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <div className='mt-4 mb-2 ms-3'>
                        <Modal.Title>
                            <div className='text-center'>
                                {/* Product_Id:  */}
                                {/* {removeid} */}
                            </div>
                        </Modal.Title>

                        <Modal.Body>
                            <div className='text-center mt-2 mb-3 fs-5'>
                                Are you sure want to delete this image permanently?
                            </div>
                        </Modal.Body>

                        <div className='d-flex gap-3 align-items-center justify-content-center mt-3 mb-4'>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={() => DeleteProduct(image?._id)}>{loading ? <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ms-2'>Loading...</span>
                            </> : <>Delete</>}</Button>
                        </div>
                    </div>
                </Modal>



            </Col>)
    } else {
        return null;
    }

}
const images = [1, 2, 3, 4, 5, 6, 7, 8];

const UploadImageMapper = ({ uploadingFiles, uploadedFiles }) => {


    const imageBoxes = images.map((item, index) => {

        if (uploadingFiles && uploadingFiles[item]) {
            // console.log(item)
            // console.log(uploadingFiles[item])

            return (<Col className='mb-3'><>
                <label htmlFor={"upload-button" + item}>
                    <div className='main-camera-sections'>
                        <img src={URL.createObjectURL(uploadingFiles[item])} alt="Avatar" class="image" width="120px" height="120px" />

                    </div>
                </label>
            </> </Col>)
        }
        else if ((uploadedFiles && uploadedFiles[index])) {
            return (<Col className='mb-3'><UploadedImages image={uploadedFiles[index]} button={item}>
            </UploadedImages> </Col>)
        } else {
            return (<Col className='mb-3'> <DefaultImageIcon button={item}></DefaultImageIcon></Col >)
        }
    })
    return (
        <Row>
            {imageBoxes}
        </Row>


    );
}







export default UploadImageMapper;