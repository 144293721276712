import { memo, Fragment, useEffect, useState } from 'react'

// React-bootstrap
import { Row, Col, Dropdown, Image, Table } from 'react-bootstrap'

// Router
import { Link, useNavigate } from 'react-router-dom'

//Img
import img5 from '../../../assets/modules/e-commerce/images/product/04.png'
import img6 from '../../../assets/modules/e-commerce/images/product/09.png'
import img7 from '../../../assets/modules/e-commerce/images/product/04.png'
import img8 from '../../../assets/modules/e-commerce/images/product/06.png'
import img9 from '../../../assets/modules/e-commerce/images/product/07.png'
import img10 from '../../../assets/modules/e-commerce/images/product/02.png'
import img11 from '../../../assets/modules/e-commerce/images/product/05.png'
import img12 from '../../../assets/modules/e-commerce/images/product/10.png'

//components


// import SubHeader from '../../components/sub-header'


//Apexcharts
import Chart from "react-apexcharts";

//Count-up
import CountUp from "react-countup"

// Redux Selector / Action
import { useSelector } from 'react-redux';

// Import selectors & action from setting store
import * as SettingSelector from '../../../store/setting/selectors'
import SubHeader from '../../../components/modal/sub-header'
import Card from '../../../components/bootstrap/card'
import CustomToggle from '../../../components/dropdowns'
import AdCard1 from '../../../views/modules/e-commerce/components/ad-card1'
import './styles/Dashboard.scss';
import moment from 'moment';
import { dashboardPopularproducts, dashboardRecentlyOrderproducts, dashboardUserengagement, dashboardservicesgetall } from '../../../services/dashboard_services/dashboard_services'
const Index = memo(() => {
    const queryParameters = (window.location.pathname)
    useSelector(SettingSelector.theme_color)
    const getVariableColor = () => {
        let prefix = getComputedStyle(document.body).getPropertyValue('--prefix') || 'bs-';
        if (prefix) {
            prefix = prefix.trim()
        }
        const color1 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary`);
        const color2 = getComputedStyle(document.body).getPropertyValue(`--${prefix}info`);
        const color3 = getComputedStyle(document.body).getPropertyValue(`--${prefix}primary-tint-20`);
        const color4 = getComputedStyle(document.body).getPropertyValue(`--${prefix}warning`);
        return {
            primary: color1.trim(),
            info: color2.trim(),
            warning: color4.trim(),
            primary_light: color3.trim(),
        };
    }
    const variableColors = getVariableColor();
    const colors = [variableColors.primary, variableColors.info, variableColors.primary_light];
    useEffect(() => {
        return () => colors
    })
    //apexcharts
    const chart1 = {
        options: {
            chart: {
                fontFamily: '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: false,
                },
            },
            colors: colors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 3,
            },
            yaxis: {
                show: true,
                labels: {
                    show: true,
                    minWidth: 19,
                    maxWidth: 19,
                    style: {
                        colors: "#8A92A6",
                    },
                    offsetX: -5,
                },
            },
            legend: {
                show: false,
            },
            xaxis: {
                labels: {
                    minHeight: 22,
                    maxHeight: 22,
                    show: true,
                    style: {
                        colors: "#8A92A6",
                    },
                },
                lines: {
                    show: false
                },
                axisTicks: {
                    show: false,

                },
                axisBorder: {
                    show: false,
                },
                categories: ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            },
            grid: {
                show: true,
                strokeDashArray: 3,
            },
            responsive: [{
                breakpoint: 1399,
                options: {
                    chart: {
                        height: 320
                    }
                }
            }],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: .4,
                    opacityTo: .1,
                    stops: [0, 50, 80],
                    colors: ["#3a57e8"]
                }
            },
            tooltip: {
                enabled: true,
            },
        },
        series: [{
            name: 'total',
            data: [30, 60, 20, 60, 25, 80, 40]
        }]
    }
    const chart2 = {
        options: {
            chart: {
                fontFamily: '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: true,
                },
            },
            colors: ["#ffffff"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 4,
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                labels: {
                    minHeight: 22,
                    maxHeight: 22,
                    show: true,
                    style: {
                        colors: "#ffffff",
                    },
                },
                lines: {
                    show: false
                },
                categories: ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]
            },
            responsive: [{
                breakpoint: 1399,
                options: {
                    chart: {
                        height: 140
                    }
                }
            }],
            grid: {
                show: false,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: .4,
                    opacityTo: .1,
                    stops: [0, 50, 80],
                    colors: ["#3a57e8"]
                }
            },
            tooltip: {
                enabled: true,
            },
        },
        series: [{
            name: 'total',
            data: [30, 50, 20, 60, 25, 80, 40]
        }]
    }
    const chart3 = {
        options: {
            chart: {
                fontFamily: '"Inter", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                toolbar: {
                    show: false
                },
                sparkline: {
                    enabled: true,
                },
            },
            colors: ["#ffffff"],
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 4,
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                labels: {
                    minHeight: 22,
                    maxHeight: 22,
                    show: true,
                    style: {
                        colors: "#ffffff",
                    },
                },
                lines: {
                    show: false
                },
                categories: ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"]
            },
            responsive: [{
                breakpoint: 1399,
                options: {
                    chart: {
                        height: 140
                    }
                }
            }],
            grid: {
                show: false,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: "vertical",
                    shadeIntensity: 0,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: .4,
                    opacityTo: .1,
                    stops: [0, 50, 80],
                    colors: ["#3a57e8"]
                }
            },
            tooltip: {
                enabled: true,
            },
        },
        series: [{
            name: 'total',
            data: [30, 50, 20, 60, 25, 80, 40]
        }]
    }

    const [userengagementdata, setUserengagement] = useState([]);

    // userengagementdata?.totalSales

    const totalsales = userengagementdata?.totalSales;
    const totalorders = userengagementdata?.totalReturns;
    const totaldiputes = userengagementdata?.totalDisputes;


    const chart4 = {
        options: {
            colors: colors,
            plotOptions: {
                radialBar: {
                    inverseOrder: false,
                    endAngle: 360,
                    hollow: {
                        margin: 5,
                        size: '50%',
                        background: 'transparent',
                        imageWidth: 300,
                        imageHeight: 300,
                        imageClipped: true,
                        position: 'front',
                        dropShadow: {
                            enabled: false,
                            blur: 10,
                            opacity: 0.5
                        }
                    },
                    track: {
                        show: true,
                        background: '#f2f2f2',
                        strokeWidth: '100%',
                        opacity: 1,
                        margin: 6,
                        dropShadow: {
                            enabled: false,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    dataLabels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '12px',
                            fontWeight: 600,
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '12px',
                            fontWeight: 400,
                            offsetY: 16,
                            formatter: function (val) {
                                return val + '%'
                            }
                        },
                    }
                }
            },
            labels: ['Total orders', 'Total disputes', 'Total returns'],

        },
        series: [totalsales, totaldiputes, totalorders],

    }

    const svg1 = <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M2 11.0786C2.05 13.4166 2.19 17.4156 2.21 17.8566C2.281 18.7996 2.642 19.7526 3.204 20.4246C3.986 21.3676 4.949 21.7886 6.292 21.7886C8.148 21.7986 10.194 21.7986 12.181 21.7986C14.176 21.7986 16.112 21.7986 17.747 21.7886C19.071 21.7886 20.064 21.3566 20.836 20.4246C21.398 19.7526 21.759 18.7896 21.81 17.8566C21.83 17.4856 21.93 13.1446 21.99 11.0786H2Z" fill="currentColor"></path>
        <path d="M11.2451 15.3843V16.6783C11.2451 17.0923 11.5811 17.4283 11.9951 17.4283C12.4091 17.4283 12.7451 17.0923 12.7451 16.6783V15.3843C12.7451 14.9703 12.4091 14.6343 11.9951 14.6343C11.5811 14.6343 11.2451 14.9703 11.2451 15.3843Z" fill="currentColor"></path>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.211 14.5565C10.111 14.9195 9.762 15.1515 9.384 15.1015C6.833 14.7455 4.395 13.8405 2.337 12.4815C2.126 12.3435 2 12.1075 2 11.8555V8.38949C2 6.28949 3.712 4.58149 5.817 4.58149H7.784C7.972 3.12949 9.202 2.00049 10.704 2.00049H13.286C14.787 2.00049 16.018 3.12949 16.206 4.58149H18.183C20.282 4.58149 21.99 6.28949 21.99 8.38949V11.8555C21.99 12.1075 21.863 12.3425 21.654 12.4815C19.592 13.8465 17.144 14.7555 14.576 15.1105C14.541 15.1155 14.507 15.1175 14.473 15.1175C14.134 15.1175 13.831 14.8885 13.746 14.5525C13.544 13.7565 12.821 13.1995 11.99 13.1995C11.148 13.1995 10.433 13.7445 10.211 14.5565ZM13.286 3.50049H10.704C10.031 3.50049 9.469 3.96049 9.301 4.58149H14.688C14.52 3.96049 13.958 3.50049 13.286 3.50049Z" fill="currentColor"></path>
    </svg>

    const svg2 = <svg width="44" height="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.9964 8.37513H17.7618C15.7911 8.37859 14.1947 9.93514 14.1911 11.8566C14.1884 13.7823 15.7867 15.3458 17.7618 15.3484H22V15.6543C22 19.0136 19.9636 21 16.5173 21H7.48356C4.03644 21 2 19.0136 2 15.6543V8.33786C2 4.97862 4.03644 3 7.48356 3H16.5138C19.96 3 21.9964 4.97862 21.9964 8.33786V8.37513ZM6.73956 8.36733H12.3796H12.3831H12.3902C12.8124 8.36559 13.1538 8.03019 13.152 7.61765C13.1502 7.20598 12.8053 6.87318 12.3831 6.87491H6.73956C6.32 6.87664 5.97956 7.20858 5.97778 7.61852C5.976 8.03019 6.31733 8.36559 6.73956 8.36733Z" fill="currentColor"></path>
        <path opacity="0.4" d="M16.0374 12.2966C16.2465 13.2478 17.0805 13.917 18.0326 13.8996H21.2825C21.6787 13.8996 22 13.5715 22 13.166V10.6344C21.9991 10.2297 21.6787 9.90077 21.2825 9.8999H17.9561C16.8731 9.90338 15.9983 10.8024 16 11.9102C16 12.0398 16.0128 12.1695 16.0374 12.2966Z" fill="currentColor"></path>
        <circle cx="18" cy="11.8999" r="1" fill="currentColor"></circle>
    </svg>

    const svg3 = <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.4" d="M18.8088 9.021C18.3573 9.021 17.7592 9.011 17.0146 9.011C15.1987 9.011 13.7055 7.508 13.7055 5.675V2.459C13.7055 2.206 13.5026 2 13.253 2H7.96363C5.49517 2 3.5 4.026 3.5 6.509V17.284C3.5 19.889 5.59022 22 8.16958 22H16.0453C18.5058 22 20.5 19.987 20.5 17.502V9.471C20.5 9.217 20.298 9.012 20.0465 9.013C19.6247 9.016 19.1168 9.021 18.8088 9.021Z" fill="currentColor"></path>                                <path opacity="0.4" d="M16.0842 2.56737C15.7852 2.25637 15.2632 2.47037 15.2632 2.90137V5.53837C15.2632 6.64437 16.1742 7.55437 17.2792 7.55437C17.9772 7.56237 18.9452 7.56437 19.7672 7.56237C20.1882 7.56137 20.4022 7.05837 20.1102 6.75437C19.0552 5.65737 17.1662 3.69137 16.0842 2.56737Z" fill="currentColor"></path>
        <path d="M15.1052 12.8838C14.8142 13.1728 14.3432 13.1748 14.0512 12.8818L12.4622 11.2848V16.1118C12.4622 16.5228 12.1282 16.8568 11.7172 16.8568C11.3062 16.8568 10.9732 16.5228 10.9732 16.1118V11.2848L9.38223 12.8818C9.09223 13.1748 8.62023 13.1728 8.32923 12.8838C8.03823 12.5948 8.03723 12.1228 8.32723 11.8308L11.1892 8.95582C11.1902 8.95482 11.1902 8.95482 11.1902 8.95482C11.2582 8.88682 11.3402 8.83182 11.4302 8.79482C11.5202 8.75682 11.6182 8.73682 11.7172 8.73682C11.8172 8.73682 11.9152 8.75682 12.0052 8.79482C12.0942 8.83182 12.1752 8.88682 12.2432 8.95382C12.2442 8.95482 12.2452 8.95482 12.2452 8.95582L15.1072 11.8308C15.3972 12.1228 15.3972 12.5948 15.1052 12.8838Z" fill="currentColor"></path>
    </svg>

    const svg4 = <svg width="44" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.2428 4.73756C15.2428 6.95855 17.0459 8.75902 19.2702 8.75902C19.5151 8.75782 19.7594 8.73431 20 8.68878V16.6615C20 20.0156 18.0215 22 14.6624 22H7.34636C3.97851 22 2 20.0156 2 16.6615V9.3561C2 6.00195 3.97851 4 7.34636 4H15.3131C15.2659 4.243 15.2423 4.49001 15.2428 4.73756ZM13.15 14.8966L16.0078 11.2088V11.1912C16.2525 10.8625 16.1901 10.3989 15.8671 10.1463C15.7108 10.0257 15.5122 9.97345 15.3167 10.0016C15.1211 10.0297 14.9453 10.1358 14.8295 10.2956L12.4201 13.3951L9.6766 11.2351C9.51997 11.1131 9.32071 11.0592 9.12381 11.0856C8.92691 11.1121 8.74898 11.2166 8.63019 11.3756L5.67562 15.1863C5.57177 15.3158 5.51586 15.4771 5.51734 15.6429C5.5002 15.9781 5.71187 16.2826 6.03238 16.3838C6.35288 16.485 6.70138 16.3573 6.88031 16.0732L9.35125 12.8771L12.0948 15.0283C12.2508 15.1541 12.4514 15.2111 12.6504 15.1863C12.8494 15.1615 13.0297 15.0569 13.15 14.8966Z" fill="currentColor"></path>
        <circle opacity="0.4" cx="19.5" cy="4.5" r="2.5" fill="currentColor"></circle>
    </svg>


    const history = useNavigate();




    const NavigatePush = () => {
        history("/womeyn/order-details");
    }

    const NavigatePath = () => {
        history("/womeyn/popularproducts");
    }

    const [alldashboarddata, setAllDashboardData] = useState([]);
    const [allrevenues, setRevenue] = useState("");
    const [allordersvalue, setAllordersvalue] = useState("");

    const [popularproducts, setPopularproducts] = useState([]);
    const [popularOrdersproducts, setPopularOrderproducts] = useState([]);




    useEffect(() => {
        dashboardservicesgetall().then((res) => {
            setAllDashboardData(res?.data);
            setRevenue(res?.data?.totalRevenue[0]);
            setAllordersvalue(res?.data?.avgOrderAmount[0]);
        }).catch((err) => {
            console.log(err);
        })
        PopularProducts();
        RecentlyOrdersproducts();
        UserengagementData();
    }, [])


    const PopularProducts = () => {
        dashboardPopularproducts().then((res) => {
            setPopularproducts(res?.data[0]);
        }).catch((err) => {
            console.log(err);
        })
    }

    const RecentlyOrdersproducts = () => {
        dashboardRecentlyOrderproducts().then((res) => {
            setPopularOrderproducts(res?.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const UserengagementData = () => {
        dashboardUserengagement().then((res) => {
            setUserengagement(res?.data);
        }).catch((err) => {
            console.log(err);
        })
    }


    console.log(popularOrdersproducts,'popularOrdersproducts')
    return (
        <Fragment>
            <div className='mb-5 mt-3'>
                <SubHeader />
            </div>

            <Row>

                <Col lg="5">
                    <Row>
                        <div className="col-6 ">

                            <Card className="cards">
                                <Card.Body className="text-center">
                                    <div className="m-auto d-inline-block p-4 bg-soft-primary rounded-pill">{svg1}</div>
                                    <h4 className="mt-4 mb-2 counter">

                                        {alldashboarddata?.totalProducts}
                                    </h4>
                                    <p className="mb-0">
                                        Active / Total Products
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6">


                            <Card className="cards">
                                <Card.Body className="text-center">
                                    <div className="m-auto d-inline-block p-4 bg-soft-primary rounded-pill">{svg2}</div>
                                    <h4 className="mt-4 mb-2 counter">

                                        {alldashboarddata?.totalServices ? alldashboarddata?.totalServices : null}
                                    </h4>
                                    <p className="mb-0">
                                        Active / Total Services
                                    </p>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-6">
                            <Card className="cards">
                                <Card.Body className="text-center">
                                    <div className="m-auto d-inline-block p-4 bg-soft-primary rounded-pill">{svg2}</div>
                                    <h4 className="mt-4 mb-2 counter">


                                        {allrevenues?.total ? allrevenues?.total.toFixed(4) : 0}
                                    </h4>
                                    <p className="mb-0">
                                        Total sales / revenue
                                    </p>
                                </Card.Body>
                            </Card>

                        </div>
                        <div className="col-6">

                            <Card className="cards">
                                <Card.Body className="text-center">
                                    <div className="m-auto d-inline-block p-4 bg-soft-primary rounded-pill">{svg2}</div>
                                    <h4 className="mt-4 mb-2 counter">


                                        {allordersvalue?.avgOrderAmount ? allordersvalue?.avgOrderAmount : 0}
                                    </h4>
                                    <p className="mb-0">
                                        Avg order value
                                    </p>
                                </Card.Body>
                            </Card>

                        </div>
                    </Row>
                </Col>
                <Col lg="7">
                    <Card className="m-0 p-0">
                        <div className="card-header">
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">Revenue</h4>

                            </div>
                        </div>
                        <Card.Body>
                            <Chart options={chart1.options} series={chart1.series} type="area" height="350" />
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="8">
                    <Row>
                        <Col md="12">
                            <Card className="overflow-hidden m-0 p-0 mb-5 mt-5 ">
                                <Card.Header className="flex-wrap align-items-center">
                                    <Card.Header.Title>
                                        <h3 className="card-title">Orders</h3>
                                    </Card.Header.Title>



                                </Card.Header>

                                <Card.Header className="flex-wrap align-items-center">
                                    <Card.Header.Title>

                                    </Card.Header.Title>



                                </Card.Header>


                                <Card.Body className="p-0">
                                    <div className="mt-4 table-responsive  ">
                                        <Table className="data-table list-table mb-0" role="grid">
                                            <thead>
                                                <tr className="added-line">
                                                    <th>S.no</th>
                                                    <th>Profile</th>
                                                    <th>Order Value</th>
                                                    <th>Date of order</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    popularOrdersproducts.slice(0, 8).map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <strong>{index + 1}</strong>
                                                                </td>
                                                                <td>
                                                                    <h6>{item?.customerName?item?.customerName:"-"}</h6>
                                                                </td>
                                                                <td>
                                                                    <strong>A$ {item.totalOrderAmount}</strong>
                                                                </td>
                                                                <td>
                                                                    {moment(item?.orderedDate).format('L')}
                                                                </td>
                                                            </tr>

                                                        )
                                                    }
                                                    )
                                                }
                                            </tbody>
                                        </Table>

                                        {popularOrdersproducts?.length > 9 ? <>
                                            <div className='mt-5 mb-5 ms-4'>
                                                <button className='sellall' onClick={NavigatePush}>See all orders
                                                    <svg className="ms-3" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.2409 7L0.334735 1.99162C-0.111578 1.53601 -0.111578 0.797321 0.334735 0.341709C0.781049 -0.113902 1.50467 -0.113902 1.95098 0.341709L7.66527 6.17504C8.11158 6.63065 8.11158 7.36935 7.66527 7.82496L1.95098 13.6583C1.50467 14.1139 0.781049 14.1139 0.334735 13.6583C-0.111578 13.2027 -0.111578 12.464 0.334735 12.0084L5.2409 7Z" fill="currentColor" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </> : null}

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>

                <Col lg="4">
                    <Card className="circle-progress-widget mb-5 m-0 p-0 mt-5">
                        <Card.Header>
                            <Card.Header.Title>
                                <h4 className="card-title">User engagement</h4>
                            </Card.Header.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-between  flex-wrap">
                                <Chart options={chart4.options} series={chart4.series} type="radialBar" className="col-md-7 col-lg-7" height="185" />
                                <div className="d-grid gap col-md-5 col-lg-5">
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2 text-primary" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="currentColor">
                                            <g><circle cx="12" cy="12" r="8" fill="currentColor"></circle></g>
                                        </svg>
                                        <div className="ms-2">
                                            <span className="mb-3">Total orders</span>
                                            <h4 className="mb-0">{userengagementdata?.totalSales}</h4>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2 text-info" xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="currentColor">
                                            <g><circle cx="12" cy="12" r="8" fill="currentColor"></circle></g>
                                        </svg>
                                        <div className="ms-2">
                                            <span className="mb-3">Total disputes</span>
                                            <h4 className="mb-0">{userengagementdata?.totalDisputes}</h4>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start">
                                        <svg className="mt-2 text-warning " xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24 24" fill="currentColor">
                                            <g><circle cx="12" cy="12" r="8" fill="currentColor"></circle></g>
                                        </svg>
                                        <div className="ms-2">
                                            <span className="mb-3">Total returns</span>
                                            <h4 className="mb-0">{userengagementdata?.totalReturns}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Col lg="12" md="12">
                        <Card className="m-0 p-0 mb-5" >
                            <Card.Header>
                                <Card.Header.Title>
                                    <h4 className="card-title">Popular products

                                    </h4>
                                </Card.Header.Title>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column">

                                {popularproducts?.results?.slice(0, 8).map((item, index) => {
                                    return (

                                        <div>
                                            <ul className="list-inline m-0 p-0">
                                                <li className="d-flex mb-4 align-items-center">
                                                    <div>
                                                        <Image src={img7} alt="story-img" className="img-fluid rounded object-contain avatar-90 iq-product-bg" />
                                                    </div>
                                                    <div className="ms-4">
                                                        <Link to="/e-commerce/product-details" className="h5 iq-product-detail mb-0">Biker’s Jacket</Link>
                                                        <div className="d-flex align-items-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="me-2 h5" height="19" viewBox="0 0 24 24" fill="none">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z" fill="#FFD329"></path>
                                                            </svg>
                                                            <p>3.5</p>
                                                        </div>
                                                    </div>
                                                    <div className="ms-auto submit">
                                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.2409 7L0.334735 1.99162C-0.111578 1.53601 -0.111578 0.797321 0.334735 0.341709C0.781049 -0.113902 1.50467 -0.113902 1.95098 0.341709L7.66527 6.17504C8.11158 6.63065 8.11158 7.36935 7.66527 7.82496L1.95098 13.6583C1.50467 14.1139 0.781049 14.1139 0.334735 13.6583C-0.111578 13.2027 -0.111578 12.464 0.334735 12.0084L5.2409 7Z" fill="#8A92A6" />
                                                        </svg>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    )
                                })}


                                {popularproducts?.results?.length === 0 && <div className='text-center'>
                                    No Popular Products...
                                </div>}
                                {popularproducts?.results?.length > 9 ? <>
                                    <button className='sellalls' onClick={NavigatePath}>
                                        See all top selling products
                                        <svg className="ms-3" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.2409 7L0.334735 1.99162C-0.111578 1.53601 -0.111578 0.797321 0.334735 0.341709C0.781049 -0.113902 1.50467 -0.113902 1.95098 0.341709L7.66527 6.17504C8.11158 6.63065 8.11158 7.36935 7.66527 7.82496L1.95098 13.6583C1.50467 14.1139 0.781049 14.1139 0.334735 13.6583C-0.111578 13.2027 -0.111578 12.464 0.334735 12.0084L5.2409 7Z" fill="currentColor" />
                                        </svg>
                                    </button>
                                </> : null}

                            </Card.Body>
                        </Card>
                    </Col>
                </Col>

            </Row>


        </Fragment>
    )
})
Index.displayName = "Index"
export default Index
