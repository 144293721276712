import { HttpInterceptor } from "../auth/http-interceptors"
export function forgetPasswordEmailSend(email) {
    return HttpInterceptor
        .post(`/auth/seller/forgot-password`, email)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
export function UpdateForgetPassword(value, data) {
    return HttpInterceptor
        .post(`/auth/seller/reset-password?token=${value}`, data)
        .then((res) => {
            return res;
        })
        .catch((err) => console.log(err));
}
