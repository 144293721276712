import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createUserProfileData } from '../../../../services/profile_user_services/profile_user_services';
import { GetSubscribeCheckoutTransactionId } from '../../../../services/subscribe_user/subscribe_users';
import './styles/Subscribesuccess.scss';
function Subscribesuccess() {
    const queryParameters = new URLSearchParams(window.location.search)
    const Transaction_id = queryParameters.get("transaction_id");
    const history = useNavigate();
    useEffect(() => {
        const successpaid = "successpaid";
        GetSubscribeCheckoutTransactionId(Transaction_id).then((res) => {
            toast.success("Subscribed Successfully");
            setTimeout(() => {
                history("/womeyn/dashboard")
                // localStorage.setItem("ispaid", JSON.stringify(successpaid));
            }, 1200);
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    return (
        <div>
            Subscribed Successfully...
        </div>
    )
}

export default Subscribesuccess