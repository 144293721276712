





import React, { useEffect, useState, useRef } from "react";

import { Button, Row, Col, Form } from "react-bootstrap";


let autoComplete;

function GooglePersonalPostalCode({
    setPostalCode,
    setCityName,
    setStateName,
    setStateShortCode,
    persondetailerror,
    postelCode
}

) {


    const autoCompleteRef = useRef(null);

    const loadScript = (url, callback) => {
        let script = document.createElement("script");
        script.type = "text/javascript";

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (
                    script.readyState === "loaded" ||
                    script.readyState === "complete"
                ) {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };

    function handleScriptLoad(updateQuery, autoCompleteRef) {
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current,
            { types: ["postal_code"], componentRestrictions: { country: "AUS" } }

        );



        autoComplete.setFields([
            "address_components",
            "formatted_address",
            "geometry",
            "name"
        ]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );
    }

    async function handlePlaceSelect(updateQuery) {
        const addressObject = autoComplete.getPlace();
        const query = addressObject;
        updateQuery(query);

    }


    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&callback=initMap&libraries=places&v=weekly`,
            () => handleScriptLoad(setPostalCode, autoCompleteRef)
        );
    }, []);

    


    useEffect(() => {

        if (postelCode && postelCode.address_components) {

            for (const component of postelCode.address_components) {
                const componentType = component.types[0];
                if (componentType === "locality") {

                    setCityName(component?.long_name)

                }
                if (componentType === "administrative_area_level_1") {


                    setStateName(component.long_name)
                    setStateShortCode(component.short_name)
                }
                if (componentType === "country") {
                    if (component.long_name === "Australia") {
                        // setBussinessCountryName(component.long_name)
                    }
                }
                if (componentType === "postal_code") {
                    setPostalCode(component?.long_name)

                }
            }

        }



    }, [postelCode]);


    // const { ref } = usePlacesWidget({
    //     apiKey: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    //     onPlaceSelected: (place) => {



    //         for (const component of place.address_components) {
    //             const componentType = component.types[0];
    //             if (componentType === "locality") {

    //                 setCityName(component?.long_name)

    //             }
    //             if (componentType === "administrative_area_level_1") {


    //                 setStateName(component.long_name)
    //                 setStateShortCode(component.short_name)
    //             }
    //             if (componentType === "country") {
    //                 if (component.long_name === "Australia") {
    //                     // setBussinessCountryName(component.long_name)
    //                 }
    //             }
    //             if (componentType === "postal_code") {
    //                 setPostalCode(component?.long_name)

    //             }
    //         }




    //     },
    //     options: {
    //         types: ["postal_code"],
    //         componentRestrictions: { country: "Aus" },
    //     },
    // });
    return (
        <div>
            <Col lg="12">
                <div className="form-group">

                    <div className="">
                    </div>
                    <Form.Control
                        type="text"
                        maxLength={4}
                        ref={autoCompleteRef}
                        onChange={(e) => setPostalCode(e?.target?.value)}
                        placeholder="Enter Post code"
                        value={postelCode}
                    />

                    <Form.Text className="text-muted">
                        {persondetailerror && postelCode?.length <= 0 ? <span className="text-danger">PostelCode is Required !</span> : null}
                    </Form.Text>

                </div>
            </Col>
        </div>
    )
}

export default GooglePersonalPostalCode;