
const { HttpInterceptor } = require("../auth/http-interceptors");
class LoginServices {
    login = (data) => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.post('/auth/seller/login', data).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })

        })
    }
    // signup = (data) => {
    //     return new Promise((resolve, reject) => {
    //         HttpInterceptor.post('/auth/seller/register', data).then(response => {
    //             resolve(response.data)
    //         }).catch((err) => {
    //             reject(err)
    //         })
    //     })
    // }
    logout = (data) => {
        return new Promise((resolve, reject) => {
            localStorage.removeItem("auth");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userid");
            localStorage.removeItem("serviceId");
            localStorage.removeItem('useremail');
            localStorage.removeItem('ispaid');
            resolve(true)
        })
    }
    asyncAuthStorage = (data) => {
        return new Promise((resolve, reject) => {
            if (!data) {
                reject('param not found')
            }
            let unsuccess = JSON.stringify(data?.user?.isPaidUser) ? "successpaiduser" : "unsuccesspaiduser";
            // localStorage.setItem("auth", true)
            localStorage.setItem('access_token', data.tokens.access.token)
            localStorage.setItem('userid', JSON.stringify(data?.user?.id));
            localStorage.setItem('useremail', JSON.stringify(data?.user?.email));
            localStorage.setItem('ispaid', JSON.stringify(unsuccess));
            resolve(true)
        })

    }
    useAuth = () => {
        const logged_in = localStorage.getItem('auth')
        return new Promise((resolve, reject) => {
            if (logged_in) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    };
    googleOauth = () => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.get('/auth/seller/google').then(response => {
                window.open(response)
            }).catch(err => {
                reject(err)
            })
        })
    }
    oAuthSuccess = () => {
        return new Promise((resolve, reject) => {
            HttpInterceptor.get('/auth/seller/oauth/success').then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
    oAuthSuccessTokenStage = async () => {
        return new Promise((resolve, reject) => {
            this.oAuthSuccess().then(async (result) => {
                if (result.tokens) {
                    let auth_set = await this.asyncAuthStorage(result)
                    localStorage.setItem("auth", true)
                    if (auth_set) {
                        resolve(true)
                    }
                } else {
                    reject(false)
                }
            }).catch(err => {
                console.log(err)
            })
        })
    }
}
export default new LoginServices();
