import { memo, Fragment, useState, useEffect } from 'react'

//React-bootstrap
import { Modal, Form, Row, Col, Button } from 'react-bootstrap'
import product_services from '../../services/product_services/product_services';

const AddVariationModal = memo((props) => {
    const [variations, setVariations] = useState([]);

    const [error, setError] = useState(false);
    const [variationName, setVariationName] = useState(null);

    const handleVariationKeyUp = (e) => {
        e.preventDefault();
        if (e.target.value.match(/^ *$/) !== null) {
            e.target.value = null;
            return;
        }
        if (e.which === 188) {
            let variation_value = e.target.value;
            variation_value = variation_value.replace(',', '');
            setVariations([...variations, variation_value]);
            e.target.value = null;
        }
    }
    const handleVariationClose = (value) => {
        setVariations(item =>
            item.filter(items => items !== value))
    }
    const handleVariationName = (e) => {
        setVariationName(e.target.value)
    }


    const saveVariation = () => {
        //   {"name":"Size","variationValues":"S,M,L,XL,XXL","sellerId":"638f94266c305a16a4e8abe1","categoryId":"63ac843eff862f22f8ffe64b"}

        if (variationName?.length === 0 || variations?.length === 0) {
            setError(true);
        }
        else {

            if (variationName && variations) {

                let postData = {
                    "name": variationName,
                    "variationValues": variations.toString(),
                    "sellerId": JSON.parse(localStorage.getItem("userid")),
                    "categoryId": props.categoryId
                }
                product_services.CreateVariation(postData).then(result => {
                    props.handleVariation(result)
                    props.handleClose();
                    clearStateValues();
                }).catch(error => {
                    console.log(error)
                })
            }

        }
    }
    const clearStateValues = () => {
        setVariations([])
        setVariationName([])
        props.handleClose();
    }

    return (
        <Fragment>
            <Modal show={props.show} onHide={props.handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Variation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Row>
                        <Col lg="12">
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label className="h6">Variation name</Form.Label>
                                <Form.Control type="text" onChange={(e) => {
                                    handleVariationName(e)
                                }} placeholder="eg: Color" />
                                <div>
                                {error &&
                                <>
                                {variationName?.length<=0 ?<>
                                    <div className='text-danger mt-2'>Variation is required.</div></>:<></>}
                                </>
                                }
                                </div>
                            </Form.Group>


                            <h6 className="mb-3">Variation values - comma separated</h6>
                            <Form.Control type="text" className="vanila-datepicker" placeholder="eg. Blue, Pink, White" onKeyUp={handleVariationKeyUp} />
                            {/* Variation as Tags or Badges - Start */}
                            <div className="bd-example">
                                {
                                    variations.map(variation => {

                                        return (
                                            <span className="bg-soft-warning rounded-pill iq-custom-badge mt-1 mt-sm-0">
                                                {variation}
                                                <Button variant="warning btn-sm rounded-pill iq-cancel-btn" onClick={() => (handleVariationClose(variation))}>
                                                    <svg className="icon-14" width="14" height="14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                                    </svg>
                                                </Button>
                                            </span>

                                        )
                                    })
                                }
                            </div>

                            <div>
                                {error &&
                                <>
                                {variations?.length<=0 ?<>
                                    <div className='text-danger mt-2'>Variation is required.</div></>:<></>}
                                </>
                                }
                                
                               
                                {/* {error && {variationName?.length<=0 ?<div className='text-danger mt-2'>Variation is required.</div>:<></>}} */}
                                
                               
                            
                            </div>
                            {/* Variation as Tags or Badges - End */}

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={clearStateValues}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={saveVariation}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
})

AddVariationModal.displayName = "AddVariationModal"
export default AddVariationModal